import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Paper,
  TextareaAutosize,
  withStyles,
  Divider,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ToastMSG from '../../../components/src/ToastMSG.web';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { eyeIcon, checkIcon, likeIcon, featureIcon, profileIcon} from "./assets";

import { createTheme, ThemeProvider ,styled} from '@mui/material/styles';
import { ProposalComponent,AboutWorkOpp,ProfileView} from '../../../components/src/ProposalComponent';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import './style.css';
const config = require("../../../framework/src/config.js")
import  {
  ParticularCollection,
} from "./CfproposalmanagementController";
export  const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0, 
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  overflowX: "hidden",
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});

export const customStyle = {
  inputField: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      textAlign: "left",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1
      }
    },
    '&::before': {
      borderBottom: "none"
    },
    '&::after': {
      borderBottom: "none"
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important"
    }
  },

  tagsElm: {
    '& .tags': {
      color: "#fff2e3",
      height: "37px",
      fontSize: "12px !important",
      lineHeight: "14.52px",
      textAlign: "center",
      verticalAlign: "middle",
      border: "0.5px solid #fff2e3",
      backgroundColor: "transparent",
      borderRadius: "18.2px",
      fontFamily: "Inter, sans-serif !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "22px",
      paddingRight: "22px",
      whiteSpace: "nowrap",
      cursor: "pointer",
      width: "max-content"
    },
    '& .tags-active': {
      borderWidth: "1px",
      fontFamily: "Inter-SemiBold, sans-serif !important",
      backgroundColor: "#323232",
      borderRadius: "21px",
      columnGap: "10px"
    }
  }
};

export const CustomBox = withStyles((theme) => ({
  root: {
    '&.project-reference-modal': {
      backgroundColor: '#222',
      borderRadius: '11.97px',
      padding: '53px 63px',
      height: '742px',
      width: '1072px',
      display: 'flex',
      justifyContent: "space-between",
      '& .modal-header-data': {
        display: 'flex',
        justifyContent: 'space-between'
      },
      '& .back-btn': {
        marginTop: '4px',
        alignSelf: 'flex-start'
      },
      '& .secondary-text': {
        color: "#fff2e3"
      },
      '& .disabled-text': {
        color: "#7d7d7d"
      },
      '& .uncheck-elm': {
        right: "14px",
        top: "14px"
      },
      '& .check-elm': {
        right: "10px",
        top: "10px"
      },
      '& .check-elm, & .uncheck-elm': {
        position: "absolute",
        zIndex: 99
      },
      '& .modal-sub-heading': {
        display: 'flex',
        gap: '54px',
        paddingBottom: '10px',
        marginTop: '12px',
        marginRight: '14px',
        borderBottom: '2.33px solid #2F2F2F',
        color: '#fff2e3',
        marginBottom: '32px'
      },
      '& .accordian-elm': {
        borderRadius: '16px',
        backgroundColor: '#171717',
        border: '1px solid #535353',
        minHeight: '36px',
        padding: '16px'
      },
      '& .accordian-summary': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '& .right-elm': {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
      },
      '& .accordian-collapse': {
        height: '36px',
        width: '36px',
        minWidth: '36px',
        backgroundColor: '#222',
        borderRadius: '8px',
        border: 'none'
      },
      '& .button-text': {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.38px',
        fontFamily: 'Inter-Medium, sans-serif',
        color: '#fff2e3',
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'center'
      },
      '& .toggle-accordian-up': {
        rotate: "180deg"
      },
      '& .add-btn': {
        width: '92px'
      },
    },
    '&.project-reference-modal,&.post-opportunity-container': {
      '& .card-container': {
        marginTop: '32px',
        display: 'flex',
        rowGap: '32px',
        columnGap: '24px',
        justifyContent: 'center',
        flexWrap: 'wrap !important'
      },
      '& .card-style': {
        width: '380px',
        height: '260px',
        borderRadius: '10px',
        marginBottom: '8px',
        position: 'relative'
      },
      '& .manage-space-feature, & .manage-space': {
        display: 'flex',
        fontFamily: 'Inter-SemiBold, sans-serif',
        fontSize: '11px',
        lineHeight: '13.31px',
        color: '#fff2e3',
        alignItems: 'center',
        maxHeight: '22px'
      },
      '& .card-label, & .card-text': {
        width: '165px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: '#fff2e3'
      },
      '& .card-label': {
        fontFamily: 'Inter-SemiBold, sans-serif',
        fontSize: '14px',
        lineHeight: '16.94px',
        marginBottom: '8px'
      },
      '& .manage-space-feature': {
        gap: "2px"
      },
      '& .manage-space': {
        gap: "8px"
      },
      '& .manage-gap': {
        gap: "19px"
      },
      '& .card-text': {
        fontFamily: 'Inter, sans-serif',
        fontSize: '12px',
        lineHeight: '14.52px'
      },
      '& .default-text': {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        lineHeight: '19.36px'
      },
      '& .text-elm': {
        fontFamily: 'Inter, sans-serif',
        fontSize: '20px',
        lineHeight: '24.2px',
        color: '#fff2e3',
      },
      '& .bold-text': {
        fontFamily: 'Inter-SemiBold, sans-serif !important',
      },
      '& .d-none': {
        display: 'none',
      },
    },
    '&.post-opportunity-container': {
      display: 'flex',
      flexDirection: 'column',
      paddingRight: '48px',
      paddingBottom: '50px',
      height: 'auto',
      marginTop: '76px',
      alignItems: 'center',
      '& .default-radius': {
        borderRadius: "11.97px"
      },
      '& .MuiInput-underline.Mui-disabled::before': {
        borderBottom: "none !important"
      },
      '& .additional-resource-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '11.97px',
        border: '2.33px dashed #0D0D0D',
        paddingTop: '13px',
        width: '427px',
      },
      '& .bg-gray': {
        backgroundColor: "#222"
      },
      '& .d-flex': {
        display: "flex"
      },
      '& .flex-column': {
        display: "flex",
        flexDirection: "column"
      },
      '& .secondary-color': {
        color: '#fff2e3'
      },
      '& .hint-color': {
        color: "#696969"
      },
      '& .add-question': {
        width: "108px",
        marginTop: "30px"
      },
      '& .label-text': {
        fontFamily: 'Inter-SemiBold, sans-serif',
        fontSize: '20px',
        lineHeight: '24.2px',
        color: '#fff2e3',
        margin: 0,
        padding: 0
      },
      '& .hint-text': {
        fontFamily: 'Inter, sans-serif',
        fontSize: '14px',
        lineHeight: '16.94px'
      },
      '& .main-text': {
        fontFamily: 'Inter, sans-serif',
        fontSize: '15px',
        lineHeight: '18.15px',
        color: '#fff2e3'
      },
      '& .hours-container .option-container': {
        left: '-80px',

      },
      '& .disabled-text': {
        color: '#7d7d7d',
      },
      '& .horizontal-scroll': {
        justifyContent: 'flex-start !important',
        flexWrap: 'nowrap !important',
        overflowX: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
      },
      '& .flex-data': {
        gap: '24px',
      },
      '& .filename-container': {
        display: 'flex',
        alignItems: 'center',
      },
      '& .filename-container-elm': {
        color: '#fff',
        width: 'max-content',
        borderRadius: '10px',
        backgroundColor: '#252525',
        padding: '13px 15px',
        margin: '0 0 10 0'
      },
      '& .list-no': {
        color: '#7d7d7d',
        paddingRight: '12px',
        fontFamily: 'Inter-SemiBold, sans-serif !important',
        fontSize: '20px',
        lineHeight: '24.2px',
      },
      ...customStyle.tagsElm
    },
    '&.post-opportunity-container ::placeholder': {
      fontStyle: 'italic',
    },
    '&.post-opportunity-container .main-text::placeholder, &.post-opportunity-container .date-container input::placeholder': {
      color: '#686868',
    },
    '&.post-opportunity-container .h-63': {
      height: '63px',
    },
    '&.post-opportunity-container .link-text::placeholder': {
      color: '#808080',
    },
    '&.post-opportunity-container .rateData::placeholder': {
      color: '#595959',
    },
    '&.post-opportunity-container .question-container': {
      borderTop: '2.33px solid #2F2F2F',
      borderBottom: '2.33px solid #2F2F2F',
      padding: '55px 60px',
      backgroundColor: '#171717',
    },
    '&.post-opportunity-container .normal-style input::placeholder': {
      fontStyle: 'normal !important',
    },
    '&.post-opportunity-container .add-resource, &.post-opportunity-container .upload-resource': {
      padding: '0',
      height: 'max-content !important',
      borderBottom: '1px solid #fff2e3',
      backgroundColor: 'transparent',
      borderRadius: '0',
      color: '#fff2e3',
      width: 'max-content',
    },
    '&.post-opportunity-container .upload-resource': {
      marginTop: '38px',
      cursor:'pointer'
    },
    '&.post-opportunity-container .draft-btn': {
      width: '142px',
    },
    '&.post-opportunity-container .medium-text': {
      fontFamily: 'Inter-Medium, sans-serif !important',
    },
    '&.post-opportunity-container .underline-text': {
      width: 'max-content',
      borderBottom: '1px solid #fff2e3',
    },
    '&.post-opportunity-container .date-container fieldset': {
      outline: 'none',
      border: 'none',
    },
    '&.post-opportunity-container .date-container input': {
      color: '#fff2e3',
    },
    '&.post-opportunity-container .date-container button': {
      color: '#fff2e3',
    },
    '&.post-opportunity-container .select-btn': {
      width: '209px',
      height: '37px',
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: '#fff2e3',
      border: '1px solid #66625c',
      borderRadius: '6px',
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      lineHeight: '14px',
      letterSpacing: '0.38px',
    },
    '&.post-opportunity-container .select-btn img.moveup': {
      rotate: '180deg',
    },
    '&.post-opportunity-container .right-data-elm': {
      display: 'flex',
      gap: '18px',
    },
    '&.post-opportunity-container .form-container': {
      padding: '40px 60px',
      backgroundColor: '#171717',
      display: 'flex',
      flexDirection: 'column',
      gap: '100px',
    },
    '&.post-opportunity-container .form-container, &.post-opportunity-container .question-container': {
      maxWidth: '1103px',
      width: '100%',
    },
    '&.post-opportunity-container .search-field': {
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      height: '49px',
      color: '#fff2e3',
    },
    '&.post-opportunity-container .header-container': {
      display: 'flex',
      width: '100%',
      maxWidth: '1348px',
      justifyContent: 'space-between',
      marginLeft: '-71px',
    },
    '&.post-opportunity-container .link-container': {
      width: '405px',
      border: '1.16px solid #3C3C3C',
      borderRadius: '10px',
    },
    '&.post-opportunity-container .link-text': {
      height: '57px !important',
      textAlign: 'center',
      borderRadius: '10px',
      color: '#fff2e3',
    },
    '&.post-opportunity-container .link-text input': {
      textAlign: 'center !important'
    },
    '&.post-opportunity-container .formGroup': {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      marginTop: '76px',
      width: '100%',
    },
    '&.post-opportunity-container .hours-container': {
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      backgroundColor: '#222',
      borderRadius: '10px',
      width: '223px',
      marginTop: '10px'
    },
    '&.post-opportunity-container .hours-container .single-select-container .select-btn': {
      padding: '0',
      height: 'max-content',
      width: '63px',
      borderRadius: '0',
      border: 'none',
    },
    '&.post-opportunity-container .hours-container .single-select-container .title-elm': {
      width: 'max-content',
      borderBottom: '1px solid #fff2e3',
    },
    '&.post-opportunity-container .hours-container .single-select-container .value-elm': {
      display: 'none',
    },
    '&.post-opportunity-container .location-container .single-select-container .select-btn': {
      width: '156px',
    },
    '&.post-opportunity-container .location-container .single-select-container .title-elm': {
      width: 'max-content',
    },
    '&.post-opportunity-container .location-container .single-select-container .value-elm': {
      display: 'none',
    },
    '&.post-opportunity-container .location-container': {
      width: 'max-content',
    },
    '&.post-opportunity-container .discard-container': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '&.post-opportunity-container .discard-container .discard-text': {
      fontSize: '16px',
      fontFamily: 'Inter-SemiBold, sans-serif',
      lineHeight: '19.36px',
      color: '#fff2e3',
      borderBottom: '1px solid #fff2e3',
      width: 'max-content',
      marginLeft: '18px',
    },
    '&.post-opportunity-container .project-title, &.post-opportunity-container .description-title, &.post-opportunity-container .deliverables-title': {
      border: 'none',
      backgroundColor: '#222',
      borderRadius: '11.97px',
      paddingLeft: '27px',
      paddingRight: '27px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
      lineHeight: '18.15px',
    },
    '&.post-opportunity-container .description-title': {
      padding: '27px',
      maxWidth: '952px',
      height: '163px',
      resize: 'none',
    },
    '&.post-opportunity-container .project-title': {
      height: '77px',
      maxWidth: '466px',
    },
    '&.post-opportunity-container .deliverables-title': {
      padding: '26px 16px !important',
      maxWidth: '952px',
      height: '145px',
      resize: 'none',
    },
    '&.post-opportunity-container .work-type-container': {
      borderRadius: '11.97px',
      backgroundColor: '#222',
      display: 'flex',
      flexDirection: 'column',
      gap: '43px',
      padding: '28px 37px',
    },
    '&.post-opportunity-container .work-type-container .text-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    '&.post-opportunity-container .project-title:focus, &.post-opportunity-container .description-title:focus, &.post-opportunity-container .search-field:focus, &.post-opportunity-container .deliverables-title:focus, &.post-opportunity-container .link-text:focus, &.post-opportunity-container .question-data input:focus': {
      outline: 'none',
    },
    '&.post-opportunity-container .upload-container': {
      display: 'flex',
      width: '405px',
      height: '115px',
      justifyContent: 'center',
      backgroundColor: '#222',
      borderRadius: '10px',
    },
    '&.post-opportunity-container .heading-text': {
      fontSize: '36px',
      lineHeight: '43.57px',
      color: '#fff2e3',
      fontFamily: 'Inter-SemiBold, sans-serif',
    },
    '&.post-opportunity-container .option-container-collection .menu-item': {
      color: '#fff2e3',
      cursor: 'pointer',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '19.36px',
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      borderTop: '1px solid #5f5f5f',
      zIndex: '12',
      backgroundColor: '#171717',
    },
    '&.post-opportunity-container .option-container-collection .menu-item.active': {
      backgroundColor: '#222',
    },
    '&.post-opportunity-container .option-container-category, &.post-opportunity-container .option-container-collection': {
      borderRadius: '0 0 10px 10px',
      border: '1px solid #66625c',
      borderTop: 'none',
      position: 'absolute',
      width: '207px',
      maxHeight: '205px',
      overflow: 'auto',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    },
    '&.post-opportunity-container .option-container-category::-webkit-scrollbar, &.post-opportunity-container .option-container-collection::-webkit-scrollbar, &.post-opportunity-container .horizontal-scroll::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
    '&.post-opportunity-container .menu-item:last-child': {
      borderRadius: '0 0 10px 10px',
    },
    '&.post-opportunity-container .link-text-data': {
      color: '#528ADF',
    },
    '&.post-opportunity-container .no-border': {
      border: 'none !important',
    },
    '&.post-opportunity-container .menu-open': {
      borderRadius: '10px 10px 0 0',
      height: '40px',
      border: '1px solid #5f5f5f',
      borderBottom: 'none',
    },
    '&.post-opportunity-container .select-container': {
      position: 'relative',
    },
    '&.post-opportunity-container .search-container': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      paddingLeft: '22px',
      paddingRight: '22px',
    },
    '&.post-opportunity-container .skill-container': {
      borderRadius: '16.5px',
      border: '1px solid #323232',
      maxWidth: '952px',
    },
    '&.post-opportunity-container .skill-Suggestion': {
      fontSize: '12px',
      color: '#666',
      lineHeight: '14.52px',
      fontFamily: 'Inter, sans-serif',
      marginBottom: '16px',
      paddingTop: '22px',
      borderTop: '1px solid #2f2f2f',
      paddingLeft: '22px',
    },
    '&.post-opportunity-container .active-skill-list-container': {
      paddingTop: '16px',
      marginBottom: '8px',
    },
    '&.post-opportunity-container .skill-list-container, &.post-opportunity-container .active-skill-list-container': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '20px',
      paddingLeft: '22px',
      paddingRight: '22px',
      marginBottom: '22px',
      overflow: 'auto',
      maxHeight: '100px',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
    },
    '&.post-opportunity-container .skill-list-container::-webkit-scrollbar, &.post-opportunity-container .active-skill-list-container::-webkit-scrollbar': {
      width: '0',
      height: '0',
    },
    '&.post-opportunity-container .tags': {
      width: 'max-content',
    },
    '&.post-opportunity-headerData': {
      display: 'flex',
      flexDirection: 'column',
    },
    '&.post-opportunity-container .banner-data': {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '446px',
      marginTop: '76px',
    },
    '&.post-opportunity-container .discardBtn': {
      fontFamily: 'Inter-SemiBold, sans-serif',
      fontSize: '16px',
      lineHeight: '19.36px',
      height: 'max-content',
      width: 'max-content',
      borderBottom: '1px solid #fff2e3',
      color: '#fff2e3',
      padding: '0',
      backgroundColor: 'transparent',
      border: 'none',
    },
    '&.post-opportunity-container .create-project:active': {
      opacity: '0.5',
    },
    '&.post-opportunity-container .pointer-cursor': {
      cursor: 'pointer',
    },
    '&.post-opportunity-container .question-data': {
      maxWidth: '908px',
      width: '100%',
      height: '74px',
      borderRadius: '11.97px',
      backgroundColor: '#222',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '16px',
      paddingRight: '16px',
      justifyContent: 'space-between',
    },
    '&.post-opportunity-container .mt-50': {
      marginTop: '50px',
    },
    '&.post-opportunity-container .mt-16': {
      marginTop: '16px',
    },
    '&.post-opportunity-container .question-data input': {
      height: '74px',
      backgroundColor: 'transparent',
      fontFamily: 'Inter, sans-serif',
      fontSize: '16px',
      lineHeight: '21.2px',
      width: '95%',
      color: '#fff2e3',
      border: 'none',
    },
    '&.post-opportunity-container .flex-center': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&.post-opportunity-headerData .fixed-top': {
      position: 'fixed',
      width: '100%',
      zIndex: '999',
    },
    '&.post-opportunity-container .location-container .option-container>div, &.post-opportunity-container .hours-container .option-container>div': {
      width: '156px !important',
    },
    '&.parent-element': {
      backgroundColor: "#111111"
    },
    [theme.breakpoints.down(1440)]: {
      '&.post-opportunity-container': {
        '& .form-container,& .question-container': {
          maxWidth: "811px"
        },
        '& .header-container': {
          maxWidth: "1081px",
          marginLeft: "-90px"
        }
      }
    }
  }
}))(Box);

export const CustomTextField = withStyles({
  root: {
    ...customStyle.inputField
  }
})(Input);

const MainBoxSecond=styled(Box)({
  width:'80%',
  "@media (max-width:1680px)": {
    width:'100%',

  },

})

const MainBoxFirst=styled(Box)({
  width:'20%',
  "@media (max-width:1680px)": {
    width:'100%',

  },

})

const MainBox=styled(Box)({
  flexDirection:'row',
  "@media (max-width:1680px)": {
    flexDirection:'column',
    gap:'20px'
  },

})
export const proposalStyle={
  subText:{
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.36px',
  },

  headingStyle:{
    fontFamily: 'Inter' as 'Inter',
  fontSize: '36px' as '36px',
  fontWeight: 700,
  lineHeight: '43.57px',
  },
  responsiveStyles: {
    width: "100%",
    backgroundColor: "rgb(15, 15, 15)",
  },
  mainBoxStyle:{
    display: 'flex',
    width: "100%",
    maxWidth: "1920px",
    margin: "0 auto",
    boxSizing: "border-box" as "border-box",
     backgroundColor: "rgb(15, 15, 15)", color: 'white',paddingInline:'71px',paddingTop:'47px'
  },
  secondHeadingStyle:{
    fontFamily: 'Inter' as'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24.2px',
  marginRight:'8px'
  },
  questionStyle:{
    fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '24.2px',

  },
  smallHeading:{
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16.94px',
    marginTop:"27px",
    marginBottom:"17px"
  },
  monyStyle:{
    fontFamily: 'Inter',
  fontSize: '18.62px',
  fontWeight: 400,
  lineHeight: '22.53px',
  }
}

import { Pagination } from "@mui/material";


// Customizable Area End

import CfproposalmanagementController, {
  Props,
} from "./CfproposalmanagementController";

export default class Cfproposalmanagement extends CfproposalmanagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getProfileDetails = () => {
    
    return this.state.profileData.profile_data.profile_details?.background_color ? (
      <div
        className="img-view-preview"
        style={{ backgroundColor: this.state.profileData.profile_data.profile_details.background_color }}
      />
    ) : (
      <img src={profileIcon} className="img-view-preview" alt="Default Profile" />
    );
  };
  handleCheckIcon=(data:any)=>{
    return this.handleViewCheck(data)?  <img
                            style={{
                              width: "25.5px",
                              height: "25.5px",
                              borderRadius: "50%",
                              position: "absolute",
                              border: "2px solid rgba(255, 242, 227, 1)",
                              right: "3%",
                              cursor: 'pointer',
                              top: "3%",
                              background:'rgba(17, 17, 17, 1)'
                            }}
                            src={ checkIcon}
                          />
                          :
                          <Box   style={{
                              width: "25.5px",
                              height: "25.5px",
                              borderRadius: "50%",
                              position: "absolute",
                              border: "2px solid rgba(255, 242, 227, 1)",
                              right: "3%",
                              cursor: 'pointer',
                              top: "3%",
                              background:'rgba(70, 70, 70, 1)'
                            }}>

                          </Box>
  }
 
  SkillButton = styled(Button)({
    fontFamily: "Inter , sans-serif",
    marginTop: "24px",
    backgroundColor: "rgb(50, 50, 50)",
    textTransform:'none',
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  });

  LocationButton = styled(Button)({
    fontFamily: "Inter , sans-serif",
    marginTop: 0,
    textTransform:'none',
    backgroundColor: "rgb(50, 50, 50)",
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  });


  renderQuestions = () => {
    return this.state.workOppertunityData.attributes.question && this.state.workOppertunityData.attributes.question.map((element, index) => {
      return <Box style={{marginBottom: "50px",}}> <Typography style={{ 
        fontFamily: 'Inter', 
      fontSize: '16px', 
      fontWeight: 600, 
      lineHeight: '19.36px',  marginBottom: "20px" 
        }}>{index + 1}. {element}</Typography>
        <TextareaAutosize
          onChange={(e) => this.handleQuestionData(index, e.target.value)}
          value={this.state.Question[index]}
          id="handleResetError"
          placeholder="Type here..."
          className={this.state.Question[index]?'pitch-style-font':'pitch-style-font-placeholder'}

          style={{
            width: '100%',
            height: '163px',
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
            borderRadius: '11.97px',
            border: '1px solid #444',
            resize: 'none',
          }}></TextareaAutosize>
          <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >{this.state.isAnswersFilled[index] ? "Reached Limit of 500 characters" : ""}</Typography>
      </Box>
    })
  }

  renderElement = (item: ParticularCollection) => {
    return <CustomBox style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <CustomBox>
        <Typography className='card-label' title={item.attributes.project_name}>{item.attributes.project_name}</Typography>
        <Typography className='card-text'>{item.attributes.username}</Typography>
      </CustomBox>
      <CustomBox className='manage-gap' style={{ display: 'flex', height: '24px' }}>
        <CustomBox className='manage-space'>
          <Typography>{item.attributes.views}</Typography>
          <img className='eyeIcon' src={eyeIcon} alt='eye icon' />
        </CustomBox>
        <CustomBox className='manage-space'>
          <Typography>{item.attributes.likes}</Typography>
          <img className='likeIcon' src={likeIcon} alt='like icon' />
        </CustomBox>
        <CustomBox className='manage-space-feature'>
          <Typography>{item.attributes.saved_count}</Typography>
          <img className='featureIcon' src={featureIcon} alt='feature icon' />
        </CustomBox>
      </CustomBox>
    </CustomBox>
  }
handleCollectionList=()=>{
  return    this.state.collectionList.map((collection:any, index) => {
    return (
      <Box  key={index} style={{display:'flex',
        background:this.state.currentCollectionId==collection.id ?'rgba(33, 33, 33, 1)':'unset',
      cursor:'pointer',flexDirection:'row',paddingInline:'15px',gap:'10px',alignItems:'center',justifyContent:'space-between'}} data-test-id="collectionData" onClick={() => this.handleCollection(collection.id)}>
      <p 
      className="collection-item-style"
      style={{ padding: "4px 0px", cursor: "pointer",  fontWeight:this.state.currentCollectionId==collection.id ?600:400}} >{collection.name}</p>
  <Box className="collection-style">

      <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.31641 9L5.31641 5L1.31641 1" stroke="#FFF2E3" stroke-width="1.52371" stroke-linecap="round"/>
</svg>
  </Box>


      </Box>
    )
  })
}

  // Customizable Area End

  render() {
    const workData = this.createObjectArray();
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <>
        <NavigationMenu activeTab="Send Proposal" {...this.props} />
        <ToastMSG open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
          
        <Box style={proposalStyle.responsiveStyles}>
        <MainBox style={{...proposalStyle.mainBoxStyle,paddingTop:"90px"}}>
          <MainBoxFirst >
            <Typography style={proposalStyle.headingStyle} data-test-id="windowBack" onClick={()=>this.handleBackWindow()} >
              <ArrowBackIosIcon style={{cursor:'pointer'}} />
              Send Proposal</Typography>
          </MainBoxFirst>
          <MainBoxSecond >
         <AboutWorkOpp
         data-test-id={AboutWorkOpp}
         workOppertunityData={this.state.workOppertunityData}
         SkillButton ={this.SkillButton}
         handleAbutWorkOpen={this.handleAbutWorkOpen}
         accordian={this.state.accordian}
         getRateTypeLabelProposal={this.getRateTypeLabelProposal()}
         />
            <Box>
            </Box>
            <Box>
            </Box>
        <Box style={styles.maincontent}>
          <Box>
            <Typography style={proposalStyle.secondHeadingStyle}>  Create Proposal </Typography>
            <Box style={{ backgroundColor: "#171717", padding: "44px 60px", borderRadius: "11.7px" ,marginTop:'29px'}} >
              <Box style={{ marginBottom: "20px", fontFamily: "Inter , sans-serif" }}>
                <Typography style={proposalStyle.questionStyle}>1. Submit your Work* </Typography>
                <Box style={{marginLeft:27}}>
                  <RadioGroup style={{ marginTop: 53 }} value={this.state.radioUpdate}
                  data-test-id="SubmitYourWork"
                    onChange={(e) => this.handleSelectChange(e.target.value)}
                  >
                    <FormControlLabel
                      value={"fullProfile"}
                      control={<Radio style={{ color: "#fff2e3" }} />}
                      label={<Typography style={{ marginTop: 6 }}>Share full profile from Dezigner's knot</Typography>}
                    />
                    <FormControlLabel value={"showProject"} control={<Radio style={{ color: "#fff2e3" }} />} label={<Typography style={{ marginTop: 6 }}>Select projects</Typography>} />
                  </RadioGroup>
                </Box>
                {
  this.state.showCollection && (
    <>
      {this.state.collectionList .length>0? (
        <Grid container style={{ border: "1px solid #7d7d7d", borderRadius: "11.7px", minHeight: "543px", marginTop: "20px" }}>
          <Grid item md={3} style={{ borderRight: "1px solid #7d7d7d",  }}>
            <p style={{ paddingBlock: "10px",paddingInline:'15px' }} className="select-collection-heading">Select Collection</p>
            {this.state.collectionList.length > 0 &&
                     this.handleCollectionList()
                      }
          </Grid>
          <Grid item md={9}>
            <Grid container>
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 16px", width: "100%" }}>
                <p style={{ padding: "10px 0px" }}  className="select-collection-heading">Select Projects</p>
                <p
                  style={{ borderBottom: "1px solid #7d7d7d", cursor: 'pointer' }}
                  className="select-all-heading"
                  data-test-id="isProjectSelectedAll123"
                  onClick={()=>this.handleSelectAll()}
                >
                  {this.handleareAllProjectsSelected() }
                </p>
              </Box>
            </Grid>
            <Box style={{ height: "600px", overflowY: "auto", overflowX: "hidden" ,position:'relative',scrollbarWidth:'none',padding: "0 16px",}}>
              <Grid container  spacing={2}>
                {this.state.particularCollectionData ? (
                  this.state.particularCollectionData.map((data,index) => (
                    <Grid item md={4} key={index}>
                      <Box
                        style={{  display: "flex", justifyContent: "space-between", whiteSpace: "normal", gap: "5px" }}
                        data-test-id="toggleSelectProject"
                        onClick={() => this.toggleSelectProject(data)}
                      >
                        <Box
                          style={{
                            width: "271.19px",
                            height: "243.15px",
                            borderRadius: "11.97px",
                            overflow: "hidden",
                            position: "relative"
                          }}
                        >
                        {this.handleCheckIcon(data)}
                          <img src={`${config.baseURL}${data.attributes.cover_image_url}`} style={{ width: "100%", height: "180px",  borderRadius: "11.97px", }} />
                          <Typography className='collection-heading-item-style' style={{ fontSize: "14px", fontWeight: 700 ,marginTop:'7px'}}>{data.attributes.project_name}</Typography>
                          <Typography className='collection-subheading-item-style' style={{ fontSize: "12px", fontWeight: 400 }}>{data.attributes.username}</Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) :
                <p style={{width: '100%',
                  textAlign: 'center'}} data-test-id="isProjectSelected">
                No projects available
              </p>
                }
              </Grid>
              {this.state.totalCount > 1 && <Box style={{position:'absolute',bottom:0,left:0,right:0, display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "15px", }}>

<PaginationComponent count={this.state.totalCount}
  onChange={this.handleChange}
  page={this.state.currentPage}

/>
</Box>}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <p style={{ marginLeft:57 ,fontSize:'16px'}} data-test-id="isProjectSelected">
          No projects available
        </p>
      )}
    </>
  )
}

              </Box>
              <Box style={{ backgroundColor: '#22222', padding: 2, borderRadius: 2, marginTop: "50px" }}>
                <Typography component="div" style={proposalStyle.questionStyle}>
                  2. Submit your service rate*
                </Typography>
                <Typography style={proposalStyle.smallHeading}>
                  Edit your rate card
                </Typography>
                <Grid container spacing={2} alignItems="center" style={{ marginBottom: 2, alignItems: "start" }}>
                  <Grid item xs={12} md={3}>
                    <Box style={styles.txtfield} >
                      <Typography style={proposalStyle.monyStyle}>Rs.</Typography>
                      <InputField  value={this.state.hourlyRate}
                      data-test-id="rateService"
                       onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{this.handleRateChange(e)}} 
                      style={styles.textFieldStyle} />
                      <Typography  style={proposalStyle.monyStyle}>pr/hr</Typography>
                    </Box>
                    {
                      this.state.isRateLimitCompleted&&
                      <>
                      <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >Service rate must be less than 5000</Typography>
                      </>
                    }
                  </Grid>
                  <Grid item xs={12} md={9} style={{ paddingLeft: "30px" }}>
                  
                    <Paper elevation={0} style={{...styles.infoBox,height:"62px"}}>
                      <Typography  style={{...proposalStyle.subText, width: "244px" , padding: "20px" }}>
                        Amount after 20% platform fees
                      </Typography>
                      <p style={{ ...proposalStyle.subText,width: "79px", borderLeft: "1px solid #323232", padding: "20px", }}>{this.state.amountAfterFees.toFixed(2)}</p>

                    </Paper>
                    <Box style={{ marginTop: "23px" }}>
                      <Paper elevation={0} style={{...styles.infoBox,height:"79px",borderBottom:'unset'}}>
                        <Typography  style={{...proposalStyle.subText,width: "244px", padding: "20px" }}>
                          Overall project est. <br />
                          <span style={styles.spancolour}>{this.state.workOppertunityData.attributes.project_timeline} {this.state.workOppertunityData.attributes
                                .project_timeline_type} {" "}({this.state.workOppertunityData.attributes
                            .required_hours_per_week} hours a week)</span>

                        </Typography>
                        <p style={{ ...proposalStyle.subText,width: "79px", borderLeft: "1px solid #323232", padding: "20px" }}>
                          {this.state.projectEstimate.toFixed(2)}
                        </p>
                      </Paper>

                      <Paper elevation={0} style={{...styles.infoBox,height:"62px"}}>
                        <Typography  style={{ ...proposalStyle.subText, width: "244px", padding: "20px" }}>
                          Overall estimate after 20% fee
                        </Typography>
                        <p id="outlined-basic" style={{ ...proposalStyle.subText, width: "79px", borderLeft: "1px solid #323232", padding: "20px" }}>
                          {this.state.overallEstimateAfterFees.toFixed(2)}
                        </p>
                      </Paper>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box style={{ padding: 2, borderRadius: 2 }}>
                <div style={styles.pitch}>
                  <Typography style={proposalStyle.questionStyle}>
                    3. Give your pitch. What makes you suitable for this job*
                  </Typography>
                      <Typography style={{
                        color: '#8c8c8c', marginBottom: 1, textAlign: 'right', fontFamily: 'Inter',
                        fontSize: '16.29px',
                        fontStyle: 'italic',
                        fontWeight: 300,
                        lineHeight: '19.71px', paddingRight: "10px", marginTop: "20px"
                      }}>
                    100 word limit
                  </Typography>
                </div>
                <TextareaAutosize
                data-test-id="handlePitchDataAPI"
                  onChange={(e) => this.handlePitchData(e.target.value)}
                  
                  minRows={5}
                  value={this.state.pitchData}
                  placeholder="Type here..."
                  style={{
                    width: '100%',
                    height: '163px',
                    backgroundColor: '#333',
                    color: '#fff',
                    padding: '10px',
                    borderRadius: '11.97px',
                    border: '1px solid #444',
                    resize: 'none',
                  
                  }}
                  className={this.state.pitchData?'pitch-style-font':'pitch-style-font-placeholder'}
                />
                <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >{this.showPitchDataError()}</Typography>
              </Box>
              <Box style={styles.section}>
                <CustomBox className='post-opportunity-container' style={{marginTop:'unset'}}>
                  <CustomBox className='formGroup'>
                    <Typography  style={proposalStyle.questionStyle}>4. Any additional resource files / links</Typography>
                    <Typography style={styles.para}>
                    You may attach up to files under the size of 25 MB each. Include work samples or other documents to support your application. Do not attach your résumé — your Deziner’s Knot profile is automatically forwarded to the client with your proposal.
                    </Typography>
                    <CustomBox className='additional-resource-container'>
                      <CustomBox className='upload-container'>
                        <InputLabel className='button-elm default-text upload-resource bold-text'>
                          <CustomTextField
                            id='cover-image'
                            type='file'
                            hidden
                            inputProps={{
                              accept: 'image/*, video/*, .doc, .docx, .pdf'
                            }}
                            ref={this.refElm}
                            data-test-id='upload-work-file'
                            onChange={(e:React.ChangeEvent<HTMLInputElement>)=>this.handleResourceFiles(e)}
                            style={{ display: "none",
                              fontFamily: 'Inter', 
                              fontSize: '16px', 
                              fontWeight: 600, 
                              lineHeight: '19.36px', 
                              textAlign: 'center' ,
                              cursor:'pointer'

                             }}
                          />
                          Upload Files
                        </InputLabel>
                      </CustomBox>
                      <CustomBox className='link-container' style={{ marginBottom: '30px', marginTop: '12px' }}>
                        <CustomTextField
                          type='text'
                          className='link-text search-field default-text'
                          placeholder='Paste link'
                          data-test-id='input-data'
                          value={this.state.files_or_links}

                          onChange={(event) => this.updateLink(event.target.value)}
                          onKeyPress={(event) => this.handlelinkData(event)}
                        />
                      </CustomBox>
                    </CustomBox>
                    {this.isProjectSelected() && <CustomBox className='card-container horizontal-scroll' style={{display:'block'}}>
                      {this.state.selectedProjectList.map((item, index) => (<CustomBox key={`card-container${item.id}`} style={{ display: 'flex', marginTop:30 }}>
                        <Typography className='list-no hint-text'>{index + 1}.</Typography>
                        <CustomBox>
                          <CustomBox className='card-style'>
                            <img style={{ width: '100%', height: '100%', borderRadius: '10px' }} src={`${config.baseURL + item.attributes.cover_image_url}`} alt='project cover image' />
                          </CustomBox>
                          {this.renderElement(item)}
                        </CustomBox>
                        <DeleteOutlineIcon fontSize="small" data-test-id="selectProject" onClick={() => this.toggleSelectProject(item)} style={{ color: 'white', marginLeft: 5 }} />
                      </CustomBox>
                      ))}
                    </CustomBox>}
                    {this.isFileAdded() && <CustomBox className='flex-data horizontal-scroll' >{this.state.files.map((item:any, index) => (<CustomBox key={index} className='filename-container horizontal-scroll'style={{marginBottom:'10px'}} >
                      <Typography className='list-no hint-text'>{this.state.selectedProjectList.length + index + 1}.</Typography>
                     <CustomBox style={{margin:'unset',
                     width:'215px',
                     whiteSpace: 'nowrap',
                     overflow: 'hidden',
                     textOverflow: 'ellipsis',
cursor:'pointer'
                     }} 
                     data-test-id="filesopenImage"

                     onClick={() => this.openImageInNewTab(item.data)}
                     className='filename-container-elm hint-text'>{item.filename}</CustomBox><DeleteOutlineIcon 
                     data-test-id="deleteFiles"
                     fontSize="small" onClick={() => this.handleDeleteFile(index)} style={{ color: 'white', marginLeft: 5 }} />
                    </CustomBox>))}</CustomBox>}
                    {this.state.linkList.map((item, index) => (<CustomBox style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='list-no hint-text'>{this.state.files.length + this.state.selectedProjectList.length + index + 1}.</Typography>
                      <Typography
                     data-test-id="filesopenLink"

                       onClick={()=>{
                        const newTab = window.open( item, '_blank');
        
                        if (newTab) {
                          newTab.focus();
                        }
                       }} style={{margin:'unset',
                     fontFamily: 'Inter', 
                     fontSize: '16px', 
                     fontWeight: 400, 
                     lineHeight: '19.36px', 
                     textAlign: 'left' ,
                     color:"rgba(82, 138, 223, 1)",
                     width:'245px',
                     whiteSpace: 'nowrap',
                    cursor:'pointer',
                    
                     overflow: 'hidden',
                     textOverflow: 'ellipsis'
                      

                      }}>{item}</Typography>
                      <DeleteOutlineIcon 
                      data-test-id="DeleteOutlineIcon"
                      fontSize="small" onClick={() => this.handleDeleteLink(index)} style={{ color: 'white', marginLeft: 5 }} />
                    </CustomBox>))}
                  </CustomBox>
                  <CustomBox className='formGroup' style={{marginTop:'50px'}}>
                    <Typography style={proposalStyle.questionStyle}>5. When can you start the project?*</Typography>
                    <CustomBox className='hours-container' style={{ backgroundColor: '#222',color:"#FFF2E3"}}>
                      <CustomBox className='date-container' style={{ color:"#FFF2E3",width: '206px', paddingLeft: '15px', paddingRight: '5px',  backgroundColor: '#222' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            data-test-id='date-picker'
                            open={this.state.openCalender}
                            // style={{color:"#FFF2E3"}}
                            minDate={this.handleCurrentDate()}
                            onClose={() => this.closeCalender()}
                            value={this.state.dateData}
                            disablePast={true}
                            onChange={(val: any) => this.handleDate(val)}
                            onAccept={() => this.closeCalender()}
                         
                            slotProps={{
                        
                              openPickerIcon: {
                                onClick: (event: any) => this.openCalender(event)
                              }
                            }}
                          />
                        </LocalizationProvider>
                      </CustomBox>
                    </CustomBox>
                  </CustomBox>
                </CustomBox>
                {
                  this.state.workOppertunityData.attributes.question.length > 0 && <Box style={{ fontFamily: "Inter , sans-serif" }}>
                  <Typography style={{...proposalStyle.questionStyle,marginBottom:"38px"}}>6. Additional questions by client*</Typography>
                  {this.renderQuestions()}
                </Box>
                }
                <Box >
                  <FormControlLabel
                    control={
                      <Checkbox style={{ color: '#fff2e3' }} color="default"
                        checked={this.state.isCheckedTermsAndCondtions}
                        id="handleTermsAndConditions"
                        onChange={(e) => this.handleTermsAndConditions(e.target.checked)}
                      />
                    }
                    label={<Box style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography style={{ fontFamily: "Inter , sans-serif", fontSize: "16px", fontWeight: 600, lineHeight: "19.36px" }}>
                        I agree to all
                      </Typography> {" "}
                      <Typography style={{ fontFamily: "Inter , sans-serif", fontSize: "16px", fontWeight: 600, lineHeight: "19.36px" ,marginLeft: 5, textDecoration: 'underline' }}>
                        terms and conditions
                      </Typography>
                    </Box>}
                  />
                </Box>
              </Box>
                        <Button 
                        data-test-id="handleSubmit"
                        disabled={this.handleDisable()} 
                         onClick={()=>this.handlePopUpOpen()} type='submit' style={{ backgroundColor:this.handleBackGroundColor()
                           , 
                          width: '175px', 
                          height: '44px', 
                          padding: '10px 30px', 
                          borderRadius: '7px', 
                          fontFamily: 'Inter', 
                          fontSize: '16px', 
                          fontWeight: 500, 
                          lineHeight: '24px', 
                          letterSpacing: '0.38px',
                          textTransform:"capitalize"
                           }} 
                        variant="contained">Send Proposal</Button>
            </Box>

          </Box>
        </Box>
          </MainBoxSecond>
        </MainBox>
        </Box>
        { <DioalogComponent
          open={this.state.proposalPopUp}
          onClose={() => this.handlePopUpClose()}
          fullWidth={true}
          data-test-id="close-onclick"
          style={{
            scrollbarWidth:'none'
          }}
          maxWidth="lg"
          classes={{ paper: "post-opportunity-modal" }}
        >
          <DialogContent
          >
            <DialogContentText className="post-opportunity-container">


              <Grid container style={{ paddingBottom: 10 }}>
                <Grid xs={6} lg={6} sm={6} md={6}>
                  <Typography className="heading-preview">
                    Review Proposal
                  </Typography>
                </Grid>
                <Grid xs={6} lg={6} sm={6} md={6} className="second-grid-dialog">
                  <Button className="btn-cancel"
                  data-test-id="btn-cancel"
                    onClick={() => this.handlePopUpClose()}>
                    Cancel
                  </Button>
                  <Button className="btn-send-proposal"
                  data-test-id="btn-send-proposal"
                  onClick={()=>{
                    this.handleSubmit()
                  }}>
                    Send Proposal
                  </Button>
                </Grid>
              </Grid>
              <Divider style={webStyle2.dividerStyle} />

              <Box className="mainBoxPreview">
             <ProfileView
             profileData={this.state.profileData}
             hourlyRate={this.state.hourlyRate}
             />
                <ProposalComponent 
                shareProfile={!this.state.showCollection}
        projectEstimate={this.state.projectEstimate}
        previewData={this.state.previewData}
        showCollection={this.state.showCollection && this.state.previewData.length==0}
        pitchData={this.state.pitchData}
        hourlyRate={this.state.hourlyRate}
        dateData={this.state.dateData}
        isFileAdded={this.isFileAdded()}
        linkList={this.state.linkList}
        files={this.state.files}
        workOppertunityData={this.state.workOppertunityData}
        Question={this.state.Question}
        project_timeline={this.state.workOppertunityData.attributes.project_timeline}
        project_timeline_type={ this.state.workOppertunityData.attributes
          .project_timeline_type}
          hours={this.state.workOppertunityData.attributes
            .required_hours_per_week}
            rateHourlyClient={this.state.workOppertunityData.attributes.rate_amount}
            rateType={this.getRateTypeLabelProposal()}
            findWork={false}
      />
                        </Box>
                            </DialogContentText>
                        </DialogContent>
                    </DioalogComponent>}
      </>
       </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start






export const PaginationComponent = styled(Pagination)({
  '& .MuiPaginationItem-page.Mui-selected': {
    color: "#03B5AA",
    background: "unset",
    fontWeight: 700

  },
 
  '& .MuiPaginationItem-root': {
    color: "#334155",
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 400
  },
 
})

const DioalogComponent = styled(Dialog)({
  '& .MuiPickersLayout-contentWrapper': {
    background: 'rgba(34, 34, 34, 1) '

  },
  '& .MuiDialogContent-root': {
    background: 'rgba(34, 34, 34, 1)'
  }
})
export const InputField = styled(TextField)(()=>({
  ' & .MuiInput-underline::before':{
borderColor: 'none',
border:'unset'
  },
  ' & .MuiInput-underline::after':{
    borderColor: 'none',
    border:'unset'
      },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: 'none',
      border:'unset'

    },
    "&.Mui-focused fieldset": {
      borderWidth: "1px",
      borderColor: 'none',
      border:'unset'


    },
  },
  "& .MuiInputBase-root":{
    color: "rgba(255, 242, 227, 1)",

    border:'unset'

  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderColor: 'none',

    border:'unset'

  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: 'none'
    ,
    borderWidth: "1px",
    border:'unset',

    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 8px",
  }
  , "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: 'none'
  },
  
  "& .MuiFormHelperText-contained": {
    marginLeft: "0px",
    marginRight: "0px"
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':{
    border:"unset",
  }
}));
export const styles = {
  maincontent: {
    backgroundColor: "#0F0F0F",
    color: "white",
    paddingTop:'24px',
    fontfamily: "Inter",
    maxWidth:'1103px'
  },
  textFieldStyle: {
    width: '44px',
    margin: "0px 5px",
    background: 'transparent',
    border: 'none',
    color: "rgba(255, 242, 227, 1)",

    outline: "none",
    fontFamily: 'Inter',
  fontSize: '18.62px',
  fontWeight: 400,
  lineHeight: '22.53px',
  },
  infoBox: {
    width: "400px",
    backgroundColor: '#171717',
    border: '1px solid #323232',
    borderRadius: '11.97px',
    color: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  spancolour: {
    color: "#7d7d7d"
  },
  txtfield: {
    width: "201px",
    height: "62px",
    overflow: "hidden",
    borderRadius: "11.97px",
    color: '#fff',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#313131',
  },
  section: {
    marginBottom: "20px",
  },
  para: {
    color: "#7d7d7d",
    fontFamily: 'Inter', 
    fontSize: '12px', 
    fontWeight: 400, 
    lineHeight: '14.52px', 
  },
  pitch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "50px",
    marginBottom: "12px"
  }
}
export const webStyle2 = {
  description: {
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "30px",
    color: "#FFF2E3",
    marginTop: "13px",
  },
  descriptionTitle: {
    fontFamily: "Inter , sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  btnStyle: {
    fontFamily: "Inter , sans-serif",
    marginTop: "24px",
    backgroundColor: "rgb(50, 50, 50)",
    fontSize: "12px",
    fontWeight: 600,
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    borderRadius: "21px",
    border: " 1px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    width: "max-content",
    height: "37px",
  },
  skillTag: {
    fontFamily: "Inter , sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  btnContainer: {
    display: "flex",

    gap: "16px",
    width: "100%",
  },
  btnBox: {
    display: "flex",

    alignItems: "flex-start",

    width: "100%",

    padding: "50px 30px 50px 0px",
  },
  DeliverablesList: {
    marginTop: "18px",
  },

  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)",
  },

  listItem: {
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",

    color: "#FFF2E3",
  },
  workBox: {
    display: "flex",
    width: "100%",
    padding: "30px 51px",
    gap: "51px",
  },
  month: {
    color: "#FFB9C7",
    padding: "6px 16px 6px 16px",
    justifyContent: "center",
    borderRadius: "24px",
    backgroundColor: "#1D1D1D",
    width: "105px",
    height: "31px",
    display: "flex",
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    fontWeight: 600,
    linehHeigth: "19.36px",
    alignItems: "center",
  },
  priceHour: {
    fontFamily: "Inter , sans-serif",
    fontWeight: 600,
    color: "#1CBAE0",
    padding: "6px 16px 6px 16px",
    borderRadius: "24px",
    width: "145px",
    backgroundColor: "#1D1D1D",
    display: "flex",
    height: "31px",
    fontSize: "16px",
    linehHeigth: "19.36px",
    justifyContent: "center",
    alignItems: "center",
  },

  titleStyle: {
    fontSize: "20px",
    fontWeight: 700,
    fontFamily: "Inter , sans-serif",
    linehHeigth: "24.2px",
    color: "#FFF2E3",
  },
  workBtn: {
    fontFamily: "Inter , sans-serif",
    fontSize: "12px",
    linehHeigth: "14.52px",
    color: "#FFF2E3",
    fontWeight: 400,
    borderRadius: "18.2px",
    border: " 0.5px solid #FFF2E3",
    padding: "11px 22px 11px 22px",
    height: "37px",
  },
  locationBox: {
    marginTop: "61px",
  },
 
  btnHourBox: {
    display: "flex",
    flexWrap:'wrap' as'wrap',
    gap: "19px",
  },
  listNumber: {
    display: "flex",
    alignItems: "center",
    jutifyContent: "center",
    color: "515151",
  },
  listItem2: {
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#3a5680",
    marginLeft: "10px",
    lineBreak: "anywhere" as any

  },
  location: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  listItem1: {
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    minWidth: "215px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    maxWidth: "max-content",
    height: "auto",
    display: "block",
    padding: "6px 15px 6px 15px",
    borderRadius: "10px",
    backgroundColor: "#333231",
    overflow: "hidden",
  },
  imgTitle: {
    fontFamily: "Inter , sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },

  listImg: {
    borderRadius: "4px",
    width: "350px",
  },

  imgUser: {
    fontFamily: "Inter , sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    linehHeigth: "24px",
    color: "#FFF2E3",
  },
  refBtn: {
    fontFamily: "Inter , sans-serif",
    width: "51px",
    fontWeight: 600,
    padding: "4px 13px 4px 13px",
    height: "21px",
    borderRadius: "13.99px",
    marginTop: "2px",
    color: "#FFF2E3",
    lineHight: "13.33px",
    backgroundColor: "828181",
  },
  DeliverItem3: {
    marginTop: "24px",
    display: "flex",
    gap: "14px",
    alignItems: "start",
    marginLeft: "10px",
  },
  imageIcon: {
    display: "flex",
    justifyContent: "center",
    gap: "6px",
    alignItems: "center",
  },
  refContainer: { display: "flex", gap: "55px", marginTop: "17px" },
  container: { width: "100%", height: "auto", overflow: "hidden" },
  paddingBox: { padding: "30px 30px 40px 0px" },
  imgeContainer: { display: "flex", gap: "15px", alignItems: "start" },
  iconNumber: {
    fontFamily: "Inter , sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "30px",
    color: "#FFF2E3",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  
};
// Customizable Area End
