import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Dialog,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, withStyles } from "@material-ui/core/styles";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web"
import { rightIcon } from "./assets";
import CircularProgress from '@mui/material/CircularProgress';
import ToastMSG from '../../../components/src/ToastMSG.web';
import LogOutModal from "../../../components/src/DeleteProjectModel.web"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledTypography = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  borderRadius: "8px",
  borderBottom: "#535353 1px solid",
  padding: "10px",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  color: "#FFF2E3",
  transition: "color 0.3s",
  "&.active": {
    background: "#111111",
  },
}));
export const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-input': {
      backgroundColor: "transparent",
      color: "#FFF2E3",
      border: "none",
      fontFamily: "Inter, sans-serif",
      fontSize: "16px",
      lineHeight: "19.36px",
      textAlign: "left",
      borderBottom: "none",
      '&::placeholder': {
        color: "#676767 !important",
        opacity: 1,
      },
      '&:focus': {
        outline: "none", // Removes outline on focus
      },
    },
    '&::before': {
      borderBottom: "none",
    },
    '&::after': {
      borderBottom: "none",
    },
    '&:hover:not(.Mui-disabled)::before': {
      borderBottom: "none !important",
    },
  },
})(Input);


// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import Location from "./Location/Location.web";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const leftTabs = this.state.userDetail.data.attributes.role_name === "designer" ? this.tabs : this.clientTabs;
    return (
      // Customizable Area Start
      <Box data-test-id='parent-container' style={webStyle.parentContainer}>
        <Box style={webStyle.mainContainer}> 
          <Box style={webStyle.navigationStyle}><NavigationMenu {...this.props} activeTab='user' /></Box>
        </Box>
        <Box style={{display:"flex",height:"100vh"}}>
        <Box style={webStyle.leftContainer}>
          <Box style={webStyle.userDetail}>
            <Box style={webStyle.userStyle}>
                {this.state.userDetail.data.attributes.profile_image ?
                  <img style={webStyle.profileStyle} src={configJSON.baseURL.baseURL + this.state.userDetail.data.attributes.profile_image} alt="" />
                  : <Box style={{ ...webStyle.profileStyle, backgroundColor: this.state.userDetail.data.attributes.background_color }}>  </Box>
                }
                <Box style={webStyle.userHeaderStyle}>
                  <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                      {this.state.userDetail.data.attributes.first_name}
                  </Typography>
                  <Typography style={{...webStyle.userNameStyle,color:"#535353",fontSize:"16px"}}>
                      Online
                  </Typography>
                </Box>
            </Box>
              <Box style={{ marginTop: "26px" }}>
                <Typography style={{...webStyle.userNameStyle,...webStyle.commonTextStyle}}>
                  Settings
                </Typography>
                <Box style={webStyle.menuStyle}>
                  {leftTabs.map((tab: string, index: number) => (
                    <StyledTypography
                      data-test-id = "active-tab-id"
                      key={index}
                      className={this.state.activeTab === tab ? "active" : ""}
                      onClick={() => this.handleActiveTab(tab)}
                    >
                      {tab}
                    </StyledTypography>
                  ))}
                  <Typography data-test-id="log-out-id" style={webStyle.logOutStyle} onClick={this.handleLogOutPopup}>
                    Log Out
                  </Typography>
                </Box>
              </Box>
            </Box>
        </Box>
        {this.state.activeTab === "Password & Security" &&
          <Box style={webStyle.subContainer}>
            <Box style={webStyle.subTextContainer}>
              <Typography
                style={webStyle.headerText}>
                Password & Security
              </Typography>
              <Typography
                style={webStyle.mainHeaderText}>
                Login & Recovery
              </Typography>
              <Typography
                style={webStyle.subHeaderText}>
                Manage your passwords, login preferences and recovery methods
              </Typography>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.rightBoxStyle}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Username:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.user_name}
                  </Typography>
                </Box>
                <Box style={{ ...webStyle.rightBoxStyle,marginLeft: "110px" }}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Email id:
                  </Typography>
                  <Typography
                    style={webStyle.rightTextStyle}>
                    {this.state.userDetail.data.attributes.email}
                  </Typography>
                </Box>
              </Box>
              <Box style={webStyle.rightContainer}>
                <Box style={webStyle.passwordContainer}>
                  <Typography
                    style={webStyle.rightMainText}>
                    Change Password
                  </Typography>
                  <img data-test-id="openDialogId" style={webStyle.passwordImage} src={rightIcon} onClick={this.handleDialog}/>
                </Box>
              </Box>
            </Box>
          </Box>
        }
        {
          this.state.activeTab === "Location & Contact Info" && 
          <Box style={webStyle.subContainer}>
            <Location navigation={this.props.navigation} id={this.props.id} isLocation={true}/>
          </Box>
        }
        {
          this.state.activeTab === "About Company" && 
          <Box style={webStyle.subContainer}>
            <Location navigation={this.props.navigation} id={this.props.id} isLocation={false}/>
          </Box>
        }
        </Box>
        <Dialog open={this.state.passwordPopUp} onClose={this.handlePasswordPopUp}>
          <Box style={webStyle.dialogContainer}>
            <Box style={{ width: "425px" }}>
              <Typography style={webStyle.dialogText}>Change Password</Typography>
              <Box style={webStyle.dialogMainContainer}>
                <Box style={webStyle.dialogHeaderContainer}>
                  <Typography style={webStyle.dialogText}>Current Password*</Typography>
                  {this.state.apiError && <Typography style={{ backgroundColor: "#363636", borderRadius: "24px", padding: "2px 15px", ...webStyle.dialogError }}>incorrect password</Typography>}
                </Box>
                <CustomTextField
                  type="password"
                  placeholder="Enter current password"
                  data-test-id="input-data-currentPassword"
                  value={this.state.currentPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event:any) => { this.handlePasswordChange("currentPassword", event.target.value); }} />
                {this.state.apiError && <Typography data-test-id="forgot-password-id" onClick={this.handleForgotPassword} style={webStyle.forgotPasswordStyle}>Forgot password ?</Typography>}
                <Typography style={webStyle.dialogError}>{this.state.passwordError}</Typography>
              </Box>
              <Box style={webStyle.dialogMainContainer}>
                <Typography style={webStyle.dialogText}>New Password*</Typography>
                <CustomTextField
                  type="password"
                  placeholder="Enter new password"
                  data-test-id="input-data-newPassword"
                  value={this.state.newPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event) => { this.handlePasswordChange("newPassword", event.target.value); }} />
                <Typography style={webStyle.dialogError}>{this.state.newPasswordError}</Typography>
              </Box>
              <Box style={webStyle.dialogMainContainer}>
                <Typography style={webStyle.dialogText}>Re-write New Password*</Typography>
                <CustomTextField
                  type="password"
                  placeholder="Re-enter new password"
                  data-test-id="input-data-confirmPassword"
                  value={this.state.confirmPassword}
                  style={webStyle.dialogTextBox}
                  onChange={(event) => { this.handleConfirmPassword(event.target.value) }} />
                <Typography style={webStyle.dialogError}>{this.state.confirmPasswordError}</Typography>
              </Box>
              <Box style={webStyle.dialogButtonContainer}>
                <Button 
                data-test-id='cancel-btn-id'
                style={{ ...webStyle.dialogButton, ...webStyle.dialogCancelBtn }} onClick={this.handlePasswordPopUp}>
                  Cancel
                </Button>
                <Button 
                disabled={this.checkIfDisabled()}
                data-test-id='save-btn-id'
                style={{ ...webStyle.dialogButton, ...webStyle.dialogSaveBtn }} onClick={this.handleChangePasswordCall}>
                  {this.state.postOpportunityLoader
                && <CircularProgress color='inherit' size={25} />}Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Dialog>
        <LogOutModal
          open={this.state.logOutPopup}
          onClose={this.handleLogOutPopup}
          onDelete={this.handleLogOut}
          title="Are you Sure?"
          content="Please confirm if you want to log out."
          button1Text="Cancel"
          button2Text="Log Out"
          data-test-id="log-out-model" />
        <ToastMSG open={this.state.open} close={this.handleClose} message={this.state.message} action={this.state.action} data-test-id="tost-msg-dialog"/>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  dialogTextBox:{
    marginTop:"16px",
    width: "100%",backgroundColor:"#171717",borderRadius:"9px",border: 'none',
    paddingLeft: '15px',
    paddingRight: '27px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '15px',
    height: '56px',
    outline: 'none',
    lineHeight: '18.15px',
  },
  forgotPasswordStyle:{
    display: "flex", justifyContent: "flex-end", width: "100%", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 300, fontSize: "14px", textDecoration: "underline", cursor: "pointer" 
  },
  dialogError:{
    color:"#FF7878",fontFamily:"Inter",fontWeight:400,fontSize:"14px"
  },
  dialogButtonContainer:{
    display:"flex",justifyContent:"center",gap:"24px",marginTop:"40px"
  },
  dialogText :{
    fontFamily:"Inter",fontWeight:600,fontSize:"20px",color:"#FFF2E3",display:"flex",justifyContent:"center" as "center"
  },
  dialogButton:{
    textTransform:"none" as "none",width:"115px",height:"44px",fontWeight:500,fontSize:"16px",letterSpacing:"0.38px",borderRadius:"7px"
  },
  dialogCancelBtn:{
    backgroundColor: "#0D0D0D", color: "#FFF2E3"
  },
  dialogSaveBtn:{
    backgroundColor: "#FFF2E3", color: "#121212"
  },
  dialogMainContainer:{
    marginTop: "32px", display: "flex", flexDirection: "column" as "column", alignItems: "flex-start"
  },
  dialogHeaderContainer:{
     display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" 
  },
  dialogContainer: {
    width: "572px", height: "maxContent",
    padding: "30px 0px",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#222222",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "12px",
    zIndex: 1000,
    position: "fixed" as "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  parentContainer:{ backgroundColor: "#111111" },
  leftContainer:{
    backgroundColor:"#171717",width:"500px",display:"flex",justifyContent:"center"
  },
  userDetail:{
    width:"413px"
  },
  userStyle:{
    padding:"16px 0px",display:"flex",alignItems:"center",marginTop:"100px",width:"100%",height:"80px",backgroundColor:"#111111",borderRadius:"24px"
  },
  userHeaderStyle:{
    marginLeft:"16px",display:"flex",alignItems:"flex-start",flexDirection:"column" as "column"
  },
  commonTextStyle:{
    color:"#FFF2E3",fontSize:"20px"
  },
  menuStyle:{
    display:"flex",flexDirection:"column" as "column",gap:"16px",padding:"16px 0px"
  },
  logOutStyle:{
    width:"80px",cursor:"pointer",padding:"10px",fontFamily: "Inter", fontSize: "20px", fontWeight: 400, lineHeight: "24px", color: "#FF7878"
  },
  userNameStyle:{
    fontFamily:"Inter",fontWeight:600,lineHeight:"24px"
  },
  profileStyle:{
    marginLeft: "16px", height: "70", width: "70", borderRadius: "50%"
  },
  subContainer:{
    height: "100vh", width: "calc(100% - 500px)"
  },
  subTextContainer:{
    marginLeft: "60px", marginTop: "100px", marginRight: "60px"
  },
  headerText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "36px", letterSpacing: "0.38px"
  },
  subHeaderText:{
    color: "#8C8C8C", fontFamily: "Inter", fontWeight: 600, fontSize: "14px", letterSpacing: "0.38px"
  },
  mainHeaderText:{
    marginTop: "40px", marginBottom: "12px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 600, fontSize: "24px", letterSpacing: "0.38px"
  },
  rightContainer:{
    marginTop: "35px", paddingLeft: "16px", borderRadius: "16px", backgroundColor: "#171717", width: "100%", height: "72px", display: "flex"
  },
  passwordContainer:{
    width:"100%",display: "flex", alignItems: "center",justifyContent:"space-between"
  },
  passwordImage:{
    marginRight:"16px",cursor:"pointer"
  },
  rightBoxStyle:{
    display: "flex", alignItems: "center"
  },
  rightMainText:{
    color: "#FFF2E3", fontFamily: "Inter", fontWeight: 500, fontSize: "20px"
  },
  rightTextStyle:{
    marginLeft: "10px", color: "#FFF2E3", fontFamily: "Inter", fontWeight: 400, fontSize: "14px", lineHeight: "24px", letterSpacing: "0.38px"
  },
  mainContainer:{ 
    display: 'flex',
  flexDirection: 'column' as 'column',
},
  navigationStyle:{
    position: 'fixed' as 'fixed',
    width: '100%',
    zIndex: 999,
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
