import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface StateType {
  passwordError: string;
  currentPassword:string;
  newPassword:string
  newPasswordError: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  apiError:boolean
  logOutPopup:boolean
  postOpportunityLoader:boolean
  newPasswordError:string,
  passwordError:string
  confirmPasswordError:string
  currentPassword: string
  newPassword:string
  confirmPassword:string
  passwordPopUp:boolean
  open:boolean
  action:string
  message:string
  activeTab:string
  userDetail:{
    data:{
      type: string,
      attributes: {
        first_name: string,
        last_name: string | null,
        email: string,
        activated: boolean,
        user_name: string,
        background_color: string,
        city: string,
        country: string,
        profile_image: any,
        role_id: number,
        role_name: string,
      },
    }
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  tabs = [
    "Password & Security",
    "Location & Contact Info",
    "Get Paid",
    "Tax Information",
    "Help & Support",
  ];
  clientTabs = [
    "About Company",
    "Password & Security",
    "Location & Contact Info",
    "Wallet",
    "Tax Information",
    "Help & Support",
  ];
  passwordChangeApiCallId :string = ""
  logOutApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      logOutPopup:false,
      apiError:false,
      message:"",
      action:"",
      open:false,
      postOpportunityLoader:false,
      newPasswordError:"",
      passwordError:"",
      confirmPasswordError:"",
      confirmPassword:"",
      currentPassword:"",
      newPassword:"",
      passwordPopUp:false,
      activeTab:"Password & Security",
      userDetail:{
        data :{
          type: "",
          attributes: {
            first_name: "",
            last_name: "",
            email: "",
            activated: true,
            user_name: "",
            background_color: "",
            city: "",
            country: "",
            profile_image: "",
            role_id: 0,
            role_name: "",
          },
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId1 = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson1 = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId1 === this.passwordChangeApiCallId) {
      
      if (!responseJson1.error) {
        this.handlePasswordPopUp()
        this.setState({
          open: true,
          action: "success",
          message: "Password changed successfully",
        })
        setTimeout(() => {
          this.handleNavigation("EmailAccountLoginBlock");
      }, 1000)
      this.handleLogOutApiCall()
      } else {
        this.setState({
          apiError: true,
          postOpportunityLoader: false,
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          passwordError: "",
          newPasswordError: "",
          confirmPasswordError: "",
          open: true,
          action: "danger",
          message: responseJson1.error,
        })
      }
    }
    if (apiRequestCallId1 === this.logOutApiCallId) {
      if (!responseJson1.errors) {
        removeStorageData("userInfo")
        removeStorageData("saveUserInfo")
        removeStorageData("authToken")
        removeStorageData("userProfile")
        removeStorageData("PublicId")
        removeStorageData("isPublicProfile")
        this.setState({
          logOutPopup:false,
        })
        setTimeout(() => {
          this.handleNavigation("EmailAccountLoginBlock");
        }, 1000)
      } else {
        this.setState({
          logOutPopup:false,
          open: true,
          action: "danger",
          message: responseJson1.errors[0],
        })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    const userInfo = await getStorageData("userInfo",true)
    if(userInfo.data.attributes.role_name !== "designer"){
      this.setState({
        activeTab:"About Company"
      })
    }
    this.setState({
      userDetail:userInfo
    })
  }
  handleActiveTab = (tab:string) =>{
    this.setState({
      activeTab:tab
    })
  }
  handleDialog = () => {
    this.setState({
      passwordPopUp: !this.state.passwordPopUp
    })
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  updatePasswordError = (
    password: string,
    passwordRegex: RegExp,
    errorMessage: string,
    errorStateKey: "passwordError" | "newPasswordError"
  ) => {
    let error = "";
    if (password !== "") {
      error = passwordRegex.test(password) ? "" : errorMessage;
    }
    this.setState({ [errorStateKey]: error } as Pick<StateType, "passwordError" | "newPasswordError">);
  };
  

  validatePassword = (password: string, type: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/;
    const errorMessage =
      "Password must be 8-15 characters and include at least one uppercase letter, one lowercase letter, one digit, and one special character.";
    if (type === "currentPassword") {
      this.updatePasswordError(password, passwordRegex, errorMessage, "passwordError")
    } else if (type === "newPassword") {
      this.updatePasswordError(password, passwordRegex, errorMessage, "newPasswordError")
    }
  };

  validateConfirmPassword = (password: string) => {
    const confirmPasswordError = 
      password !== "" && this.state.newPassword !== this.state.confirmPassword
        ? "The Password doesn't match"
        : "";
  
    this.setState({ confirmPasswordError });
  };
  handleNavigation = async (path:string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    this.send(navigateMessage);
  }
  handleForgotPassword = () =>{
      this.handleNavigation("ForgotPassword")
  }
  handleLogOutPopup = () =>{
    this.setState({logOutPopup:!this.state.logOutPopup})
  }
  handleLogOut = () => {
    this.handleLogOutApiCall()
  }
  handlePasswordChange = (key: "currentPassword" | "newPassword", value: string) => {
    this.setState(
      { [key]: value } as Pick<StateType, "currentPassword" | "newPassword">,
      () => {
        this.validatePassword(value, key);
      }
    );
  };
  handleConfirmPassword = (value: string) => {
    this.setState({
      confirmPassword: value
    },
      () => {
        this.validateConfirmPassword(this.state.confirmPassword);
      }
    );
  };
  handlePasswordPopUp = () => {
    this.setState({
      currentPassword:"",
      newPassword:"",
      confirmPassword:"",
      apiError:false,
      passwordError:"",
      newPasswordError:"",
      confirmPasswordError:"",
      passwordPopUp:false,
      postOpportunityLoader:false
    })
  }
  checkIfDisabled = () => {
    const { confirmPassword, currentPassword, newPassword, confirmPasswordError, passwordError, newPasswordError } = this.state;
    return !confirmPassword || 
           !currentPassword || 
           !newPassword || 
           confirmPasswordError || 
           passwordError || 
           newPasswordError ? true : false;
  }
  handleChangePasswordCall= async ()=> {
    this.setState({postOpportunityLoader:true})
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const body = {
        "current_password": this.state.currentPassword,
        "new_password": this.state.newPassword,
        "confirm_password": this.state.confirmPassword
    }
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePasswordApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.putApiMethod
    );
    this.passwordChangeApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  handleLogOutApiCall= async ()=> {
    const tokens = await getStorageData("userInfo");   
    let newToken = JSON.parse(tokens)
    const { meta: { token } } = newToken; 
    const headers = {
      "Content-Type": "application/json",
      token: token,
    };
    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logOutApiEndPoint
    )
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.validationApiMethodType
    );
    this.logOutApiCallId = getValidationsMsg.messageId
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  // Customizable Area End
}
