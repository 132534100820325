Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT"
exports.deleteApiMethod = "DELETE"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.baseURL = require('../../../framework/src/config');
exports.btnExampleTitle = "CLICK ME";
exports.updatePasswordApiEndPoint="bx_block_settings/settings/update_password"
exports.logOutApiEndPoint="/bx_block_settings/settings/logout"
exports.setDefaultLocationApiEndPoint="/bx_block_settings/addresses/"
exports.getLocationApiEndPoint="/bx_block_settings/addresses/?page=1&per_page=30"
exports.updateUserApiEndPoint="/bx_block_settings/settings/update_user"
exports.validateOtpApiEndPoint ="/bx_block_settings/settings/validate_pin"
exports.resendOtpApiEndPoint="/bx_block_settings/settings/resend_otp"
exports.getCompanyDetailApiEndPoint="/bx_block_settings/settings/get_company_details"
exports.updateCompanyDetailApiEndPoint="/bx_block_settings/settings/"

// Customizable Area End