import React from "react";

// Customizable Area Start
import ToastMSG from '../../../../components/src/ToastMSG.web';
import { withStyles, Radio, TextField, Checkbox, Box, styled, Typography, Input, Button, InputLabel, Grid, Divider, InputAdornment, IconButton,Slider } from '@material-ui/core';
import {closeIcon, checkIcon,addIcon,removeIcon, filelink, dropDownIcon, searchIcon, imgIcon, Backbtn, attachmentIcon } from '../assets';
import NavigationMenu from '../../../navigationmenu/src/NavigationMenu.web';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Dialog, DialogContent, DialogContentText,DialogTitle } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import PlacesAutocomplete from 'react-places-autocomplete';
import AddServiceController, { Props } from "./AddServiceController";
import { ParticularCollection } from '../WorkExperience/WorkExperienceFormController';
import { PaginationComponent } from '../WorkExperience/WorkExperienceForm.web';
import Cropper from "react-easy-crop";
export const configJSON = require("../config");

export const customStyle = {
  inputField: {
      '& .MuiInputBase-input': {
        backgroundColor: "transparent",
        color: "#FFF2E3",
        border: "none",
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        lineHeight: "19.36px",
        textAlign: "left",
        borderBottom: "none",
        '&::placeholder': {
          color: "#676767 !important",
          opacity: 1 
        }
      },
      '&::before': {
        borderBottom: "none"
      },
      '&::after': {
        borderBottom: "none"
      },
      '&:hover:not(.Mui-disabled)::before': {
        borderBottom: "none !important"
      }
  },
  buttonField: {
      textTransform: "none" as "none",
      '&.button-elm': {
          height: "44px",
          borderRadius: "7px",
          alignItems: "center",
          justifyContent: "center",
          verticalAlign: "middle",
          fontFamily: "Inter-Medium,sans-serif",
          lineHeight: "24px",
          letterSpacing: "0.38px",
          fontSize: "16px",
          display: "flex",
          border: "none",
          gap: "10px",
          backgroundColor: "transparent"
      },
      '&.button-elm.secondary-btn': {
          backgroundColor: "#fff2e3"
      },
      '&.button-elm.disabled-btn': {
          backgroundColor: "#7d7d7d"
      },
      '&.button-elm.disabled-btn, &.button-elm.secondary-btn': {
          color: "#121212"
      },
      '&.button-elm.default-btn': {
          color: "#fff2e3",
          backgroundColor: "#0d0d0d"
      }
  },
  tagsElm: {
      '& .tags': {
          color: "#fff2e3",
          height: "37px",
          fontSize: "12px !important",
          lineHeight: "14.52px",
          textAlign: "center",
          verticalAlign: "middle",
          border: "0.5px solid #fff2e3",
          backgroundColor: "transparent",
          borderRadius: "18.2px",
          fontFamily: "Inter, sans-serif !important",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "22px",
          paddingRight: "22px",
          whiteSpace: "nowrap",
          cursor: "pointer",
          width: "max-content"
      },
      '& .tags-active': {
          borderWidth: "1px",
          fontFamily: "Inter-SemiBold, sans-serif !important",
          backgroundColor: "#323232",
          borderRadius: "21px",
          columnGap: "10px"
      }
  }
};
// Customizable Area End

// Customizable Area Start
const theme = createTheme({
    palette: {
        primary: {
            main: '#fff2e3',
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#222',
                    color: '#fff2e3'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#fff2e3 !important',
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#fff2e3 !important'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter,sans-serif !important',
                    color: '#7d7d7d !important',
                    '&.Mui-selected': {
                        color: '#222 !important',
                        backgroundColor: '#fff2e3 !important'
                    },
                    '&.MuiPickersDay-today': {
                        border: '1px solid #fff2e3 !important'
                    }
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0, 
                    paddingBottom: 0,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    overflowX: "hidden",
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0',
                        height: '0',
                    }
                }
            }
        }
    },
});

const TextFieldData = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            height: "56px",
            borderColor: '#66625C',
            borderWidth: "1.62px",
            borderRadius: "9px",
            width:"100%",
            '& fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "9px"
            },
            '&:hover fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "9px"
            },
            '&.Mui-focused fieldset': {
                borderColor: '#66625C',
                borderWidth: "1.62px",
                borderRadius: "9px"
            }
        },
        '& p.MuiFormHelperText-root': {
            fontSize: "14px",
            lineHeight: "16.94px",
            color: "#FFF2E3",
            fontFamily: "Inter, sans-serif"
        },
        "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":{
            borderColor: "#66625C"
        }
    },
})(TextField);

const CustomRadio = withStyles({
    root: {
        color: '#fff2e3',
        '&.MuiTypography-body1': {
            color: '#fff2e3'
        }
    },
    checked: {
        '&.Mui-checked': {
            color: '#fff2e3'
        }
    },

})(Radio);

const CustomCheckBox = withStyles({
    root: {
        color: '#d9d9d9',
        width: '20px',
        height: '20px',
        borderRadius: '2.3px',
        border: 'none'
    },
    checked: {
        '&.Mui-checked': {
            color: '#111111'
        }
    },

})(Checkbox);

const CustomTextField = withStyles({
    root: {
        ...customStyle.inputField
    }
})(Input);

const CustomButton = withStyles({
    root: {...customStyle.buttonField}
})(Button);

const CustomBox = withStyles((theme) => ({
    root: {
        '& .MuiSlider-track': {
            border: 'none',
            height: 5,
            borderRadius:6,
            background: "#FFF2E3"
          },
          '& .MuiSlider-thumb': {
            height: 19,
            width: 19,
            backgroundColor: '#FFF2E3',
            bottom: 3,
            '&:focus, &:hover, &$active': {
              boxShadow: 'inherit',
            },
          },
          '& .MuiSlider-rail': {
            height: 5,
            opacity: 1,
            borderRadius:6,
            backgroundColor: '#626262',
          },
        '&.project-reference-modal': {
            backgroundColor: '#222',
            borderRadius: '9px',
            padding: '53px 63px',
            height: '742px',
            width: '1072px',
            display: 'flex',
            justifyContent: "space-between",
            '& .modal-header-data': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            '& .back-btn': {
                marginTop: '4px',
                alignSelf: 'flex-start',
                cursor:'pointer'
            },
            '& .secondary-text-active' :{
                color: "#fff2e3",
                borderBottom:'4px solid #818181',
                padding:'0px 10px 10px',
                cursor:'pointer',
            },
            '& .secondary-text': {
                color: "#fff2e3",
                
            },
            '& .disabled-text': {
                color: "#7d7d7d",
                marginLeft:'10px',
                cursor:'pointer'
            },
            '& .uncheck-elm': {
                right: "14px",
                top: "14px"
            },
            '& .check-elm': {
                right: "10px",
                top: "10px"
            },
            '& .check-elm, & .uncheck-elm': {
                position: "absolute",
                zIndex: 99,
                cursor:"pointer"
            },
            '& .modal-sub-heading': {
                display: 'flex',
                gap: '54px',
                marginTop: '12px',
                marginRight: '14px',
                borderBottom: '2.33px solid #2F2F2F',
                color: '#fff2e3',
                marginBottom: '32px',
                marginLeft:'-10px'
            },
            '& .accordian-elm': {
                borderRadius: '9px',
                backgroundColor: '#171717',
                border: '1px solid #535353',
                minHeight: '36px',
                padding: '16px',
                marginBottom: '10px'
            },
            '& .accordian-summary': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& .right-elm': {
                display: 'flex',
                alignItems: 'center',
                gap: '16px'
            },
            '& .accordian-collapse': {
                height: '36px',
                width: '36px',
                minWidth: '36px',
                backgroundColor: '#222',
                borderRadius: '9px',
                border: 'none'
            },
            '& .button-text': {
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.38px',
                fontFamily: 'Inter-Medium, sans-serif',
                color: '#fff2e3',
                display: 'flex',
                alignSelf: 'center',
                justifyContent: 'center'
            },
            '& .button-text-count':{
                fontSize: '16px',
                letterSpacing : '0.38px',
                lineHeight: '24px',
                color:'#fff2e3',
                fontFamily: 'Inter-Medium, sans-serif',
                alignSelf:'center',
                justifyContent:'flex-end',
                display:'flex'
            },
            '& .toggle-accordian-up': {
                rotate: "180deg"
            },
            '& .add-btn': {
                width: '92px'
            },
        },
        '&.project-reference-modal,&.post-opportunity-container': { 
            '& .card-container': {
                marginTop: '32px',
                display: 'flex',
                rowGap: '32px',
                columnGap: '24px',
                justifyContent: 'flex-start',
                flexWrap: 'wrap !important'
            },
            '& .card-style': {
                width: '380px',
                height: '260px',
                borderRadius: '9px',
                marginBottom: '8px',
                position: 'relative'
            },
            '& .manage-space-feature, & .manage-space': {
                display: 'flex',
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '11px',
                lineHeight: '13.31px',
                color: '#fff2e3',
                alignItems: 'center',
                maxHeight: '22px'
            },
            '& .card-label, & .card-text': {
                width: '165px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                color: '#fff2e3'
            },
            '& .card-label': {
                fontFamily: 'Inter-SemiBold, sans-serif',
                fontSize: '14px',
                lineHeight: '16.94px',
                marginBottom: '8px'
            },
            '& .manage-space-feature': {
                gap: "2px"
            },
            '& .manage-space': {
                gap: "8px"
            },
            '& .manage-gap': {
                gap: "19px"
            },
            '& .card-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '12px',
                lineHeight: '14.52px'
            },
            '& .default-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                lineHeight: '19.36px'
            },
            '& .text-elm': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '20px',
                lineHeight: '24.2px',
                color: '#fff2e3',
            },
            '& .bold-text': {
                fontFamily: 'Inter-SemiBold, sans-serif !important',
            },
            '& .upload-file-style':{
                cursor : "pointer"
            },
            '& .d-none': {
                display: 'none',
            },
        },
        '&.post-opportunity-container': {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '48px',
            paddingRight: '48px',
            paddingBottom: '50px',
            height: 'auto',
            marginTop: '176px',
            alignItems: 'center',
            '& .default-radius': {
                borderRadius: "9px"
            },
            '& .MuiInput-underline.Mui-disabled::before': {
                borderBottom: "none !important"
            },
            '& .additional-resource-container': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '9px',
                border: '2.33px dashed #0D0D0D',
                paddingTop: '13px',
                width: '427px',
                height: '276px'
            },
            '& .bg-gray': {
                backgroundColor: "#222"
            },
            '& .d-flex': {
                display: "flex"
            },
            '& .flex-column': {
                display: "flex",
                flexDirection: "column"
            },
            '& .secondary-color': {
                color: '#fff2e3'
            },
            '& .hint-color': {
                color: "#696969"
            },
            '& .add-question': {
                width: "108px",
                marginTop: "30px"
            },
            '& .label-text': {
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight:600,
                lineHeight: '24px',
                color: '#fff2e3',
                letterSpacing:"0.38px",
                margin: 0,
                padding: 0
            },
            '& .hint-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '14px',
                lineHeight: '16.94px'
            },
            "& .word-wrap":{
                overflowWrap: "anywhere"
            },
            '& .main-text': {
                fontFamily: 'Inter, sans-serif',
                fontSize: '15px',
                lineHeight: '18.15px',
                color: '#fff2e3'
            },
            '& .hours-container .option-container': {
                left: '-80px'
            },
            '& .disabled-text': {
                color: '#7d7d7d',
                marginLeft:'10px',
                cursor:'pointer'
                
            },
            '& .horizontal-scroll': {
                justifyContent: 'flex-start !important',
                flexWrap: 'nowrap !important',
                overflowX: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
            },
            '& .flex-data': {
                display: 'flex',
                gap: '24px',
            },
            '& .filename-container': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .filename-container-elm': {
                color: '#fff',
                width: 'max-content',
                borderRadius: '9px',
                backgroundColor: '#252525',
                padding: '13px 15px',
            },
            '& .list-no': {
                color: '#7d7d7d',
                paddingRight: '12px',
                fontFamily: 'Inter-SemiBold, sans-serif !important',
                fontSize: '20px',
                lineHeight: '24.2px',
            },
            ...customStyle.tagsElm
        },
        '&.post-opportunity-container ::placeholder': {
            fontStyle: 'italic',
        },
        '&.post-opportunity-container .main-text::placeholder, &.post-opportunity-container .date-container input::placeholder': {
            color: '#686868',
        },
        '&.post-opportunity-container .h-63': {
            height: '63px',
        },
        '&.post-opportunity-container .link-texts::placeholder': {
            color: '#808080',
        },
        '&.post-opportunity-container .rateData::placeholder': {
            color: '#595959',
        },
        '&.post-opportunity-container .question-container': {
            borderTop: '2.33px solid #2F2F2F',
            borderBottom: '2.33px solid #2F2F2F',
            padding: '55px 60px',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .normal-style input::placeholder': {
            fontStyle: 'normal !important',
        },
        '&.post-opportunity-container .add-resource, &.post-opportunity-container .upload-resource': {
            padding: '0',
            height: 'max-content !important',
            borderBottom: '1px solid #fff2e3',
            backgroundColor: 'transparent',
            borderRadius: '0',
            color: '#fff2e3',
            width: 'max-content',
        },
        '&.post-opportunity-container .upload-resource': {
            marginTop: '38px',
        },
        '&.post-opportunity-container .draft-btn': {
            width: '142px',
        },
        '&.post-opportunity-container .medium-text': {
            fontFamily: 'Inter-Medium, sans-serif !important',
        },
        '&.post-opportunity-container .underline-text': {
            width: 'max-content',
            lineHeight: '1.4 !important',
            textDecoration: 'underline'
        },
        '&.post-opportunity-container .date-container fieldset': {
            outline: 'none',
            border: 'none',
        },
        '&.post-opportunity-container .date-container input': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .date-container button': {
            color: '#fff2e3',
        },
        '&.post-opportunity-container .select-btn': {
            width: '100%',
            height: '56px',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: '#848181',
            border: '1px solid #66625c',
            borderRadius: '9px',
            fontSize: '14px',
            fontFamily: 'Inter',
            lineHeight: '14px',
            letterSpacing: '0.38px',
            
        },
        '&.post-opportunity-container .select-btn img.moveup': {
            rotate: '180deg',
        },
        '&.post-opportunity-container .right-data-elm': {
            display: 'flex',
            gap: '18px',
        },
        '&.post-opportunity-container .form-container': {
            padding: '40px 60px',
            backgroundColor: '#171717',
            display: 'flex',
            flexDirection: 'column',
            gap: '56px',
        },
        '&.post-opportunity-container .form-container, &.post-opportunity-container .question-container': {
            maxWidth: '1490px',
            width: '100%',
        },
        '&.post-opportunity-container .search-field': {
            width: '100%',
            backgroundColor: 'transparent',
            border: 'none',
            height: '49px',
            color: '#fff2e3',
        },
        '&.post-opportunity-container .header-container': {
            display: 'flex',
            width: '100%',
            maxWidth: '1348px',
            justifyContent: 'space-between',
            marginLeft: '-350px',
        },
        '&.post-opportunity-container .link-container': {
            width: '405px',
            border: '1.16px solid #3C3C3C',
            borderRadius: '9px',
        },
        '&.post-opportunity-container .link-texts': {
            height: '57px !important',
            textAlign: 'center',
            borderRadius: '9px',
            color: '#fff2e3',
            width:"100%"
        },
        
        '&.post-opportunity-container .formGroup': {
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
        },
        '&.post-opportunity-container .hours-container': {
            display: 'flex',
            alignItems: 'center',
            height: '50px',
            backgroundColor: '#222',
            borderRadius: '9px',
            width: '223px',
            marginTop: '10px'
        },
        '&.post-opportunity-container .hours-container .single-select-container .select-btn': {
            padding: '0',
            height: 'max-content',
            width: '63px',
            borderRadius: '0',
            border: 'none',
        },
        '&.post-opportunity-container .hours-container .single-select-container .title-elm': {
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
        },
        '&.post-opportunity-container .hours-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container .single-select-container .select-btn': {
            width: '156px',
        },
        '&.post-opportunity-container .location-container .single-select-container .title-elm': {
            width: 'max-content',
        },
        '&.post-opportunity-container .location-container .single-select-container .value-elm': {
            display: 'none',
        },
        '&.post-opportunity-container .location-container': {
            width: 'max-content',
        },
        '&.post-opportunity-container .discard-container': {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        '&.post-opportunity-container .discard-container .discard-text': {
            fontSize: '16px',
            fontFamily: 'Inter-SemiBold, sans-serif',
            lineHeight: '19.36px',
            color: '#fff2e3',
            borderBottom: '1px solid #fff2e3',
            width: 'max-content',
            marginLeft: '18px',
        },
        '&.post-opportunity-container .project-title,&.post-opportunity-container .rate-title, &.post-opportunity-container .description-title, &.post-opportunity-container .deliverables-title': {
            border: 'none',
            backgroundColor: '#222',
            borderRadius: '9px',
            paddingLeft: '15px',
            paddingRight: '27px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '15px',
            lineHeight: '18.15px',
        },
        '&.post-opportunity-container .description-title': {
            padding: '27px',
            height: '163px',
            resize: 'none',
        },
        '&.post-opportunity-container .limit-title': {
            color: '#fff2e3',
            textAlign: 'end',          
            marginTop: '-20px'
        },
        '&.post-opportunity-container .project-title': {
            height: '56px',
            maxWidth: '48%',
        },
        '&.post-opportunity-container .rate-title': {
            height: '56px',
            maxWidth: '100%',
        },
        '&.post-opportunity-container .deliverables-title': {
            height: '56px',
        },
        '&.post-opportunity-container .work-type-container': {
            borderRadius: '9px',
            backgroundColor: '#222',
            display: 'flex',
            flexDirection: 'column',
            gap: '43px',
            padding: '28px 0px',
            paddingLeft:"37px",
            maxWidth: '915px',
        },
        '&.post-opportunity-container .work-type-container .text-container': {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxWidth: '915px',
        },
        '&.post-opportunity-container .project-title:focus,&.post-opportunity-container rate-title:focus, &.post-opportunity-container .description-title:focus, &.post-opportunity-container .search-field:focus, &.post-opportunity-container .deliverables-title:focus, &.post-opportunity-container .link-texts:focus, &.post-opportunity-container .question-data input:focus': {
            outline: 'none',
        },
        '&.post-opportunity-container .upload-container': {
            display: 'flex',
            width: '405px',
            height: '115px',
            justifyContent: 'center',
            backgroundColor: '#222',
            borderRadius: '9px',
        },
        '&.post-opportunity-container .heading-text': {
            fontSize: '36px',
            lineHeight: '43.57px',
            color: '#fff2e3',
            fontFamily: 'Inter-SemiBold, sans-serif',
        },
        '&.post-opportunity-container .option-container-collection .menu-item': {
            color: '#fff2e3',
            cursor: 'pointer',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            borderTop: '1px solid #5f5f5f',
            zIndex: '12',
            backgroundColor: '#171717',
        },
        '&.post-opportunity-container .option-container-collection .menu-item.active': {
            backgroundColor: '#222',
        },
        '&.post-opportunity-container .option-container-category, &.post-opportunity-container .option-container-collection': {
            borderRadius: '0 0 9px 9px',
            border: '1px solid #66625c',
            borderTop: 'none',
            position: 'absolute',
            width: '13%',
            maxHeight: '205px',
            overflow: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .option-container-category::-webkit-scrollbar, &.post-opportunity-container .option-container-collection::-webkit-scrollbar, &.post-opportunity-container .horizontal-scroll::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .menu-item:last-child': {
            borderRadius: '0 0 9px 9px',
        },
        '&.post-opportunity-container .link-text-data': {
            color: '#528ADF',
        },
        '&.post-opportunity-container .no-border': {
            border: 'none !important',
        },
        '&.post-opportunity-container .menu-open': {
            borderRadius: '9px 9px 0 0',
            height: '56px',
            border: '1px solid #5f5f5f',
            borderBottom: 'none',
        },
        '&.post-opportunity-container .select-container': {
            position: 'relative',
        },
        '&.post-opportunity-container .search-container': {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            paddingLeft: '22px',
            paddingRight: '22px',
        },
        '&.post-opportunity-container .skill-container': {
            borderRadius: '9px',
            border: '1px solid #323232',
        },
        '&.post-opportunity-container .skill-Suggestion': {
            fontSize: '12px',
            color: '#666',
            lineHeight: '14.52px',
            fontFamily: 'Inter, sans-serif',
            marginBottom: '16px',
            paddingTop: '22px',
            borderTop: '1px solid #2f2f2f',
            paddingLeft: '22px',
        },
        '&.post-opportunity-container .active-skill-list-container': {
            paddingTop: '16px',
            marginBottom: '8px',
        },
        '&.post-opportunity-container .skill-list-container, &.post-opportunity-container .active-skill-list-container': {
            display: 'flex',
            gap: '20px',
            marginRight:"20px",
            marginLeft:"20px",
            marginBottom: '22px',
            overflow: 'auto',
            maxHeight: '100px',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
        },
        '&.post-opportunity-container .skill-list-container::-webkit-scrollbar, &.post-opportunity-container .active-skill-list-container::-webkit-scrollbar': {
            width: '0',
            height: '0',
        },
        '&.post-opportunity-container .tags': {
            width: 'max-content',
        },
        '&.post-opportunity-headerData': {
            display: 'flex',
            flexDirection: 'column',
        },
        '&.post-opportunity-container .banner-data': {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: '446px',
            marginTop: '76px',
        },
        '&.post-opportunity-container .discardBtn': {
            fontFamily: 'Inter-SemiBold, sans-serif',
            fontSize: '16px',
            lineHeight: '19.36px',
            height: 'max-content',
            width: 'max-content',
            borderBottom: '1px solid #fff2e3',
            color: '#fff2e3',
            padding: '0',
            backgroundColor: 'transparent',
            border: 'none',
        },
        '&.post-opportunity-container .create-project:active': {
            opacity: '0.5',
        },
        '&.post-opportunity-container .pointer-cursor': {
            cursor: 'pointer',
        },
        '&.post-opportunity-container .question-data': {
            maxWidth: '908px',
            width: '100%',
            height: '74px',
            borderRadius: '9px',
            backgroundColor: '#222',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '16px',
            paddingRight: '16px',
            justifyContent: 'space-between',
        },
        '&.post-opportunity-container .mt-50': {
            marginTop: '50px',
        },
        '&.post-opportunity-container .mt-16': {
            marginTop: '16px',
        },
        '&.post-opportunity-container .question-data input': {
            height: '74px',
            backgroundColor: 'transparent',
            fontFamily: 'Inter, sans-serif',
            fontSize: '16px',
            lineHeight: '21.2px',
            width: '95%',
            color: '#fff2e3',
            border: 'none',
        },
        '&.post-opportunity-container .flex-center': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        '&.post-opportunity-headerData .fixed-top': {
            position: 'fixed',
            width: '100%',
            zIndex: '999',
        },
        '&.post-opportunity-container .location-container .option-container>div, &.post-opportunity-container .hours-container .option-container>div': {
            width: '156px !important',
        },
        '&.parent-element': {
            backgroundColor: "#111111"
        },
        '& .option-container-category .menu-item, & .option-container-collection .menu-item': {
                color: '#fff2e3',
                cursor: 'pointer',
                fontFamily: 'Inter, sans-serif',
                fontSize: '16px',
                lineHeight: '19.36px',
                padding: '0 16px',
                display: 'flex',
                alignItems: 'center',
                height: '56px',
                borderTop: '1px solid #5f5f5f',
                zIndex: 12,
                backgroundColor: '#171717',
                '&.active': {
                    backgroundColor: '#222',
                    color: '#61CA78',
                }
            },
            '& .option-container-category, & .option-container-collection': {
                borderRadius: '0 0 9px 9px',
                border: '1px solid #66625c',
                borderTop: 'none',
                position: 'absolute',
                maxHeight: '205px',
                overflow: 'auto',
                zIndex: 12,
                scrollbarWidth: 'none',
                '-ms-overflow-style': 'none',
                '&::-webkit-scrollbar': {
                    width: '0',
                    height: '0',
                }
            },
        '& .inputLabelConatiner': {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: '20px'
        },
        '& .select-collection-heading_Exp':{
            fontFamily: 'Inter, sans-serif',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.94px',
            color:' rgba(125, 125, 125, 1)',
            
           },
           '& .select-all-heading_Exp':{
  fontFamily: 'Inter, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.36px',
  color: 'rgba(255, 242, 227, 1)',
  
 },
 '& .collection-heading-item-style_Exp':{
    fontFamily: 'Inter,sans-serif',
  fontSize: '14px',
  fontWeight: 700,
  lineHeight: '16.94px',
  color: 'rgba(255, 242, 227, 1)',
   },
   '& .collection-subheading-item-style_Exp':{
    fontFamily: 'Inter,sans-serif',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '14.52px',
  color: 'rgba(255, 242, 227, 1)',
   },
   '& .cover-image-container': {
                width: '381px',
                height: '357px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '17px',
                border: '1px dashed #8C8C8C',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                marginTop: '15px'
            },
            '& .button-elm': {
                width: '155px',
                fontFamily: 'Inter, sans-serif !important'
            },
'& .cover-label': {
                width: '158px',
                fontFamily: 'Inter-SemiBold, sans-serif !important'
            },
            '& .closebtn-elm': {
            width: "12px",
            height: "14.24px"
        },
        '&.share-project-popup .crop-container': {
            width: "100%",
            height: "536px"
        },
        '&.share-project-popup .cornerBox': {
            width: "38.8px",
            height: "42.33px",
            border: "10px solid #fff2e3",
            position: "absolute",
            zIndex: 2
        },
        '&.share-project-popup .cornerBox1': {
            left: '11.8%',
            borderRightStyle: 'none',
            borderBottomStyle: 'none',
            top: '8.3%',
            borderRadius: '10px 0px 0px 0px'
        },
        '&.share-project-popup .cornerBox2': {
            right: '11.8%',
            borderBottomStyle: 'none',
            borderLeftStyle: 'none',
            top: '8.3%',
            borderRadius: '0px 10px 0px 0px',
        },
        '&.share-project-popup .cornerBox3': {
            right: '11.8%',
            bottom: '8.5%',
            borderLeftStyle: 'none',
            borderTopStyle: 'none',
            borderRadius: '0px 0px 10px 0px',
        },
        '&.share-project-popup .cornerBox4': {
            left: '11.8%',
            bottom: '8.5%',
            borderRightStyle: 'none',
            borderTopStyle: 'none',
            borderRadius: '0px 0px 0px 10px',
        },
        '&.share-project-popup .gap-elm': {
            margin: 0,
            padding: "5px 0"
        },
        '&.share-project-popup .discardBtn': {
            fontFamily: "Inter-SemiBold,sans-serif",
            fontSize: "16px",
            lineHeight: "19.36px",
            height: "max-content",
            width: "max-content",
            color: "#fff2e3",
            padding: 0,
            backgroundColor: "transparent",
            border: "none"
        },
        '&.share-project-popup .saveProgressBtn': {
            width: "154px"
        },
        '&.share-project-popup input[type="range"]': {
            fontSize: "1.5rem",
            width: "12.5em",
            color: "#fff2e3",
            '--clip-edges': "0.125em",
            position: "relative",
            background: "#fff0",
            overflow: "hidden",
            '&::-webkit-slider-thumb': {
                height: '19px'
            },
            '&::-webkit-slider-runnable-track': {
                height: '6px',
                backgroundColor: '#626262',
                clipPath: 'inset(0 var(--clip-edges) 0 var(--clip-edges))',
            },
            '&:hover': {
                filter: 'brightness(180%)',
            },
            '&:active': {
                filter: 'brightness(80%)',
            }
        },
        '&.share-project-popup input[type="range"],&.share-project-popup input[type="range"]::-webkit-slider-runnable-track,&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            transition: 'all ease 100ms',
            height: 'var(--thumb-height)',
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-runnable-track,&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            position: "relative"
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-thumb': {
            '--thumb-radius': 'calc((var(--thumb-height) * 0.5) - 1px)',
            '--clip-top': 'calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px)',
            '--clip-bottom': 'calc(var(--thumb-height) - var(--clip-top))',
            '--clip-further': 'calc(100% + 1px)',
            '--box-fill': 'calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor',
            width: 'var(--thumb-width, var(--thumb-height))',
            background: 'linear-gradient(currentColor 0 0) scroll no-repeat left center / 50% calc(var(--track-height) + 1px)',
            backgroundColor: 'currentColor',
            boxShadow: 'var(--box-fill)',
            borderRadius: 'var(--thumb-width, var(--thumb-height))',
            filter: 'brightness(100%)',
            clipPath: 'polygon(100% -1px, var(--clip-edges) -1px, 0 var(--clip-top), -100vmax var(--clip-top), -100vmax var(--clip-bottom), 0 var(--clip-bottom), var(--clip-edges) 100%, var(--clip-further) var(--clip-further))',
        },
        '&.share-project-popup input[type="range"]::-webkit-slider-runnable-track': {
            height: "4px",
            background: 'linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px)'
        },
        '&.share-project-popup input[type="range"],&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-thumb': {
            appearance: "none",
            transition: "all ease 100ms",
            height: "var(--thumb-height)"
        },
        '&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-thumb,&.share-project-popup input[type="range"]::-moz-range-progress': {
            background: "#fff0"
        },
        '&.share-project-popup input[type="range"]::-moz-range-thumb': {
            background: 'currentColor',
            border: 0,
            width: 'var(--thumb-width, var(--thumb-height))',
            borderRadius: 'var(--thumb-width, var(--thumb-height))',
            cursor: 'grab',
        },
        '&.share-project-popup input[type="range"]::-moz-range-track': {
            width: "100%",
            background: "var(--track-color)"
        },
        '&.share-project-popup input[type="range"]::-moz-range-progress': {
            appearance: "none",
            background: "currentColor",
            transitionDelay: "30ms"
        },
        '&.share-project-popup input[type="range"]::-moz-range-track,&.share-project-popup input[type="range"]::-moz-range-progress': {
            height: 'calc(var(--track-height) + 1px)',
            borderRadius: 'var(--track-height)'
        },
        '&.share-project-popup input[type="range"]::-moz-range-thumb,&.share-project-popup input[type="range"]::-moz-range-progress': {
            filter: "brightness(100%)"
        },
        '&.share-project-popup .reactEasyCrop_Container': {
            height: "100%",
            width: "100%",
            left: "0 !important",
            top: "0 !important",
            bottom: 0
        },
        '&.share-project-popup .controls': {
            display: "flex",
            alignItems: "center",
            gap: "19px"
        },
        '&.share-project-popup .controls .imgIconFirst': {
            marginLeft: "10px",
            width: "25px",
            height: "25px"
        },
        '&.share-project-popup .controls .imgIconLast': {
            marginLeft: "10px",
            width: "37px",
            height: "37px"
        },
        '&.share-project-popup .slider': {
            padding: "22px 0px"
        },
        '&.share-project-popup .reactEasyCrop_CropArea': {
            border: "1px solid #fff2e3 !important"
        },
        '&.share-project-popup .reactEasyCrop_CropArea::before,&.share-project-popup .reactEasyCrop_CropArea::after': {
            border: "none !important"
        },
        '&.share-project-popup .create-project': {
            fontFamily: "Inter-Medium,sans-serif !important"
        },
        '&.share-project-popup .create-project:active':  {
            opacity: 0.5
        },
        '&.share-project-popup .pointer-cursor': {
            cursor: "pointer"
        },
        '&.share-project-popup .bold-elm': {
            fontFamily: "Inter-SemiBold,sans-serif !important"
        },
        '&.share-project-popup .space-between-elm': {
            display: "flex",
            justifyContent: "space-between !important",
            width: "100%"
        },
        '&.share-project-popup .right-section-elm': {
            gap: "34px",
            marginRight: "35px"
        },
        '&.share-project-popup .left-elm-container': {
            marginLeft: "35px"
        },
        '&.share-project-popup .relative-position': {
            position: "relative",
            borderTop: "0.54px solid #525252",
            borderBottom: "0.54px solid #525252",
            paddingLeft: "35px",
            paddingRight: "35px",
            width: "84%"
        },
        '&.share-project-popup .crop-border-container': {
            position: "absolute",
            border: "1px solid #8C8C8C",
            bottom: "9.3%",
            left: "12.3%",
            borderRadius: "10px",
            zIndex: 99
        },
        '&.share-project-popup .flex-container': {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        '&.share-project-popup .h-94': {
            height: "94px"
        },
        '&.share-project-container .fs-16': {
            fontSize: "16px !important",
            left: "16.36px !important"
        },
        '&.share-project-popup .close-icon': {
            width: "12px",
            height: "14.24px"
        },
        '&.share-project-container .text-elm,&.share-project-popup .text-elm': {
            fontFamily: "Inter,sans-serif",
            fontSize: "20px",
            lineHeight: "24.2px",
            color: "#fff2e3"
        },
        '&.story-upload-btn.closebtn': {
            position: "absolute",
            top: "-6px",
            right: "0px",
            padding: 0,
            border: "none",
            cursor: "pointer",
            background: "transparent"
        },
        [theme.breakpoints.down(1440)]: {
            '&.post-opportunity-container': {
                '& .form-container,& .question-container': {
                    maxWidth: "811px"
                },
                '& .header-container': {
                    maxWidth: "1081px",
                    marginLeft: "-90px"
                }
            }
        },
        [theme.breakpoints.up(1440)]: {
            '&.post-opportunity-container': {
              '& .form-container, & .question-container': {
                maxWidth: "1000px", // Adjust width as needed
              },
              '& .header-container': {
                maxWidth: "1200px", // Adjust width as needed
                marginLeft: "-50px", // Adjust margin as needed
              },
            },},
        [theme.breakpoints.up(1600)]: {
            '&.post-opportunity-container': {
              '& .form-container, & .question-container': {
                maxWidth: "1100px", // Adjust width as needed
              },
              '& .header-container': {
                maxWidth: "1200px", // Adjust width as needed
                marginLeft: "-140px", // Adjust margin as needed
              },
            },}
    }
}))(Box);

const PlaceAutoCompleteWrapper = styled(Box)({
    width:"100%",
    "& .placeAutoComplete": { fontFamily: 'Inter', color: '#FFF2E3', fontSize: "14px", lineHeight: "16.94px" },
    "& .suggestion_box": { position: 'absolute', zIndex: 100, backgroundColor: 'rgba(0, 0, 0, 0.9)', width: 400 },
    "& .suggestion_container": { backgroundColor: 'transparent', padding: 2 },
    "& .suggestion": { padding: "3", color: '#FFF2E3', cursor: 'pointer' }
});
// Customizable Area End

export default class AddService extends AddServiceController {
  constructor(props: Props) {
    super(props);

     // Customizable Area Start
     // Customizable Area End
  }

    // Customizable Area Start
    handleToggleButtons = (isOpen: boolean, label: string, currentValue: string | undefined | any, toggleFunction: (event: React.MouseEvent<HTMLButtonElement>) => void) => (
        <CustomButton
            data-test-id="toggle-menu"
            className={`select-btn ${isOpen ? "menu-open" : ""}`}
            onClick={toggleFunction}
            disableRipple={true}
        >
            {(isOpen || !currentValue) ? label : currentValue}
            <img className={`${isOpen ? "moveup" : ""}`} src={`${dropDownIcon}`} alt="dropdown icon" />
        </CustomButton>
    );
    handleContainer = (isOpen: boolean, list: any[], currentValue: any, handleItemClick: (item: any) => void,width:string) => (
        <CustomBox className="option-container-category" style={{ display: isOpen ? "block" : "none",width:width}}>
            {list.map((item: any, index: number) => (
                <CustomBox
                    className={`menu-item ${currentValue === item ? "active" : ""}`}
                    key={`item${index}`}
                    data-test-id="category-menu"
                    onClick={() => handleItemClick(item)}
                >
                    {item.name || item}
                </CustomBox>
            ))}
            {!list.length && (
                <CustomBox className="menu-item">
                    No record found.
                </CustomBox>
            )}
        </CustomBox>
    );
    
    handleCollectionList=()=>{
        return    this.state.collectionList.map((collection:any, index:number) => {
          return (
            <Box  key={index} style={{display:'flex',
              background:this.state.currentCollectionId==collection.id ?'rgba(33, 33, 33, 1)':'unset',
            cursor:'pointer',flexDirection:'row',paddingInline:'15px',gap:'10px',alignItems:'center',justifyContent:'space-between'}} data-test-id="collectionData" onClick={() => this.handleCollection(collection.id)}>
            <p 
            className="collection-item-style"
            style={{ padding: "4px 0px", cursor: "pointer",  fontWeight:this.state.currentCollectionId==collection.id ?600:400}} >{collection.name}</p>
        <Box className="collection-style">
      
            <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.31641 9L5.31641 5L1.31641 1" stroke="#FFF2E3" stroke-width="1.52371" stroke-linecap="round"/>
      </svg>
        </Box>
      
      
            </Box>
          )
        })
      }
      handleCheckIcon=(data:any)=>{
        return this.handleViewCheck(data)?  <img
                                style={webStyle.viewCheck }
                                src={ checkIcon}
                              />
                              :
                              <Box style={webStyle.viewUnCheck}>
      
                              </Box>
      }
      handleCheckIconForCover=(data:any)=>{
        return this.handleViewCheckCover(data)?  <img
                                style={webStyle.viewCheckCover}
                                src={ checkIcon}
                              />
                              :
                              <Box   style={webStyle.unCheckCover}>
      
                              </Box>
      }
      handleViewCheck = (item: ParticularCollection) => {
        const matchedData = this.state.tempSelectedProjectList.includes(item.id);
        return matchedData ? true : false
      }
      handleViewCheckCover = (item: any) => {
        const matchedData = this.state.tempSelectedCover === item
        return matchedData ? true : false
      }
      uploadCoverContainer = (index?:number|undefined) =>(
        <CustomBox 
    className={`cover-image-container ${this.state.croppedImage.length > 0 ? 'no-border' : ''}`} 
    data-test-id="cover-image-container"
    onDragOver={this.handleDragOver}
    onDrop={(event) => this.handleDrop(event, "coverImage")}
    style={{
         position: "relative",
        backgroundImage: 
            this.state.croppedImage.length > 0 && index !== undefined && this.state.croppedImage[index]
                ? `url("${this.state.croppedImage[index]}")`
                : "none"
    }}
>
    <CustomBox 
        style={{
            alignItems: "center", 
            display: this.state.croppedImage.length > 0 ? "none" : "flex", 
            flexDirection: "column", 
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}
    >
        <CustomButton 
            key={`coverImage${this.state.coverImage.length}`} 
            className="button-elm default-btn cover-label" 
            onClick={this.handleUploadImageClick} 
            data-test-id="upload-image-button"
        >
            <InputLabel 
                style={{color: "#fffee3", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px"}}
                onClick={e => e.stopPropagation()} 
                data-test-id="upload-image-label"
            >
                <Input 
                    id="cover-image" 
                    type="file" 
                    hidden 
                    inputProps={{ accept: "image/*" }}
                    ref={this.refElm}
                    data-test-id={"cover-image-id"}
                    onChange={this.handleCoverImage}
                    style={{ display: "none" }}
                />
                Upload Image
            </InputLabel>
        </CustomButton>
    </CustomBox>
    {index !== undefined && index < 2 && 
    <Box>
        <CustomBox 
            key={index} 
            style={{ display: "flex", alignItems: "center", backgroundColor: "#171717", top: "10px", right: "10px", position: "absolute", height: "35px", borderRadius: "12px", padding: "0px 10px" }}
            onClick={() => this.handleSelectCover(index)}
        >
            <label 
                htmlFor={index.toString()} 
                style={{ color: "#fffee3", marginLeft: "8px", fontFamily: "Inter-Medium,sans-serif", fontSize: "16px" }}
            >
                Cover Image
            </label>
            {this.handleCheckIconForCover(index)}
        </CustomBox>
    </Box>
}

</CustomBox>

    )
   
    renderPostButton = () => (
        <CustomButton data-test-id='create-work'
        disabled={this.state.isEditPage ? this.disableButtonLogic() : this.state.postOpportunityLoader || !this.enablePostWorkOpportunity()}
            onClick={() => this.handleModelOpen()}
            className={`button-elm ${this.enableButtonText()} medium-text`}
            style={{cursor:"pointer"}}
            >
                {this.state.postOpportunityLoader
                && <CircularProgress color='inherit' size={25} />}
{this.renderHeading()}
            </CustomButton>
    )
    renderDeliverable = () => (
        <CustomBox className='formGroup'>
            <Typography className='label-text'>Deliverables<span style={webStyle.requiredStyle}>*</span></Typography>
            {this.state.deliverables.map((deliverable, index) => (
                <Box key={index} style={webStyle.deliverableContainer}>
                    <Box key={index} style={{ display: 'flex', alignItems: "center", backgroundColor: "#222", width: deliverable ? "100%" : "", maxWidth: deliverable ? "100%" : "25%", paddingLeft: "2%", borderRadius: "11.97px" }}>
                        <Typography style={webStyle.deliverableCount}>{index + 1}. </Typography>
                        <CustomTextField
                            className="deliverables-title main-text"
                            type="text"
                            placeholder="Add deliverable"
                            data-test-id="input-data-deliverable"
                            value={deliverable}
                            inputProps={{
                                maxLength: 500,
                            }}
                            style={{ width: deliverable ? "100%" : "" }}
                            onChange={(event) => this.handleDeliverablesChange(index, event.target.value)}
                        />

                    </Box>
                    {index === this.state.deliverables.length - 1 ? (
                        <img
                            style={{
                                objectFit: "contain",
                                height: "41px",
                                width: "41px",
                                cursor: this.state.deliverables[0] ? "pointer" : "",
                                pointerEvents: this.state.deliverables[0] ? "auto" : "none",
                                opacity: this.state.deliverables[0] ? 1 : 0.5,
                            }}
                            src={addIcon}
                            alt="add icon"
                            onClick={this.state.deliverables[0] ? this.addDeliverable : undefined}

                        />
                    ) : (
                        <img
                            style={
                                webStyle.removeIconStyle
                            }
                            src={removeIcon}
                            alt="remove icon"
                            onClick={() => this.removeDeliverable(index)}
                        />
                    )}
                </Box>
            ))}

        </CustomBox>
    )
    renderSkills = () => (
        <CustomBox className='formGroup'>
            <Typography className='label-text'>Add Skills<span style={webStyle.requiredStyle}>*</span></Typography>
            <CustomBox className='skill-container'>
                {this.isSkillAdded() && <CustomBox className='active-skill-list-container'>
                    {this.state.selectedSkillTags.map((list: any, index: any) => <CustomBox
                        className='tags tags-active'
                        data-test-id='selectedSkillTags'
                        key={`selectedSkillTags${index}`}
                        onClick={() => this.handleRemoveSkillTags(list)}
                    >
                        <img src={`${closeIcon}`} alt='remove skill' />{list.name}</CustomBox>)}
                </CustomBox>}
                <CustomBox className={`search-container`}>
                    <img src={`${searchIcon}`} alt='search skill tags' />
                    <CustomTextField
                        type='text'
                        placeholder='Search'
                        className={this.handleClassess()}
                        disabled={!this.state.skillList.length}
                        value={this.state.searchText}
                        data-test-id='input-data-search-skill'
                        onChange={(event) => this.handleChange(event.target.value)}
                        endAdornment={
                            this.state.searchText.length > 0 && (
                                <InputAdornment position="end">
                                    <IconButton
                                        id="clear-text"
                                        data-test-id="closeOnSearch"
                                        style={{
                                            color: "white",
                                            fontFamily: "Inter, sans-serif",
                                        }}
                                        size="small"
                                        onClick={this.handleCancel}
                                    >
                                        x
                                    </IconButton>
                                </InputAdornment>)
                        }
                    />
                </CustomBox>
                {this.handleSkillView() ? <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container'>
                    {this.handleSkillORSearchList().map((list: any, index: any) => <CustomBox
                        className='tags'
                        data-test-id='skillList'
                        key={`skillTags${index}`}
                        onClick={() => this.handleAddSkillTags(list)}
                    >{list.name}</CustomBox>)}</CustomBox></>
                    : <><Typography className='skill-Suggestion'>Suggestions</Typography><CustomBox className='skill-list-container text-elm fs-16'>No result found</CustomBox></>}
            </CustomBox>
        </CustomBox>
    )
    renderCoverImage= ()=>(
        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Add Image<span style={webStyle.requiredStyle}>*</span></Typography>
                                {this.state.croppedImage.length > 0 ? (
                                    <Box>
                                    <Box style={webStyle.addImageContainer} id="image-upload-id">
                                        {this.state.croppedImage.map((data: any, index: number) => (
                                            <Box key={index} style={webStyle.deliverableContainer}>
                                                {this.uploadCoverContainer(index)}
                                            </Box>
                                        ))}
                                        {this.state.croppedImage.length < 26 &&
                                        <CustomButton key={`coverImage${this.state.coverImage.length}`} style={webStyle.coverContainer} onClick={this.handleUploadImageClick} data-test-id="upload-image-button">
                                            <InputLabel style={webStyle.coverMainContainer} onClick={e => e.stopPropagation()} data-test-id="upload-image-label">
                                                <Input
                                                    id="cover-image"
                                                    type="file"
                                                    hidden
                                                    inputProps={{
                                                        accept: "image/*"
                                                    }}
                                                    ref={this.refElm}
                                                    onChange={this.handleCoverImage}
                                                    style={{ display: "none" }}
                                                />
                                                +
                                            </InputLabel>
                                        </CustomButton>}
                                    </Box>
                                    <Box style={webStyle.countContainer}>
                                        <img src={attachmentIcon} alt='attachment icon' />
                                        <Typography style={webStyle.imageCount}>{this.state.croppedImage.length} {this.state.croppedImage.length > 1 ? "images":"image"} attached</Typography>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box style={webStyle.uploadCoverContainer}>
                                        {this.uploadCoverContainer()}
                                    </Box>
                                )}
                            
                            
                        </CustomBox>
    )

    renderEmptyScreen = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <Box sx={webStyle.contentCon}>
                    <Box
                        sx={webStyle.backdrop} >
                        <Box sx={webStyle.progressCon}>
                            <CircularProgress className="circular" />
                        </Box>
                    </Box>
                </Box>
            )
        }
    }
    
    renderLinks=()=>(
        <CustomBox className='formGroup'>
                                <Typography className='label-text'>Add Reference Links</Typography>
                                <Box>
                                    <Box style={webStyle.linkContainer}>
                                        <Box style={webStyle.linkMainContainer}>
                                            <img
                                                style={webStyle.linkIcon}
                                                src={filelink} alt="add icon" />
                                            <CustomTextField
                                                className='link-texts main-text'
                                                type='text'
                                                placeholder='www.linklink.com'
                                                data-test-id='input-data-link'
                                                value={this.state.currentLink}
                                                inputProps={{
                                                    maxLength: 500
                                                }}
                                                onChange={(event) => this.handleLinkChange(event.target.value)}
                                            />

                                        </Box>
                                        <img
                                            style={{ objectFit: "contain", height: "41px", width: "41px", 
                                                cursor: this.state.currentLink.trim() ? "pointer" : "",
                                                opacity: this.state.currentLink.trim() ? 1 : 0.5, }}
                                                data-test-id = "linkIcon"
                                                onClick={() => {
                                                    if (this.state.currentLink.trim()) this.handleAddLink();
                                                  }}
                                            src={addIcon}
                                            alt="add icon" />
                                    </Box>
                                    {this.state.links.map((data) => (
                                        <Box style={webStyle.linksSubContainer}>
                                            <Typography style={webStyle.linkStyle}>
                                                {data}
                                            </Typography>

                                        </Box>
                                    ))}
                                </Box>
                                
                            </CustomBox>
    )
    renderHeading = () => {
        return this.state.isEditPage ? configJSON.editServiceText : configJSON.addServiceText;
    }

    renderMinTimeLineEroor = () => {
        return this.state.minTimeLineError ?
            <Typography style={webStyle.redColor}>
                {this.state.minTimeLineError}
            </Typography> :
            null
    }

    renderMinRateError = () => {
        return  this.state.minRateError ? 
        <Typography style={webStyle.redColor}>
            {this.state.minRateError} 
        </Typography> : 
        null      
    }

    renderPaginationComponent = () => {
        return this.state.totalCount > 1 ?
            <Box style={webStyle.totalCount}>
                <PaginationComponent count={this.state.totalCount}
                    onChange={this.handleChanges}
                    page={this.state.currentPage}
                />
            </Box> :
            null
    }

    renderCollectionList = () => {
        return this.state.collectionList.length > 0 ?
        this.handleCollectionList()
    : null
    }
    renderCropImage = () => {
     return   this.state.showCropper ? <Dialog
            open={this.state.showCropper}
            data-tets-id="dialog-close-popup"
            onClose={() => this.closePopup()}
            fullWidth={true}
            maxWidth="lg"
            classes={{ paper: "share-project-popup-wrapper" }}
        >
            <DialogTitle>
                <CustomBox
                    className="story-upload-btn closebtn"
                    data-test-id="close-btn-share-project"
                    onClick={() => this.closePopup()}
                >
                    <img
                        src={closeIcon}
                        alt="close-icon"
                        className="closebtn-elm"
                    />
                </CustomBox>
            </DialogTitle>
            <DialogContent 
                style={webStyle.dialogContainer}
            >
                <DialogContentText>
                    <CustomBox
                        className="share-project-popup"
                    >
                        <CustomBox
                            className="flex-container h-94 space-between-elm"
                        >
                            <Typography className="text-elm bold-elm left-elm-container">
                                {configJSON.uploadCoverImageText}
                            </Typography>
                            <CustomBox className="right-section-elm flex-container">
                                <CustomButton
                                    className="discardBtn"
                                    data-test-id="discard-btn"
                                    onClick={this.handleDiscardCroppedImage}
                                >
                                    {configJSON.cancelText}
                                </CustomButton>
                                <CustomButton
                                    className="saveProgressBtn button-elm default-btn"
                                    data-test-id="save-Progress-btn"
                                    onClick={this.handleCroppedImage}
                                >
                                    {configJSON.uploadCoverImage}
                                </CustomButton>
                            </CustomBox>
                        </CustomBox>
                        <CustomBox className="relative-position">
                            <CustomBox className="crop-container">
                                <CustomBox>
                                </CustomBox>
                                <Cropper
                                    image={URL.createObjectURL(this.state.coverImage[0])}
                                    crop={this.state.crop}
                                    zoom={this.state.zoom}
                                    aspect={1.45}
                                    onCropChange={this.handleCropChange}
                                    onCropComplete={this.onCropComplete}
                                    onZoomChange={this.handleZoomChange}
                                />
                            </CustomBox>
                            <CustomBox className="crop-border-container"></CustomBox>
                        </CustomBox>
                        <CustomBox className="controller-container flex-container h-94">
                            <CustomBox className="controls h-94">
                                <img
                                    src={imgIcon}
                                    alt="img Icon small"
                                    className="imgIconFirst"
                                />
                                <Slider data-test-id="zoom-input" min={1}
                  max={3}
                  step={0.1} 
                  value={this.state.zoom}
                   onChange={this.handleZoomChangeFromSlider} aria-labelledby="continuous-slider" />
                                <img
                                    src={imgIcon}
                                    alt="img Icon large"
                                    className="imgIconlast"
                                />
                            </CustomBox>
                        </CustomBox>
                    </CustomBox>
                </DialogContentText>
            </DialogContent>
        </Dialog> : null;
    }
    // Customizable Area End  

  render() {

    // Customizable Area Start
    // Customizable Area End     
    return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
            <CustomBox data-test-id='parent-container' className='parent-element' onClick={() => this.hidemenu()}>
                <CustomBox className='post-opportunity-headerData'>
                    <CustomBox className='fixed-top'><NavigationMenu {...this.props} activeTab='user' /></CustomBox>
                </CustomBox>
                {this.state.isLoading ? this.renderEmptyScreen():
                <CustomBox className='post-opportunity-container'>
                    <CustomBox className='header-container'>
                        <CustomBox className='left-elm'>
                            <Box style={webStyle.serviceContainer}>
                        <img style={webStyle.serviceImage} data-test-id="back-btn-id" src={Backbtn} alt="left icon" onClick={this.backAddService}/>
                            <Typography data-testid="heading" className='heading-text'>{this.renderHeading()}</Typography>
                            </Box>
                        </CustomBox>
                    </CustomBox>
                    
                    <CustomBox className='form-container' style={{ marginTop: '54px' }}>
                    
                        {this.renderCoverImage()}
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Service<span style={webStyle.requiredStyle}>*</span></Typography>
                            <CustomTextField
                                className='project-title main-text'
                                type='text'
                                placeholder='Type Here'
                                data-test-id='input-data-service-name'
                                value={this.state.serviceName}
                                inputProps={{
                                    maxLength: 50
                                }}
                                onChange={(event) => 
                                    this.handleInputChange('serviceName', event.target.value)
                                }
                            />
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Box style={webStyle.workStyle}>
                            <Box style={webStyle.timeLineStyle}>
                            <Box style={{width:"50%"}}>
                            <Typography className='label-text' style={{marginBottom:"24px"}}>Timeline<span style={webStyle.requiredStyle}>*</span></Typography>
                                            <Box style={{position:"relative",width:"100%"}}>
                                                {this.handleToggleButtons(
                                                    this.state.isMinTimeLineOpen,
                                                    "Select",
                                                    this.state.minTimeLine,
                                                    this.toggleTimeLineMenu
                                                )}
                                                {this.handleContainer(
                                                    this.state.isMinTimeLineOpen,
                                                    this.state.timeLineList,
                                                    this.state.minTimeLine,
                                                    this.handleTimeLine,
                                                    "-webkit-fill-available"
                                                )}
                                            </Box>
                                
                                </Box>
                                        <Box style={{ width: "50%",position:"relative" }}>

                                            {this.handleToggleButtons(
                                                this.state.isMaxTimeLineOpen,
                                                "Select",
                                                this.state.maxTimeLine,
                                                this.toggleMaxTimeLineMenu
                                            )}
                                            {this.handleContainer(
                                                this.state.isMaxTimeLineOpen,
                                                this.state.timeLineList,
                                                this.state.maxTimeLine,
                                                this.handleMaxTimeLine,
                                                "-webkit-fill-available"
                                            )}
                                        </Box>
                            </Box>

                                    <Box style={{ width: "15%",position:"relative" }}>

                                        {this.handleToggleButtons(
                                            this.state.isTimeLineTypeOpen,
                                            "Select",
                                            this.state.timeLineType,
                                            this.toggleTimeLineTypeMenu
                                        )}
                                        {this.handleContainer(
                                            this.state.isTimeLineTypeOpen,
                                            this.state.timeLineTypeList,
                                            this.state.timeLineType,
                                            this.handleTimeLineType,
                                             "-webkit-fill-available"
                                        )}
                                        
                                    </Box>
                            </Box>
                                {this.renderMinTimeLineEroor()}
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Box style={webStyle.workStyle}>
                            <Box style={webStyle.workContainer}>
                            <Box style={{width:this.state.category.name === "Location" ? "100%":"48.4%"}}>
                            <Typography className='label-text' style={{marginBottom:"24px"}}>Work Setting<span style={webStyle.requiredStyle}>*</span></Typography>
                                            <Box style={{position:"relative"}}>

                                                {this.handleToggleButtons(
                                                    this.state.isCategoryOpen,
                                                    "Anywhere / Remote",
                                                    this.state.category.name,
                                                    this.toggleCategoryMenu
                                                )}
                                                {
                                                    this.handleContainer(
                                                        this.state.isCategoryOpen,
                                                        this.state.categoryList,
                                                        this.state.category.name,
                                                        this.handleCategory,
                                                         "-webkit-fill-available"
                                                    )
                                                }
                                            </Box>
                                
                            </Box> 
                            </Box>
                            {this.state.category.name === "Location" && 
                            <Box style={webStyle.minimumStyle}>
                            <Typography className='label-text' style={{marginBottom:"24px"}}>City</Typography>
                            <LocationWrapper>
                                            <PlaceAutoCompleteWrapper>
                                                <PlacesAutocomplete
                                                    value={this.state.selectedLocation}
                                                    onChange={this.handleChangeLocationText}
                                                    onSelect={this.handleChangeLocation}
                                                    data-testId = "selectLocationTestId"
                                                    searchOptions={{
                                                        types: ['locality']
                                                    }}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                                        <Box>
                                                            <TextFieldData
                                                                {...getInputProps()}
                                                                placeholder='Select Location'
                                                                variant='outlined'
                                                                data-testId= "locationTestId"
                                                                fullWidth
                                                                inputProps={{
                                                                    className: "placeAutoComplete",
                                                                    onChange: this.handleInputChangeLocation
                                                                  }}
                                                            />
                                                            <Box className="autocomplete-dropdown-container suggestion_box">
                                                                {suggestions.map((suggestion, index) => {
                                                                    return (
                                                                        <Box className="suggestion_container"
                                                                            {...getSuggestionItemProps(suggestion, { key: { index } })}
    
                                                                        >
                                                                            <Typography key={index} style={{width:"50%"}} className="suggestion">{suggestion.description}</Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </Box>
                                                        </Box>
                                                    )}
                                                </PlacesAutocomplete>
                                            </PlaceAutoCompleteWrapper>
                                        </LocationWrapper>
                                
                            </Box>}
                            </Box>
                        </CustomBox>
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Rate (Hourly)<span style={webStyle.requiredStyle}>*</span></Typography>
                            <Box style={webStyle.rateContainer}>
                            <Box style={webStyle.minimumStyle}>
                            <Typography className='label-text' style={webStyle.rateStyle}>Minimum</Typography>
                            <CustomTextField
                                className='rate-title main-text'
                                type='text'
                                style={webStyle.rateBoxStyle}
                                placeholder='Minimum rate'
                                data-test-id='input-data-min-rate'
                                value={this.state.minValue}
                                inputProps={{
                                    maxLength: 50,
                                    inputMode: 'numeric', 
                                    pattern: '[0-9]*'    
                                }}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d*$/.test(value)) { 
                                        this.handleMinRate(value);
                                    }
                                }}
                            />
                            </Box>
                            <Box style={{width:"100%"}}>
                            <Typography className='label-text' style={webStyle.rateStyle}>Maximum</Typography>
                            <CustomTextField
                                className='rate-title main-text'
                                type='text'
                                style={{width:"100%"}}
                                placeholder='Maximum rate'
                                data-test-id='input-data-max-rate'
                                value={this.state.maxValue}
                                inputProps={{
                                    maxLength: 50,
                                    inputMode: 'numeric', 
                                    pattern: '[0-9]*'    
                                }}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (/^\d*$/.test(value)) { 
                                        this.handleMaxRate(value)
                                    }
                                }}
                            />
                            </Box>
                            </Box>
                                {this.renderMinRateError()}
                        </CustomBox>
                        
                        <Divider style={webStyle.dividerStyle} />
                        <CustomBox className='formGroup'>
                            <Typography className='label-text'>Work Description<span style={webStyle.requiredStyle}>*</span></Typography>
                            <CustomTextField
                                className='description-title main-text'
                                placeholder='Type here'
                                value={this.state.description}
                                data-test-id='input-data-desc'
                                inputProps={{
                                    maxLength: 500
                                }}
                                multiline={true}
                                minRows={5}
                                onChange={(event) => {this.handleDescriptionChange(event.target.value); this.handleCountCharacter(event.target.value)}}
                            />
                            <Typography className='limit-title'>
                                {this.state.descCountCharacter}/500
                            </Typography>
                        </CustomBox>
                        <Divider style={webStyle.dividerStyle} />
                            {this.renderDeliverable()}
                            {this.renderSkills()}
                            {this.renderLinks()}
                        
                            <CustomBox className='formGroup'>
                                <Typography className='label-text'>Add Projects from DK</Typography>
                                <Box className="inputLabelConatiner editProfileInputContainerFirst">
    
                                    {this.state.collectionList.length > 0 ? (
                                        
                                        <Grid container style={webStyle.collectionContainer}>
                                            <Grid item md={3} style={webStyle.collectionGrid}>
                                                <Typography style={webStyle.collectionTitle} className="select-collection-heading_Exp">Select Collection</Typography>
                                                {this.renderCollectionList()}
                                            </Grid>
                                            <Grid item md={9}>
                                                <Grid container>
                                                    <Box style={webStyle.handleProject}>
                                                        <Typography style={{ padding: "10px 0px" }} className="select-collection-heading_Exp">Select Projects</Typography>
                                                        <Typography
                                                            style={webStyle.selectedProjectStyle}
                                                            className="select-all-heading_Exp"
                                                            data-test-id="isProjectSelectedAll123"
                                                            onClick={() => this.handleSelectAll()}
                                                        >
                                                            {this.handleareAllProjectsSelected()}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Box style={webStyle.projectContainer}>
                                                    <Grid container spacing={2}>
                                                        {this.state.particularCollectionData ? (
                                                            this.state.particularCollectionData.map((data, index) => (
                                                                <Grid item md={4} key={index}>
                                                                    <Box
                                                                        style={webStyle.projectMainBox}
                                                                        data-test-id="toggleSelectProject"
                                                                        onClick={() => this.toggleSelectProject(data)}
                                                                    >
                                                                        <Box
                                                                            style={webStyle.projectSubBox}
                                                                        >
                                                                            {this.handleCheckIcon(data)}
                                                                            <img src={`${this.baseUrlString}${data.attributes.cover_image_url}`} style={webStyle.projectImage} />
                                                                            <Typography className='collection-heading-item-style_Exp' style={webStyle.projectName}>{data.attributes.project_name}</Typography>
                                                                            <Typography className='collection-subheading-item-style_Exp' style={webStyle.projectUserName}>{data.attributes.username}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Grid>
                                                            ))
                                                        ) :
                                                            <Typography style={webStyle.noProjects} data-test-id="isProjectSelected">
                                                                No projects available
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    { this.renderPaginationComponent()}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <p style={webStyle.noProjectsAvailable} data-test-id="isProjectSelected">
                                            No DK Project Available
                                        </p>
                                    )}
                                </Box>
                                
                            </CustomBox>
                    </CustomBox>
                        <CustomBox className='form-container'>
                            {this.renderPostButton()}
                        </CustomBox>
                    </CustomBox>
  }
            </CustomBox>
            {this.renderCropImage()}
        <ToastMSG data-testid="toastMessage" open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />
            </ThemeProvider>
            // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
    redColor: {
        color: 'red'
    },
    contentCon: {
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        margin: "1rem auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: "transparent",
        height: "calc(100% - 76px)",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    progressCon: {
        width: "100%",
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .circular": {
          color: "#fff"
        }
      },
    coverContainer:{
        backgroundColor:"#222222",height:"82px",minWidth:"87px", borderRadius:"16px"
    },
    dividerStyle: {
        backgroundColor: "rgba(84, 84, 84, 1)",
    },
    projectContainer:{
        height: "600px", overflowY: "auto" as "auto", overflowX: "hidden" as "hidden", 
        position: 'relative' as "relative", scrollbarWidth: 'none' as "none", padding: "0 16px"
    },
    projectMainBox:{
        display: "flex", justifyContent: "space-between", whiteSpace: "normal" as "normal", gap: "5px"
    },
    projectSubBox: {
        width: "271.19px",
        height: "243.15px",
        borderRadius: "9px",
        overflow: "hidden",
        position: "relative" as "relative"
    },
    projectImage:{
        width: "100%", height: "180px", borderRadius: "9px"
    },
    projectName:{
        fontSize: "14px", fontWeight: 700, marginTop: '7px'
    },
    projectUserName:{
        fontSize: "12px", fontWeight: 400
    },
    noProjects: {
        width: '100%',
        textAlign: 'center' as 'center',
        color:"#fff2e3",
        marginTop:"50px"
    },
    totalCount:{
        position: 'absolute' as 'absolute', bottom: 0, left: 0, right: 0, display: "flex", justifyContent: "center", alignItems: "center", paddingBlock: "15px",
    },
    noProjectsAvailable:{
        fontSize: '16px',color:"#666666",fontWeight:600,fontFamily:"Inter"
    },
    dialogContainer:{ 
        paddingTop: 0, 
        paddingBottom: 0,
        backgroundColor: "transparent",
        boxShadow: "none"
    },
    handleProject:{
        display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 16px", width: "100%",paddingBlock: "10px"
    },
    selectedProjectStyle:{
        borderBottom: "1px solid #7d7d7d", cursor: 'pointer'
    },
    collectionContainer:{
        border: "1px solid #7d7d7d", borderRadius: "9px", minHeight: "543px", marginTop: "20px"
    },
    collectionGrid:{
        borderRight: "1px solid #7d7d7d",
    },
    collectionTitle:{
        paddingBlock: "20px", paddingInline: '15px'
    },
    linksSubContainer:{
        alignItems: "center", paddingLeft: "1.5%", display: "flex", padding:"1%", backgroundColor: "#222222", color: "#498EFF", marginTop: "20px", borderRadius: "9px"
    },
    linkStyle:{
        fontFamily: "Inter", 
        fontSize: "20px", 
        fontWeight: 600, 
        lineHeight: "24px", 
        letterSpacing: "0.37px", 
        textDecorationStyle: "solid" as "solid", textDecorationSkipInk: "none" as "none", 
        textUnderlinePosition: "from-font", textDecorationLine: "underline",wordBreak:"break-all" as "break-all"
    },
    linkIcon:{
        objectFit: "contain" as "contain", height: "24px", width: "24px", cursor: "pointer", paddingRight: "1.5%" 
    },
    linkContainer:{
        display: "flex", alignItems: "center", gap: "2%"
    },
    linkMainContainer:{
        display: 'flex', alignItems: "center", backgroundColor: "#222", border: "1px solid #A6A6A6", width: "100%", paddingLeft: "1.5%", borderRadius: "9px"
    },
    removeIconStyle: {
        objectFit: "contain" as "contain",
        height: "41px",
        width: "41px",
        cursor: "pointer",
    },
    serviceContainer:{
        display:"flex",alignItems:"center",gap:"14px"
    },
    serviceImage:{
        width:"7px",height:"16px",cursor:"pointer"
    },
    unCheckCover: {
        width: "17.5px",
        height: "17.5px",
        borderRadius: "50%",
        marginLeft: "5px",
        border: "2px solid rgba(255, 242, 227, 1)",
        right: "3%",
        cursor: 'pointer',
        top: "3%",
        background: 'rgba(70, 70, 70, 1)'
    },
    viewCheckCover:{
        width: "17.5px",
        height: "17.5px",
        borderRadius: "50%",
        border: "2px solid rgba(255, 242, 227, 1)",
        right: "3%",
        cursor: 'pointer',
        top: "3%",
        background:'rgba(17, 17, 17, 1)',
        marginLeft:"5px"
      },
    viewCheck: {
        width: "25.5px",
        height: "25.5px",
        borderRadius: "50%",
        position: "absolute" as "absolute",
        border: "2px solid rgba(255, 242, 227, 1)",
        right: "3%",
        cursor: 'pointer',
        top: "3%",
        background: 'rgba(17, 17, 17, 1)'
    },
    viewUnCheck: {
        width: "25.5px",
        height: "25.5px",
        borderRadius: "50%",
        position: "absolute" as "absolute",
        border: "2px solid rgba(255, 242, 227, 1)",
        right: "3%",
        cursor: 'pointer',
        top: "3%",
        background: 'rgba(70, 70, 70, 1)'
    },
    requiredStyle:{color:"red"},
    deliverableContainer:{ display: "flex", alignItems: "center" },
    deliverableCount:{color: "#fff2e3"},
    rateStyle:{fontSize:"16px",marginBottom:"16px"},
    rateContainer:{
        display:"flex",gap:"48px"
    },
    rateBoxStyle:{
        display:"flex",gap:"48px"
    },
    minimumStyle:{
        width:"100%"
    },
    workStyle:{
        display:"flex",gap:"48px",alignItems:"flex-end"
    },
    workContainer:{
        display:"flex",gap:"24px",width:"100%",alignItems:"flex-end"
    },
    timeLineStyle:{
        display:"flex",gap:"24px",width:"48%",alignItems:"flex-end"
    },
    uploadCoverContainer:{
        display: "flex", alignItems: "center"
    },
    imageCount:{
        color:"#666666",fontFamily:"Inter",fontWeight:500,fontSize:"24px"
    },
    countContainer:{
        display:"flex", alignItems:"center",marginTop:"16px",gap:"7px"
    },
    coverMainContainer:{
        color: "#878787", fontFamily: "Inter", fontSize:"36px", fontWeight:500,cursor:"pointer"
    },
    addImageContainer:{
        display:"flex",alignItems:"center",gap:"20px",overflowX:"auto" as "auto",scrollbarWidth:"none" as "none"
    }

}

const LocationWrapper = styled(Box)({
    display: "flex"
})
// Customizable Area End
