// Customizable Area Start
import React from "react";

import JobListingController, {
  configJSON,
  SortingBySideBar,
  SortingByWorkType,
  TabPanelProps,
  DropDownData
} from "./JobListingController";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Checkbox, CircularProgress, createTheme, Divider, FormControlLabel, Grid, IconButton, withStyles, InputAdornment, MenuItem, MenuList, Paper, Slider, styled, Tab, Tabs, TextField, ThemeProvider, Tooltip, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WorkOppertunityModal from "../../landingpage/src/workopportunity/WorkOppertunityModal.web"
import OpportunityCard, { workOpportunity } from "../../../components/src/OpportunityCard.web";
import SearchIcon from '@material-ui/icons/Search';
import { Pagination } from "@material-ui/lab";
import { dancingBar, findWorkPoster, Layer_1, searchImage } from "./assets";
import SelectLocation from "../../../components/src/SelectLocation.web"
import CreativeFields from "../../../components/src/CreativeFields.web"
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import SortBy from "../../../components/src/SortBy.web";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Proposals } from "./Proposals.web";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { DraftOpportunity } from "./DraftOpportunity.web";
import UnderReviewProposal from "../../cfproposalmanagement/src/UnderReviewProposal.web";
import { NoResultFound } from "../../../components/src/ProposalComponent";
import OfferReceived from "../../cfproposalmanagement/src/OfferReceived.web";
const customStyle = require('../../dashboard/src/CustomStyles.js');
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { OfferContract } from "./OfferContract.web";
import { ActiveContract } from "./ActiveContract.web";
import ToastMSG from '../../../components/src/ToastMSG.web';


const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1611,
      xl: 1980,
    },
  },
});

class JobListing extends JobListingController {

  MainWrapper = styled(Box)(({ theme }) => ({
    "& .find_work_side_bar_container_menu": {
      width: "100%",
      backgroundColor: "#171717",
      margin: 0,
    },
    "& .find_work_main_container": {
      display: "flex",
      width: '100%',
      backgroundColor: "#111111"
    },
    "& .find_work_sidebar_accordian": {
      display: "flex",
      flexDirection: 'column',
      width: "100%"
    },
    "& .edit_profile_disco_bar": {
      marginLeft: '-17.5rem',
      width: '100%',
      marginBottom: 20
    },
    "& .find_work_side_bar_container": {
      border: 1,
      width: "25%",
      padding: 20,
      backgroundColor: "#111111"
    },
    "& .find_work_right_main_container": {
      width: "75%",
      border: 1,
      padding: 20
    },
    "& .find_work_grid_section": {
      width: "98%",
    },
    "& .find_work_search_field": { borderRadius: 20, outline: "none", marginBottom: 10, width: "95%", backgroundColor: '#171717', "::placeholder": { color: '#66625C' }, color: '#66625C' },
    '&:focus, &:hover': {
      outline: 'none',
    },
    "& .gray_color": {
      color: '#FFF2E3',
      fontFamily: "Inter , sans-serif",
    },
    "& .right_side_grid_box": {
      width: "95%",
      padding: 2
    }, "& .main_container_search_button": { color: "#FFF2E3" },
    "& .tabs_css": {
      color: "#FFF2E3",
      fontWeight: 700,
      fontFamily: "Inter,sans-serif",
      textTransform: 'none',
      fontSize: 16
    },
    "& .apply_button": {
      backgroundColor: "#FFF2E3", fontFamily: "Inter , sans-serif",
    },
    '& .MuiTabs-indicator': {
      backgroundColor: "#FFF2E3",
      height: '3px',
      widht: "40% !important",
      marginLleft: '10%'
    },
    '& .pagination .MuiPaginationItem-root': {
      color: '#8C8C8C',
    },
    '& .pagination .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: '#F5F5DC',
      color: '#121212',
      borderRadius: '50%',
    },
    '& .find_work_search_field::placeholder': {
      color: '#66625C',
    },
    '& .find_work_search_field:focus': {
      outline: 'none',
    },
    "& .find_work_and_praposals_button": { display: 'flex', justifyContent: 'space-between', alignItems: "center", padding: 6, backgroundColor: '#0D0D0D', borderRadius: 50, marginBottom: 20 }
    , "& .praposal_text": { color: '#8C8C8C', fontFamily: "Inter,sans-serif", fontSize: '20px', padding: '5% 10%', cursor: 'pointer' },
    "& .sidebar_menu_box": { backgroundColor: "#111111", padding: 2 }, "& .sidebar_menu_list": { padding: 0 }, "& .sidebar_menu_item": { display: "flex", flexDirection: 'column', padding: 0 }, "& .accordian_details": { display: "flex", justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '12px' }, "& .divider": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 }, "& .divider_for_tabs": { backgroundColor: '#3E3E3E', width: '100%', borderBottomWidth: 2 },
    '& .MuiSlider-track': {
      border: 'none',
      height: 8,
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)'
    },
    "& .MuiSlider-root": {
      width: "97%",
      // marginLeft: '-2.5rem',
    },
    '& .MuiSlider-thumb': {
      height: 20,
      width: 20,
      backgroundColor: '#fff',
      border: '2px solid #171717',
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-rail': {
      height: 8,
      opacity: 1,
      backgroundColor: '#bfbfbf',
    },
    '& .MuiSlider-valueLabel': {
      display: 'none',
    },
    '& .budget_min_max_box': {
      backgroundColor: '#222222', padding: '10 15', borderRadius: '5%'
    },
    '& .budget_count_box': {
      display: 'flex', gap:'10px', alignItems: 'center',flexWrap:'wrap',
      justifyContent:'space-between',
      marginTop:'35px'
      
    }, "& .budget_min_max_color": {
      color: '#797979', fontFamily: "Inter , sans-serif",
    }, '& .budget_count_color': {
      color: '#FFF2E3', fontFamily: "Inter , sans-serif",
    }, '& .to_padding': {
      padding: 10
    }, "& .MuiTab-wrapper": {
      display: 'flex',
      justifyContent: "start",
    }, "& ..MuiTab-root": {
      minWidth: 0,
    }, "& .font_weight_600": {
      fontWeight: 600
    }, "& .disabled-btn": {
      backgroundColor: 'rgb(157, 155, 153)',
      color: "black"
    }, "& .slider_container": {
      display: 'flex', flexDirection: 'column', flexGrow: 1, width: "100%", marginTop: -41
    },
    "& .opportunity_card_main_container": {
      borderRadius: 13,
      padding: "22px 20px 22px 20px",
      gap: 24,
      border: "1px solid #3E3E3E",
      cursor: "pointer",
    },
    "& .scroll::-webkit-scrollbar":{
        display:"none",
    },
    "& .opportunity_card_main_containerChangeColor": {
      cursor: "pointer",
      position: 'relative',
      borderRadius: '13px',
      background: '#313131',
      color: 'white',
      textTransform: 'none',
      fontWeight: 'bold',
      fontFamily: "Inter,sans-serif",
      fontSize: '20px', 
      padding: '22px 20px 22px 20px',
      zIndex: 1,
      '&::before': {
        content: '""',
        position: 'absolute',
        inset: 0,
        borderRadius: '13px',
        padding: '2px',
        background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        WebkitMaskComposite: 'xor',
        maskComposite: 'exclude', 
      }

    },
    "& .opportunity_card_person_city": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 400,
    },
    "& .opportunity_card_price_duration": {
      padding: "6px 16px 6px 16px",
      fontSize: "14px",
      borderRadius: "24px",
      backgroundColor: "#1D1D1D",
      fontFamily: "Inter,sans-serif",
    },
    "& .opportunity_card_price_color": {
      color: "#1CBAE0"
    }, "& .opportunity_card_duration_color": {
      color: "#FFB9C7",
      marginLeft: 15
    },
    "& .opportunity_card__description": {
      fontSize: "12px",
      height: 50,
      fontFamily: "Inter,sans-serif",
      fontWeight: '400',
      color: "#FFF2E3",
      flexGrow: 1,
    },
    "& .opportunity_card_price_duration_box": {
      display: 'flex'
    }, "& .only_2_line": {
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      lineHeight: 1.5,
      maxHeight: "3em",
    },
    "& .opportunity_card_title_box": {
      borderColor: '#3E3E3E',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: "Inter , sans-serif",
      alignItems: "stretch",
      gap: 14,
      justifyContent: "space-between",
    },
    "& .opportunity_card_divider_color": {
      borderColor: '#3E3E3E'
    },
    "& .opportunity_card_hour": {
      color: "#66625C",
      fontSize: "11px",
      fontWeight: 400,
      fontFamily: "Inter,sans-serif",
    },
    '& .MoreVectorIcon': {
      textAlign: 'center',
      paddingBottom: '20px'
    },
    '& .MoreVectorIcon svg': {
      width: "50px",
      height: "50px",
      background: "#171717",
      borderRadius: "50px",
      color: '#fff'
    },
    "& .opportunity_card_title": {
      fontSize: "20px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600,
      height: 35,
      WebkitLineClamp: 1
    },
    "& .opportunity_card_timeline_title": {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      "&.gray12": {
        fontFamily: "Inter,sans-serif",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '12.5px',
        color: "#8C8C8C"
      },
      "&.green12": {
        fontFamily: "Inter,sans-serif",
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '12.5px',
        color: "#61CA78"
      },
      "&.white14": {
        fontFamily: "Inter,sans-serif",
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '14px',
        color: "#FFF2E3"
      },
    },
    "& .opportunity_card_person_name": {
      fontSize: "14px",
      fontFamily: "Inter,sans-serif",
      color: "#FFF2E3",
      fontWeight: 600
    }, "& .opportunity_card_person_detain_box": {
      display: "flex",
      justifyContent: 'space-between',
      alignItems: "baseline",
      marginTop: 10,
    },
  }));

  CustomTextField = styled(TextField)({
    backgroundColor: '#1c1c1c',
    margin: "0px 0px 20px 0",
    fontSize: 14,
    fontFamily: "Inter , sans-serif",
    borderRadius: 20,
    '& .MuiOutlinedInput-root': {
      overflow: "hidden",
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
      fontSize: 14,
      '&:placeholder': {
        textDecoration: 'italic',
      },
    },
    '& .MuiInputAdornment-root': {
      color: '#66625C',
    }
  });

  SearchButton = styled(IconButton)({
    color: '#fff',
    textTransform: 'none',
    fontSize: 14,
    textDecoration: 'underline',
    fontFamily: "Inter , sans-serif",
    borderLeft: '1px solid #434343',
    borderRadius: '0px',
    '&:disabled': {
      color: '#888',
      textDecoration: 'none', // Remove underline if needed
      opacity: 0.5, // Reduce opacity to make it look disabled
    },
  });


DropDownBox = styled(Box)({
    position:"relative",
    display: "flex",
    height: "53px",
    gap: "0px",
    borderRadius: "6px",
    border: "1px solid #595959",
    justifyContent: "space-between",
    alignItems: "center",
    opacity: "0px",
    cursor:"pointer"

});

DropDownBoxTypography = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29.05px",
    textAlign: "left",
    color: "#FFF2E3",
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    gap: "10px",
"@media (max-width:960px)": {
  fontSize: "15px !important",
  fontWeight: "300px !important",
  lineHeight: "29.05px !important",
  whiteSpace:"nowrap",
},
"@media (min-width:720px)": {

},

});

openDropDownBox = styled(Typography)({
    position:"absolute",
    display: "flex",
    flexDirection: "column",
    width: "335px",
    height: "fit-content",
    opacity: "0px",
    backgroundColor: "#111111",
    border: "1px solid #595959",
    gap: "10px",
    cursor: "pointer",
    top:"3.5rem",
    borderRadius: "10px",
    zIndex:9,
"@media (max-width:960px)": {
  width: "fit-content",
},
"@media (min-width:720px)": {

},
});

DropDownBoxSecondTypography = styled(Typography)({
  color: "white",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  letterSpacing: "0.3799999952316284px",
  textAlign: "left",
"@media (max-width:960px)": {
fontSize: "15px !important",
fontWeight: "300px !important",
lineHeight: "29.05px !important",
whiteSpace:"nowrap",

},
"@media (min-width:720px)": {

},

});

  SearchIconStyled = styled(SearchIcon)({
    color: '#66625C',
    fontSize: 18
  });

  CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p:((this.state.currentNav == "praposals" )&&(this.state.proposalTab==0 || this.state.proposalTab==3))?0: 3 }}>{children}</Box>}
      </Box>
    );
  }


  a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      label: index === 0 ? "Find Work" : "Saved Work",
      className: "tabs_css",
    };
  }

  StyledTabs = styled(Tabs)({
    '& .MuiTabs-wrapper': {
      justifyContent: 'flex-start',
      flexDirection: 'row'
    },
  });

  StyledButton = styled(Button)(({ theme }) => ({
    position: 'relative',
    borderRadius: '50px',
    background: '#313131',
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
    fontFamily: "Inter,sans-serif",
    overflow: 'hidden',
    fontSize: '20px', padding: '5% 10%',
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '50px',
      padding: '2px',
      background: 'linear-gradient(224.84deg, #FFC29F 0%, #FFB9C7 16.15%, #CF8CCC 33.85%, #AB9EF5 50.52%, #6C98EE 67.71%, #1CBAE0 83.85%, #A8E5EC 100%)',
      WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude',
    },
    '&:hover': {
      background: '#313131',
    },
    [theme.breakpoints.down(1340)]: {
      fontSize: '17px',
      padding: '5% 10%',
    },

  }));
  renderOpportunitiesData = () => {
    const { loading, workOpportunityData } = this.state;

    if (loading) {
      return (
        <Box style={{ width: '100%', marginTop: '10%' }}>
          <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress className="gray_color" size={25} />
          </Box>
          <Typography className="gray_color" style={{ marginTop: '20px', textAlign: 'center' }}>Searching...</Typography>
        </Box>
      );
    } else if (workOpportunityData.length > 0) {
      return (
        <Grid container spacing={3}>
          {workOpportunityData.map((element: workOpportunity, index: number) => (
            <Grid item xs={6} lg={4} key={index}>
              <OpportunityCard handlenavigate={this.handleNavigateFind} opportunity={element} handleOpenOpportunity={this.handleOpenOpportunity} />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%", marginTop: '15%', gap: 2 }}>
          <img src={searchImage} alt="search icon" height={84.39} width={171} />
          <Typography style={{ fontStyle: "Inter", fontSize: 20, fontWeight: 600, color: '#FFF2E3' }}>No work results found</Typography>
          <Typography style={{ fontStyle: "Inter", fontWeight: 400, color: '#FFF2E3', width: 550, textAlign: 'center', marginTop: "5%" }}>Sorry! We could not find any relevant job opportunities with your selected filters. Please try again by removing or applying different filters</Typography>
        </Box>
      );
    }
  };

  renderWorkDurationData = () => {
    return configJSON.workDurationData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox onChange={() => this.handleWorkDurtationClick(element.value, element.id)} checked={this.state.workDurtationSelected.includes(element.value)} className="gray_color" id={`work_duration_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderExperienceLevelData = () => {
    return configJSON.experienceLevelData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.experienceSelected.includes(element.value)} onChange={() => this.handleExperienceClick(element.value)} className="gray_color" id={`experience_${element.id}`}
          color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }

  renderWorkTypeData = () => {
    return configJSON.workTypeData.data.map((element: SortingByWorkType) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.workTypeSelected.includes(element.value)} className="gray_color" id={`work_type_${element.id}`}
          onChange={() => this.handleWorkTypeClick(element.value)} color="default" />}
        label={<Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box className="gray_color">{element.name}</Box>
          <Box>
            <Tooltip title={element.tooltip} placement="right-start">
              <IconButton className="gray_color" style={{ color: '#5C5C5C', border: '1px solid #5C5C5C', borderRadius: '30', fontSize: 10, padding: "2px 7px", marginLeft: 4 }}>
                i
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        }
      />
    })
  }

  renderHoursPerWeekData = () => {
    return configJSON.hoursPerWeekData.data.map((element: SortingBySideBar) => {
      return <FormControlLabel key={element.id}
        control={<Checkbox checked={this.state.hoursPerWeekSelected.includes(element.value)} className="gray_color" id={`hour_per_week_${element.id}`}
          onChange={() => this.handleHoursPerWeekClick(element.value)} color="default" />}
        label={<Box className="gray_color">{element.name}</Box>}
      />
    })
  }
  renderButtons = () => {
    const { currentNav } = this.state;
    return currentNav === 'find_work' ? (
      <Box className="find_work_and_praposals_button">
        <this.StyledButton variant="contained">
          Find Work
        </this.StyledButton>
        <Typography id="praposals_button" onClick={this.handleChangeCurrentNavData} className="praposal_text">
          Proposals
        </Typography>
      </Box>
    ) : (
      <Box className="find_work_and_praposals_button">
        <Typography id="find_work_button" onClick={this.handleChangeCurrentNavData} className="praposal_text">
          Find Work
        </Typography>
        <this.StyledButton variant="contained">
          Proposals
        </this.StyledButton>
      </Box>
    );
  };

  renderPaginationArror = () => {
    return (
      ((this.state.page * 4) < this.state.totalWorkOpportunity) &&
        <Box>
          <div className="MoreVectorIcon">
            <ExpandMoreRoundedIcon data-testId="paginationTestid" onClick={this.handleScrollApi} />
          </div>
        </Box>
    )
  }

  opportunityStatus = (status: string, action: string) => {
    return (
      (this.state.selectedOpportunity == 'offered_contract' || this.state.selectedOpportunity == 'activated') &&
      <>
        <Box className='opportunity_card_timeline_title'>
          {status.toLowerCase().includes('ongoing') ?
            <Typography className='opportunity_card_timeline_title green12'>{status}</Typography>
            :
            <Typography className='opportunity_card_timeline_title gray12'>{status}</Typography>
          }
          {this.state.selectedOpportunity == 'offered_contract' &&
            <Typography className='opportunity_card_timeline_title white14'>{action}</Typography>
          }
        </Box>
        <Divider className="divider" style={{ marginTop: '15px', marginBottom: '25px' }} />
      </>
    )
  }

  rederLeftOpportunity = () => {
    return (
      <Box data-test-id="oportunityCard"  className="scrollOportunityCard" style={{width:"100%",display:"flex", flexDirection:"column",gap:"20px",
        overflowY: "scroll", scrollbarWidth: 'none', maxHeight: '895px',cursor:"pointer"}} >
         {
           this.state.myWorkOpportinuity.map((data:any)=>{
               return(
                 <Box className={this.state.activeId === data.id ? 'opportunity_card_main_containerChangeColor': "opportunity_card_main_container"} 
                   data-test-id="openOpportunity" onClick={() => this.changeColor(data)}>
                   {this.opportunityStatus(data.attributes.contract_status, data.attributes.action_time)}
                 <Box className='opportunity_card_title_box'>
                   <Typography className='opportunity_card_title only_2_line'>{data.attributes.title}</Typography>
                   <Box className='opportunity_card_price_duration_box'>
                   {data.attributes.rate_amount && <Typography className='opportunity_card_price_duration opportunity_card_price_color'>Rs. {data.attributes.rate_amount.toLocaleString('en-IN')} {data.attributes.rate_type !== "overall" ? "pr/hr" : "overall"}</Typography>}
                   {data.attributes.project_timeline && <Typography className='opportunity_card_price_duration opportunity_card_duration_color'>{data.attributes.project_timeline} {this.capitalizeFirstLetter(data.attributes.project_timeline_type)}</Typography>}
                 </Box>
                   <Typography className='opportunity_card__description only_2_line'>{data.attributes.description}</Typography>
                 </Box>
                 <Box>
                   <Box className='opportunity_card_person_detain_box'>
                     <Box >
                       <Typography className='opportunity_card_hour'>{data.attributes.posted_time_ago}</Typography>
                     </Box>
                   </Box>
                 </Box>
                 
               </Box>
               )
           })
         }
           {this.renderPaginationArror()}
       </Box>
    )
  }

  renderList = () => {
    return (
      this.state.openDropDown && (
        <this.openDropDownBox >
          {this.state.dropDown.map((element: DropDownData, index: number) => {
            return (
              <Box data-test-id={`selectedDropDown-${index}`} onClick={() => this.dropDownViewData(element)}
                style={{
                  
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "1rem",
                  cursor: "pointer"
                }}>
                <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: element.color }}></div>
                <this.DropDownBoxSecondTypography data-test-id={`Typography-${index}`} onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }} 
                >{element.name}</this.DropDownBoxSecondTypography>
              </Box>
            )
          })
          }
        </this.openDropDownBox>
      )
    )
  }

  renderSection = () => {
    return <>
      <this.CustomTextField
        variant="outlined"
        style={{marginBottom:'32px'}}
        size="small"
        id="find_work_search"
        data-test-id="find-text-elm"
        fullWidth
        onChange={this.handleFindWorkSearch}
        value={this.state.findWorkSearch}
        onKeyDown={(event) => this.handleKeyPress(event)}
        placeholder="Type here"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <this.SearchIconStyled />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {this.state.isSearched && <IconButton onClick={this.clearText} id="clear-text" style={{ color: "white", marginRight: 20, fontFamily: "Inter , sans-serif", }} size="small">
                x
              </IconButton>}
              <this.SearchButton onClick={this.handleApplyFilterButton} disabled={this.handleDisableSearch()}>
                Search
              </this.SearchButton>
            </InputAdornment>
          ),
        }}
      />

      <ThemeProvider theme={theme}>
        <Grid className="right_side_grid_box" container spacing={2}>
          {
            this.renderOpportunitiesData()
          }
        </Grid>
      </ThemeProvider>
    </>
  }

  nodataText = () => {
    return (
      < Box style={{ display: 'flex', height: "80%", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: "100%", marginTop: '5%', gap: 2 }}>
        <img src={searchImage} alt="search icon" height={84.39} width={171} />
        <Typography style={{ fontStyle: "Inter", fontSize: 20, fontWeight: 600, color: '#FFF2E3' }}>No work results found</Typography>
      </Box>
    )
  }

  nodataOpen = () => {
    return (
      <Box style={webStyle.mainOpenDiv}>
        <Box style={webStyle.titleDiv}>
          <Typography style={webStyle.maintitle}>
            <strong>No open work posts</strong></Typography>

          <Typography style={webStyle.subtitle}>
            Post a new work opportunity now to get going.
          </Typography>
        </Box>
        <Box style={webStyle.buttonDiv}>
          <CustomButton data-test-id='create-work' onClick={this.handleNavigationPostOpporunity} style={webStyle.buttonStyle} className={`button-elm secondary-btn medium-text`}>+ Work Opportunity</CustomButton>
        </Box>
      </Box>
    )
  }

  nodataDraft = () => {
    return (
      <Box style={webStyle.mainOpenDiv}>
        <Box style={webStyle.titleDiv}>
          <Typography style={webStyle.maintitle}>
            <strong>No new drafts</strong></Typography>

          <Typography style={webStyle.subtitle}>
            You have no saved drafts. Post a new work opportunity now too start a new work project
          </Typography>
        </Box>
        <Box style={webStyle.buttonDiv}>
          <CustomButton data-test-id='create-work' onClick={this.handleNavigationPostOpporunity} style={webStyle.buttonStyle} className={`button-elm secondary-btn medium-text`}>+ Work Opportunity</CustomButton>
        </Box>
      </Box>
    )
  }

  nodataOffered = () => {
    return (
      <Box style={webStyle.mainOpenDiv}>
        <Box style={webStyle.titleDiv}>
          <Typography style={webStyle.maintitle}>
            <strong>No offered contracts</strong></Typography>

          <Typography style={webStyle.subtitle}>
            Try going to open opportunities and send a contract to the best suited designer
          </Typography>
        </Box>
        <Box style={webStyle.buttonDiv}>
          <CustomButton data-test-id='create-work-view' style={webStyle.activeColor} onClick={this.viewOffered}>View open opportunities</CustomButton>
        </Box>
      </Box>
    )
  }

  openOpportunityContentManagement = () => {
    let content;
    if (this.state.emptyProposoal) {
      content = this.nodataOpen();
    } else {
      content = <Proposals cardId={this.state.activeId} dropDownId={this.state.SelectedDropDownID} navigation={this.props.navigation} />;
    }
    return content;
  }

  draftContentManagement = () => {
    let content;
    if (this.state.activeId != '') {
      content = <DraftOpportunity cardId={this.state.activeId} navigation={this.props.navigation} id={""} />
    } else {
      content = this.nodataDraft()
    }
    return content;
  }

  deleteContentManagement = () => {
    let content;
    content = <Proposals type="deleted" cardId={this.state.activeId} dropDownId={this.state.SelectedDropDownID} navigation={this.props.navigation} />;
    return content;
  }

  offerContentManagement = () => {
    let content;
    if (this.state.emptyProposoal) {
      content = this.nodataOffered();
    } else {
      if (this.state.activeId != '') {
        content = <OfferContract cardId={this.state.activeId} getDeleteOpportunity={this.allOpportiunity} dropDownId={this.state.SelectedDropDownID} navigation={this.props.navigation} deletePost={this.myWorkOpportiunity} activeOpportunity={this.dropDownViewData} id={""} />
      }
    }
    return content;
  }

  activateContentManagement = () => {
    let content;
    let id;
    if (this.state.emptyProposoal) {
      content = this.nodataOffered();
    } else {
      if (this.state.activeId != '') {
        id = this.findProposalIdById(this.state.activeId)
        content = <ActiveContract cardId={this.state.activeId} proposalId={id} dropDownId={this.state.SelectedDropDownID} navigation={this.props.navigation} id={""} />
      }
    }
    return content;
  }

  mainContentManage = () => {
    let content;
    if (this.state.selectedOpportunity === "open_opportunity") {
      content = this.openOpportunityContentManagement();
    }
    else if (this.state.selectedOpportunity === "draft") {
      content = this.draftContentManagement();
    }
    else if (this.state.selectedOpportunity === "deleted_post") {
      content = this.deleteContentManagement();
    }
    else if (this.state.selectedOpportunity === "offered_contract") {
      content = this.offerContentManagement();
    }
    else if (this.state.selectedOpportunity === "activated") {
      content = this.activateContentManagement();
    }
    return content;
  }


  capitalizeFirstLetter(value:string) {
    if (value) {
    return value.replace(/\b[a-z]/g, (match) => match.toUpperCase());
    } else {
      return '';
    }
  }

  changeColor =(data:any)=>{
    this.setState({activeId: data.id })
    localStorage.setItem("CardId",data.id)
    this.resetProposalLocalStorage()
  }

  render() {
    return (
      
      <this.MainWrapper>
        <ToastMSG open={this.state.open} close={() => this.handleCloseToast()} message={this.state.message} action={this.state.action} />
        {this.state.userType === "client" ?
          <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
            <NavigationMenu {...this.props} activeTab="my work" />
            <Box style={webStyle.container}>
            <img src={Layer_1} style={webStyle.titleImg} alt="My Work Poster" />
            <div style={webStyle.mainClientWrap}>
            {this.state.emptyScreen ?
              <Box style={webStyle.mainBox}>
                <Box style={webStyle.mainMargin}>
                  <Box
                    style={webStyle.titleBox}>
                    <Typography style={{...webStyle.myworkFont, width: 'max-content'}}>My Work posts</Typography>
                    <Typography onClick={this.handleNavigationExploreMore} style={{...webStyle.exploreText, width: 'max-content'}}>Explore more</Typography>
                  </Box>
                  <Box style={webStyle.grayBox}>
                    <Box style={webStyle.titleDiv}>
                      <Typography style={webStyle.maintitle}>
                        <strong>No work posts</strong></Typography>

                      <Typography style={webStyle.subtitle}>
                        You haven’t posted any work opportunity yet. Post a new opportunity now to get going.
                      </Typography>
                    </Box>
                    <Box style={webStyle.buttonDiv}>
                      <CustomButton data-test-id='create-work' onClick={this.handleNavigationPostOpporunity} style={webStyle.buttonStyle} className={`button-elm secondary-btn medium-text`}>+ Work Opportunity</CustomButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              :
            <Box style={{ width: "100%", backgroundColor: "#111111", display: "flex", gap:"3rem", padding: '1rem',  boxSizing: 'border-box' }}>
              <Box style={{
                maxWidth: "400px",
                opacity: "0px",
                display: "flex",
                backgroundColor: "#111111",
                width: '30%',
                paddingTop:"40px"
              }}>
                <Box style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "40px",
                  opacity: "0px",
                  marginLeft:"40px"

                }}>
                  <Box style={{
                    display: "flex",
                    justifyContent: "space-between",
                    opacity: "0px",
                  }}>
                    <Typography style={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 600,
                      lineHeight: "24.2px",
                      textAlign: "left",
                      color: "#FFF2E3",
                    }}>My Work posts</Typography>

                    <Typography
                      onClick={this.handleNavigationExploreMore}
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 600,
                      lineHeight: "19.36px",
                      textAlign: "left",
                      color: "#686868",
                      cursor:"pointer",
                      textDecoration: "underline",
                    }}>Explore more</Typography>

                  </Box>
                  <div style={{position:"relative"}}>

                  <Box
                  style={{
                    display: "flex",
                    gap:"32px",
                    flexDirection: "column",
                    opacity: "0px",
                  }} >

                    <this.DropDownBox
                    data-test-id="Typography-1"
                    onClick={() => { this.setState({ openDropDown: !this.state.openDropDown }) }}>
                      <this.DropDownBoxTypography >
                        <div style={{ height: "10px", width: "10px", borderRadius: "10px", backgroundColor: this.state.SelectedDropDownColor }}></div>
                        <Typography style={{
                          color: "white",
                          fontFamily: "Inter",
                          fontSize: "inherit",
                          fontWeight: 600,
                          lineHeight: "24px",
                          letterSpacing: "0.3799999952316284px",
                          textAlign: "left"
                        }}>{this.state.SelectedDropDownName}</Typography>
                      </this.DropDownBoxTypography>

                      <ArrowDropDownIcon style={{ width: "50px", height: "40px", color: "#D9D9D9" }} />
                    </this.DropDownBox>
                    {this.rederLeftOpportunity()}
                    {this.renderList()}

                  </Box>
                  </div>
               
                </Box>
                
                <Box>
                </Box>
              </Box>
              <Box style={{height:"1000px", borderRight: "0.33px solid #6D6D6D"}}></Box>
              <Box style={{
                width:"70%",
                paddingTop:"40px",
                paddingRight: '15px'
              }}>
                {this.mainContentManage()}
              </Box>
          </Box>
            }
            </div>
            </Box>
            </Box>
          :
          <>
          <Box
          style={webStyle.newResponseCon}
          data-testid="outside">
            <NavigationMenu {...this.props} activeTab="find work" />
            <Box style={webStyle.container}>
            <img src={findWorkPoster} style={webStyle.titleImg} alt="Find Work Poster" />
            <div style={webStyle.mainClientWrapDesigner}>
            { this.state.currentNav=="find_work"?
            <>
               <Box className="find_work_main_container">
               <Box className="find_work_side_bar_container">
 
                 {this.renderButtons()}
 
                 <Box className="sidebar_menu_box">
                   <Paper >
                     <MenuList className="sidebar_menu_list">
                       <MenuItem className="sidebar_menu_item">
                         <Accordion className="find_work_side_bar_container_menu">
                           <AccordionDetails>
                             <Box className="accordian_details" >
                               <Box>
                                 <SortBy selectedSort={this.state.selectedSort} handleApply={this.handleSortSelect} />
                               </Box>
                               <Box >
                                 <Button onClick={this.handleResetClick} disabled={this.handleDisableResetButton()} className={`apply_button ${this.handleDisableResetButton() ? "disabled-btn" : ""}`} size="small" style={{ textTransform: 'none', marginRight: 10, fontFamily: "Inter , sans-serif", }}>
                                   Reset
                                 </Button>
                                 <Button onClick={this.handleApplyFilterButton} disabled={this.handleDisableApplyFilterButton()} className={`apply_button ${this.handleDisableApplyFilterButton() ? "disabled-btn" : ""}`} size="small" style={{ textTransform: 'none', fontFamily: "Inter , sans-serif", }}>
                                   Apply
                                 </Button>
                               </Box>
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion className="find_work_side_bar_container_menu">
                           <AccordionDetails>
                             <Box className="accordian_details">
                               <Box>
                                 <Typography className="gray_color font_weight_600">Skill categories</Typography>
                               </Box>
                               <Box >
                                 <CreativeFields fields={this.state.creativeFields}
                                   selectedFields={this.state.selectedCategories}
                                   onApply={this.handleCreativeFieldApply} />
                               </Box>
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[0]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="budget"
                             className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(0)}
                           >
                             Budget
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
 
                               <Box>
                                 <img src={dancingBar} className="edit_profile_disco_bar" />
                                 <Box className="slider_container">
                                   <Slider
                                     value={[this.state.rateMin, this.state.rateMax]}
                                     onChange={this.handleRangeSliderChange}
                                     min={100}
                                     max={5000}
                                     valueLabelDisplay="auto"
                                     aria-labelledby="range-slider"
                                   />
                                 </Box>
                                 <Box className="budget_count_box">
                                   <Box className="budget_min_max_box">
                                     <Typography className="budget_min_max_color">Min (per hour)</Typography>
                                     <Typography className="budget_count_color">{this.state.rateMin}</Typography>
                                   </Box>
                                   <Typography className="budget_min_max_color to_padding">to</Typography>
                                   <Box className="budget_min_max_box">
                                     <Typography className="budget_min_max_color">Max (per hour)</Typography>
                                     <Typography className="budget_count_color">{this.state.rateMax}</Typography>
                                   </Box>
                                 </Box>
                               </Box>
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[1]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="location"
                             className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(1)}
                           >
                             {configJSON.locationTitle}
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
 
                               <FormControlLabel
                                 data-test-id="btnToggle10"
                                 id="remote_work"
                                 value="Remote"
                                 control={<Checkbox checked={this.state.isRemotLocationSelected} className="gray_color" />}
                                 label={<Typography className="gray_color">Remote</Typography>}
                                 onClick={this.handleRemoteWorkLocation}
                               />
                               <FormControlLabel
                                 data-test-id="btnToggle11"
                                 value={this.state.selectedLocation}
                                 id="other_location"
                                 control={<Checkbox onClick={this.handleWorkLocationSelected} className="gray_color" checked={this.state.isOtherLocationSelected} />}
                                 label={<Box>
                                   <SelectLocation fields={this.state.locationListingData}
                                     selectedFields={this.state.selectedLocation}
                                     handleValue={this.handleLocationApplyFn}
                                     locationKey={"FindWorkKey"}
                                   />
                                 </Box>
                                 }
                               />
 
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[2]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="work_duration"
                             className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(2)}
                           >
                             {configJSON.workDurationData.title}
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
                               {this.renderWorkDurationData()}
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[3]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="work_type"
                             className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(3)}
                           >
                             {configJSON.workTypeData.title}
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
                               {this.renderWorkTypeData()}
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[4]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="experience_level"
                             className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(4)}
                           >
                             {configJSON.experienceLevelData.title}
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
                               {this.renderExperienceLevelData()}
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                         <Divider className="divider" />
                         <Accordion expanded={this.state.accordianToggle[5]} className="find_work_side_bar_container_menu">
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon className="gray_color" />}
                             aria-controls="panel1-content"
                             id="hours_per_week" className="gray_color font_weight_600"
                             onClick={() => this.handleAccordianToggle(5)}
                           >
                             {configJSON.hoursPerWeekData.title}
                           </AccordionSummary>
                           <AccordionDetails>
                             <Box className="find_work_sidebar_accordian">
                               {this.renderHoursPerWeekData()}
                             </Box>
                           </AccordionDetails>
                         </Accordion>
                       </MenuItem>
                     </MenuList>
                   </Paper>
                 </Box>
 
               </Box>
               <Box className="find_work_right_main_container">
 
                 <Box>
                   <Box sx={{
                     display: 'flex',
                     justifyContent: 'space-between',
                   }}>
                     <this.StyledTabs id="tabs" onChange={this.handleTabValueChange} value={this.state.tabValues}
                       indicatorColor="primary"
                       className="Mui-indicator"
                     >
                       <Tab {...this.a11yProps(0)} />
                       <Tab {...this.a11yProps(1)} />
                     </this.StyledTabs>
                     <Box className="pagination">{
                       this.state.count > 1 && <Pagination onChange={this.handlePageChange} count={this.state.count} variant="text" defaultValue={this.state.page} shape="rounded" />
                     }
                     </Box>
                   </Box>
                   <Divider className="divider divider_for_tabs" />
                 </Box>
 
                 <this.CustomTabPanel value={this.state.tabValues} index={0}>
                   {this.renderSection()}
                 </this.CustomTabPanel>
                 <this.CustomTabPanel value={this.state.tabValues} index={1} data-test-id={'find-text'}>
                   {this.renderSection()}
                 </this.CustomTabPanel>
               </Box>
             </Box>
             {this.state.openOppertunity && (
               <WorkOppertunityModal
                 id="some-id"
                 selectedOpportunityId={this.state.selectedOpportunityId}
                 handleClose={this.handleClose}
                 openOppertunity={this.state.openOppertunity}
                 handleNavigate = {this.handleProposal}
               />
             )}
            </>
             :
             <>
             <Box className="find_work_main_container">
             <Box className="find_work_side_bar_container">

               {this.renderButtons()}


             </Box>
           </Box>

               <div style={{width:'100%',background:"rgba(23, 23, 23, 1)"}}>
      <AppBarProposal position="static">
      <Tabs value={this.state.proposalTab} onChange={this.handleChange} aria-label="simple tabs example">
  <Tab {...this.a11yProps23(0, "Offers received ")} />
  <Tab {...this.a11yProps23(1, "Under Review")} />
  <Tab {...this.a11yProps23(2, "Archived Proposals")} />
  <Tab {...this.a11yProps23(3, "Activated Contracts")} />
</Tabs>
<this.CustomTabPanel value={this.state.proposalTab} index={0}>
  <OfferReceived navigation={this.props.navigation} id={""} tabNo={0}/>
                 </this.CustomTabPanel>
                 <this.CustomTabPanel value={this.state.proposalTab}  index={1} data-test-id={'find-text'}>
                  <UnderReviewProposal navigation={this.props.navigation} id={""} tabNo={1}/>
                 </this.CustomTabPanel>
                 <this.CustomTabPanel value={this.state.proposalTab}  index={2} data-test-id={'find-text'}>
                 <UnderReviewProposal navigation={this.props.navigation} id={""} tabNo={2}/>
                 </this.CustomTabPanel>
                 <this.CustomTabPanel value={this.state.proposalTab}  index={3} data-test-id={'find-text'}>
                 <OfferReceived navigation={this.props.navigation} id={""} tabNo={3}/>
                 </this.CustomTabPanel>
      </AppBarProposal>
    </div>
      
       
             </>
  }
            </div>
            </Box>
           </Box>
          </>
        }
      </this.MainWrapper>
    );
  }
}

const CustomButton = withStyles({
  root: {
      ...customStyle.customStyle.buttonField
  }
})(Button);

export const AppBarProposal=styled(AppBar)({
  padding:'26px',
  '& .MuiTabs-flexContainer':{
    gap:'90px',
  },
  '& [class*="PrivateTabIndicator-colorSecondary-"]':{
    backgroundColor:"unset"
  },
  '& .MuiTabs-root':{
    borderBottom:'1px solid rgba(125, 125, 125, 1)'
  },
  '&.MuiAppBar-colorPrimary':{
    background:"rgba(17, 17, 17)"
  },
  '& .MuiTab-textColorInherit.Mui-selected':{
    fontFamily: "Inter",
    fontSize: '20px',
    textTransform:'inherit',
    fontWeight: 700,
    lineHeight: "24.2px",
    color: 'rgba(255, 242, 227, 1)',
    borderBottom:'7px solid rgba(255, 242, 227, 1)'
  },
  '& .MuiTab-textColorInherit':{
    fontFamily: "Inter",
    fontSize: '20px',
    textTransform:'inherit',
    fontWeight: 400,
    lineHeight: "24.2px",
    color: 'rgba(255, 242, 227, 1)',
  }
})

const webStyle: Record<string, CSSProperties> = {
  newResponseCon: {
    width: "100%",
    backgroundColor: "#111111",
    minHeight: '100vh'
  },
  container: {
    width: '100%',
    backgroundColor: "#111111",
    display: "flex",
    flexDirection: "column" as "column",
  },
  titleImg:{
    width: "100%",
      maxWidth: "calc(1920px - 96px)",
      height: "307px",
      objectFit: "cover",
      margin: "0 auto"
  },
  mainClientWrap:{
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%, 1920px)',
    margin: 'auto',
  },
  mainClientWrapDesigner:{
    backgroundColor: '#111',
    fontFamily: 'Inter, sans-serif',
    color: '#fff2e3',
    overflow: 'scroll',
    scrollbarWidth: 'none',
    width: 'min(100%, 1840px)',
    margin: 'auto',
  },
  mainBox: {
    width: "100%",
    backgroundColor: "#111111",
    display: "flex",
    gap: "3rem",
    padding: '1rem',
    boxSizing: 'border-box'
  },
  mainMargin: {
    margin: '60px 80px',
    width: '100%'
  },
  titleBox: {
    display: "flex",
    flexDirection: "column",
    opacity: "0px",
    gap: '18px'
  },
  myworkFont: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24.2px",
    textAlign: "left",
    color: "#FFF2E3",
  },
  exploreText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "19.36px",
    textAlign: "left",
    color: "#686868",
    cursor: "pointer",
    textDecoration: "underline",
  },
  grayBox: {
    height: '779px',
    backgroundColor: '#1F1F1F',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '50px 0'
  },
  titleDiv: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: 'column'
  },
  maintitle: {
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24.2px"
  },
  subtitle: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "22.08px",
    width: '100%',
    maxWidth: "460px",
    textAlign: 'center',
    marginTop: '25px'
  },
  buttonDiv: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonStyle: {
    width: '194px',
    marginTop: '45px'
  },
  mainOpenDiv: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    flexDirection: 'column'
  },
  activeColor: {
    borderImageSource: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  backgroundImage: "linear-gradient(90.83deg, #3FA7E1 -0.01%, #7699EF 31.13%, #AE9DF2 51.64%, #E8A5CA 72.11%, #F1B2C6 99.99%)",
  WebkitBackgroundClip: "text",
  MozBackgroundClip: "text",
  backgroundClip: "text",
  color: "transparent",
  borderImageSlice: 1,
  borderImageRepeat: "round",
  fontFamily: "Inter-SemiBold, sans-serif",
  borderBottom: '1px solid',
  padding: '0',
  margin: "40px 0"
  }
}

export default JobListing;
// Customizable Area End
