import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import dayjs, { Dayjs } from "dayjs";
import { getToken } from "../../../components/src/Base64Conversion";
import { getStorageData } from "../../../framework/src/Utilities";
import React, {  RefObject } from "react";
import { Response } from "../../cfproposalmanagement/src/UnderReviewProposal.web";
const config = require("../../../framework/src/config.js")

interface WorkOpportunityData {
  data?: {
    id: string;
    type: string;
    attributes: WorkOpportunityAttributes;
  };
}

interface WorkOpportunityAttributes {
  title: string;
  description: string;
  skills: Skill[];
  deliverables: string;
  work_type: string;
  location: string;
  experience_level: string;
  required_hours_per_week: number;
  rate_type: string;
  rate_amount: number;
  project_timeline_type: string;
  project_timeline: number;
  start_date: string;
  files_or_links: string | null;
  added_projects: string[];
  attached_files: string[];
  question: string[];
  agree_to_terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  Posted_time_ago: string;
  account_id: number;
  profile_image: string;
  account_details: AccountDetails;
  first_name: string;
  take_action_until: string;
  decline_time: string | null;
  contract: ContractData;
  activate_status: string | null;
  Post_history: PostHistory;
  contract_history: string | null;
  status: string | null;
  ongoing_status: string | null;
  is_saved_opportunity: boolean;
  saved_opportunity_id: string | null;
}

interface Skill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}

interface AccountDetails {
  background_color: string | null;
  user_location: string | null;
}

interface ContractData {
  data: Contract[];
}

interface Contract {
  id: string;
  type: string;
  attributes: ContractAttributes;
}

interface ContractAttributes {
  id: number;
  start_date: string;
  edited_start_date: string | null;
  proposal_id: number;
  account_id: number;
  terms_and_condition_accept: boolean;
  decline_contract: boolean;
  project_timeline: number;
  created_at: string;
  updated_at: string;
  contract_status: ContractStatus;
  contract_overall_budget: number;
  milestones: Milestones;
  work_opportunity_title: string;
  after_edit_project_timeline: number;
  project_timeline_type: string;
  project_rate_type: string;
  after_edits_overall_budget: number;
  contract_sent_on_date: string;
  resent_contract_sent_on_date: string;
  take_action_until: string;
  edit_request_n_date: string;
  awaing_action_till: string;
}

interface ContractStatus {
  id: number;
  receiver_id: number;
  status: string;
  contract_id: number;
  created_at: string;
  updated_at: string;
  action_taken_by: number;
}

interface Milestones {
  data: Milestone[];
}

interface Milestone {
  id: string;
  type: string;
  attributes: MilestoneAttributes;
}

interface MilestoneAttributes {
  id: number;
  contract_id: number;
  name: string;
  description: string;
  amount: number;
  due_date: string;
  client_approval: boolean;
  deliverables: Deliverables;
  edits: string | null;
}

interface Deliverables {
  data: Deliverable[];
}

interface Deliverable {
  id: string;
  type: string;
  attributes: DeliverableAttributes;
}

interface DeliverableAttributes {
  id: number;
  deliverable: string;
}

interface PostHistory {
  data: PostHistoryItem[];
}

interface PostHistoryItem {
  id: string;
  type: string;
  attributes: PostHistoryAttributes;
}

interface PostHistoryAttributes {
  id: number;
  work_opportunity_id: number;
  event: string;
  event_date: string;
  referenceable_type: string;
  referenceable_id: number;
}

interface ProposalData {
  data?: {
    id: string;
    type: string;
    attributes: ProposalAttributes;
  };
}

interface ProposalAttributes {
  id: number;
  name: string;
  location: string | null;
  submitted_rate: number;
  ratings: number;
  skills: string[];
  projects: string[];
  give_your_pitch: string;
  hourly_rate: number;
  overall_project_estimate: number;
  resource_files: any[];
  links: string | null;
  terms_and_conditions: boolean;
  created_at: string;
  updated_at: string;
  proposal_status: string;
  answer: string[];
  profile_photo: string | null;
  work_timeline: number;
  work_opportunity_title: string;
  work_opportunity_status: string;
  start_date: string;
  work_timeline_type: string;
  rate_type: string;
  rate_amount: number;
  background_color: string | null;
  required_hours_per_week: number;
  received_time_ago: string;
  user_name: string;
  views: number;
  likes: number;
  proposal_history: ProposalHistory;
}

interface ProposalHistory {
  data: ProposalHistoryItem[];
}

interface ProposalHistoryItem {
  id: string;
  type: string;
  attributes: ProposalHistoryAttributes;
}

interface ProposalHistoryAttributes {
  id: number;
  proposal_id: number;
  event: string;
  event_date: string;
  ref_type: string;
  ref_id: number;
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expanded: boolean[];
  showUpcoming: boolean;
  mileStoneData:any[];
  accordingToCondiitonData:any[];
  remaining_milestones:number;
  token:string;
  userType:string;
  activeID:number;
  isViewAllMilestone:boolean;
  contractData:any;
  addMileStone:boolean;
  milestoneName:string;
  milestoneDescription:string;
  milestoneDeliverables:{
    id:number|string;
    deliverable:string;
    deliverableError:boolean;
    deliverable_type: string;
    prevAdd:boolean
  }[];
  milestoneAmount:number|null;
  milestoneDueDate:Dayjs|null;
  isMilestoneNameLimit:boolean;
  isWordLimitDescription:boolean;
  openCalender:boolean;
  toastOpen:boolean;
  toastError:string;
   message:string;
  action:string;
  submitWorkOpen:boolean;
  files: {
    filename:string
      data: string
  }[],
  files_or_links: string,
  linkList: string[],
  submitDescritpion:string;
  isWordLimitSubmitDescritpion:boolean;
  isWordLimitReasonDescritpion:boolean;
  isSubmession:boolean;
  active_contract_milestone:any;
  editPopUp:boolean;
  milestoneId:number;
  milestoneType:string;
  isViewSubmessionPopUp:boolean;
  isViewSubmessionPopUpAll:boolean;
  isViewSubmessionMilestonePopUp:boolean;
  viewSubmessionData:any;
  afterSubmessiondata:boolean;
  editAmountCheck:boolean;
  awaitMilestoneIndex:null|number;
  notificationdata:any;
  showNotification:boolean;
  resourcelinks:any[];
  notification_count:number;
  unread_notification_count:number;
  page:number;
  submessionLinksData:any[];
  deleteDataState:any[];
  viewAllMilestoneData:any[];
  indexOfNotification:boolean;
  menuOpen:boolean;
  temp:number;
  endContractPopUp:boolean;
  reasonEndContractDescription:string;
  giveRatingPopUp:boolean;
  ratingCount:number;
  feedBack:string;
  feedbackError:boolean;
  raiseDisputePopUp:boolean;
  raiseDisputeDescription:string;
  raiseDisputeDescriptionError:boolean;
  raiseDisputeDetails:any;
  raiseDisputeDetailsData:{
    attributes:{
        id: number;
contract_id: number;
issue: string;
created_at:string;
raised_by:string;
updated_at:string;
is_resolved: boolean,
raised_date: string;
resolved_date: null|string;
    }
  }[];
  viewWorkPost:boolean;
  viewProposal:boolean;
  proposalData:ProposalData;
  workOpportunityData:WorkOpportunityData;

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CfActiveContractForDesignerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataContractId:string="";
  postAddMilestoneID:string="";
  submitWorkId:string="";
  editMilestoneId:string="";
  refElm: RefObject<HTMLInputElement> = React.createRef();
viewSubmissionId:string='';
resourceLinkId:string='';
notificationLinkId:string='';
notificationViewId:string='';
allSubmessionId:string="";
viewAllMilestoneId:string="";
createContractEnd:string="";
raiseDisputeId:string="";
raiseDisputeAllId:string="";
viewProposalId:string="";
viewWrokPostId:string="";
resolveRaiseDisputeId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      submessionLinksData:[],
      workOpportunityData:{},
      proposalData:{},
      raiseDisputeDetailsData:[],
      raiseDisputeDetails:{},
      raiseDisputeDescription:"",
      raiseDisputeDescriptionError:false,
      raiseDisputePopUp:false,
      giveRatingPopUp:false,
  ratingCount:0,
  feedBack:"",
  feedbackError:false,
      reasonEndContractDescription:"",
      endContractPopUp:false,
      temp:0,
      indexOfNotification:false,
      viewAllMilestoneData:[],
      showNotification:false,
      menuOpen:false,
      deleteDataState:[],
      page:1,
      resourcelinks: [  ],
notification_count:0,
unread_notification_count:0,
      notificationdata: [
      ],
      expanded: [],
      awaitMilestoneIndex:null,
      isViewSubmessionMilestonePopUp:false,
      toastError:"",
      editAmountCheck:false,
      submitDescritpion:"",
      isViewSubmessionPopUp:false,
      isViewSubmessionPopUpAll:false,
      showUpcoming: false,
      milestoneId:0,
      milestoneType:"",
      isSubmession:false,
      isWordLimitSubmitDescritpion:false,
      isWordLimitReasonDescritpion:false,
  submitWorkOpen:false,
  files: [],
  files_or_links: "",
  active_contract_milestone:{},
  linkList: [],
      activeID:0,
      userType:"",
      token:"",
      mileStoneData:[],
      accordingToCondiitonData:[],
      remaining_milestones:1,
      contractData:[],
      isViewAllMilestone:false,
      addMileStone:false,
      milestoneName:"",
  milestoneDescription:"",
  milestoneDeliverables:[{id:0,deliverable:"",deliverableError:false,deliverable_type: "BxBlockDashboard::Deliverable",prevAdd:false}],
  milestoneAmount:null,
  milestoneDueDate:dayjs(),
  isMilestoneNameLimit:false,
  openCalender:false,
  isWordLimitDescription:false,
  toastOpen:false,
  message:"",
  action:"",
  editPopUp:false,
  viewSubmessionData:[],
  afterSubmessiondata:false,
  viewWorkPost:false,
  viewProposal:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId===this.viewProposalId){
        if(!responseJson?.error && !responseJson?.errors)
this.setState({proposalData:responseJson})
      }
      else if(apiRequestCallId===this.resolveRaiseDisputeId){
        if(!responseJson?.error && !responseJson?.errors){
        this.getAllListOfDispute();
        this.setState({raiseDisputeDetails:null})
        }
      }
      else if(apiRequestCallId===this.viewWrokPostId){
           if(!responseJson?.error && !responseJson?.errors)
            {this.setState({workOpportunityData:responseJson})
          }
              }
    else  if(apiRequestCallId==this.notificationViewId){
        this.setState({indexOfNotification:true})
        this.getNotificationLink(this.state.page);
      }
      if(apiRequestCallId===this.getDataContractId){
        if(!responseJson?.error && !responseJson?.errors)
          this.getProposalData(responseJson);
        this.getWorkPostData(responseJson);
        this.setState({contractData:responseJson})
      const milestoneData=responseJson?.activate_milestone_details;
      const remainingMilestone=milestoneData.length-2;
      const accordingToConditionData=milestoneData.length>2 ?milestoneData.slice(0,2):milestoneData;

      this.setState({ 
         accordingToCondiitonData: this.state.isViewAllMilestone ?milestoneData:
         [...accordingToConditionData, { remaining_milestones: remainingMilestone>0?remainingMilestone:0 }],
         remaining_milestones: remainingMilestone>0?remainingMilestone:0,
         mileStoneData:milestoneData,
         expanded:new Array(milestoneData.length).fill(false),
         raiseDisputeDetails:responseJson?.dispute_raise_details
       })

      }
      else if(apiRequestCallId==this.postAddMilestoneID){
        if(!responseJson?.error && !responseJson?.errors)
{
  this.setState(
    {
      toastOpen: true,
      action: "success",
      message: "Milestone created successfully!",
    },
    () => {
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          action: "",
          message: "",
        });
      }, 4000);
    }
  );
  this.resetStateForAddMilestone();
  this.setState({addMileStone:false});
  this.getDataContract(this.state.activeID);

}
      }
else if(apiRequestCallId==this.editMilestoneId){
  if(!responseJson?.error && !responseJson?.errors)
    {
      this.setState({addMileStone:false});
      this.setState(
        {
          action: "success",
          message: "Milestone edited successfully!",
          toastOpen: true,
        },
        () => {
          setTimeout(() => {
            this.setState({
              action: "",
              message: "",
              toastOpen: false,
            });
          }, 4000);
        }
      );
      this.resetStateForAddMilestone();
      this.getDataContract(this.state.activeID);
    }
}
    else  if(apiRequestCallId==this.submitWorkId){
      if(!responseJson?.error && !responseJson?.errors){
        this.setState({isSubmession:true,submitWorkOpen:false})

        this.setState(
          {
            toastOpen: true,
            action: "success",
            message: "Work submitted successfully.",
          },
          () => {
            setTimeout(() => {
              this.setState({
                toastOpen: false,
                action: "",
                message: "",
              });
            }, 4000);
          })
    this.resetSubmitFormState();
    this.getDataContract(this.state.activeID);
      }

      }

else if(apiRequestCallId==this.viewSubmissionId){
 if(!responseJson.error && !responseJson.errors){
  this.setState({viewSubmessionData:responseJson?.data,afterSubmessiondata:responseJson?.data.length==2 ?true:false})
 }
}  

else if(apiRequestCallId==this.notificationLinkId){
  if(!responseJson.error && !responseJson.errors){
  let data=this.state.temp==0?responseJson?.notifications.slice(0,2):responseJson?.notifications;
 { !this.state.indexOfNotification  && responseJson?.unread_notification_count!==0 &&data.forEach((item:{id:number})=>{
      this.getViewAllNotification(item.id);
    })}
  
    this.setState({notificationdata:this.state.indexOfNotification ?this.state.notificationdata:[...this.state.notificationdata,...responseJson?.notifications],
      unread_notification_count	:responseJson?.unread_notification_count,
      notification_count:responseJson?.notification_count})
      this.setState({indexOfNotification:false});
  }

}
else if(apiRequestCallId==this.allSubmessionId){
  const transformedData = responseJson.data.flatMap((item:any) => {
    const submissionTime = item.attributes.submission_time;
  
    const files = (item.attributes.files || []).map((file:any) => ({
      file_type: file.file_type,
      file_name: file.file_name,
      url: file.url,
      submission_time: submissionTime,
    }));
  
    const links = (item.attributes.submission_links || []).map((link:any) => ({
      link_url: link,
      submission_time: submissionTime,
    }));
  
    return [...files, ...links];
  });
  this.setState((prevState) => ({
    submessionLinksData: [...transformedData, ...prevState.submessionLinksData],
  }));

}
else if(apiRequestCallId==this.resourceLinkId){
  if(!responseJson.error && !responseJson.errors){
    this.setState({resourcelinks:responseJson})
  }
}

else if(apiRequestCallId==this.viewAllMilestoneId){
  if(!responseJson.error && !responseJson.errors){
   this.setState({viewAllMilestoneData:responseJson?.combined_milestones})
  }
}

else if(apiRequestCallId==this.createContractEnd){
  if(!responseJson.error && !responseJson.errors){
    this.setState({giveRatingPopUp:false})
    this.setState(
      {
        toastOpen: true,
        action: "success",
        message: responseJson.message,
      },
      () => {
        setTimeout(() => {

          this.setState({
            toastOpen: false,
            action: "",
            message: "",
          });
          this.props.navigation.navigate("ContractPage")
        }, 2000);
      })
  }

}

else if(apiRequestCallId==this.raiseDisputeId){
  if(!responseJson.error && !responseJson.errors){
    this.setState({raiseDisputePopUp:false});
    this.getDataContract(this.state.activeID);
    this.getAllListOfDispute();
  }
}

else if(apiRequestCallId==this.raiseDisputeAllId){
  if(!responseJson.error && !responseJson.errors){
    this.setState({raiseDisputeDetailsData:responseJson?.data})
  }
  }
  
}
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
  await this.getTokenData()
    const url = window.location.href;
    const id = url.split('/').pop();
    this.setState({ activeID: Number(id)})
    this.getDataContract(Number(id));
    this.getResourceLink();
    this.getAllSubmessionFn()
    this.getNotificationLink(this.state.page);
    this.getAllMilestone();
    this.getAllListOfDispute();
 
  }

   findIndexOfWait = (data:any) => {
    for (let index = 0; index < data?.length; index++) {
      const item = data[index];
      
      if (item.milestone_status === "approved") {
        if (index + 1 < data.length) {
          if (!data[index + 1].activate) {
            return index + 1; 
          }
        }
      }
    }
    
    return 0;
  };
  formatToLocaleString = (data: Response) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
  getTokenData = async () => {
    const token = await getToken();
     let userInfo= await getStorageData("userInfo")
     let UserType=JSON.parse(userInfo)
    this.setState({ token: token,userType:UserType?.data?.attributes?.role_name})
};
  handleNavigation(path:string) {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }


  handleRemainingMilestone = () => {
    this.setState({ accordingToCondiitonData: this.state.mileStoneData, showUpcoming: true })
  }
  handleAccordionToggle = (index: number) => {
    const newExpanded = this.state.expanded.map((item: boolean, idx: number) =>
      idx === index ? !item : item
    );
    this.setState( 
       { expanded: newExpanded })
  };
  escapeRemainingMilestone = () => {
    const remainingMilestone = this.state.mileStoneData.length - 2;
    const newConditionData = [
      ...this.state.mileStoneData.slice(0, 2), 
      { remaining_milestones: Math.max(remainingMilestone, 0) }
    ];
    
    this.setState(prevState => ({
        accordingToCondiitonData: newConditionData,
        showUpcoming: false,
        expanded: [...prevState.expanded.slice(0, 2), ...new Array(Math.max(remainingMilestone, 0)).fill(false)]
    }));
}
  getDataContract = async (id:number) => {
    const headers = {
        "Content-Type": "application/json",
        token: this.state.token,
    };

    const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
      this.getDataContractId = getValidationsMsg.messageId
   
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
       `bx_block_cfdesignersidecontractmanagement/designers_contracts/designer_active_contract_details/${id}`
      )
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'GET'
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }


  //All view milestone function

  handleOpenViewAllMilestone=()=>{

    this.setState({isViewAllMilestone:true,accordingToCondiitonData:this.state.mileStoneData,
      expanded:new Array(this.state.mileStoneData.length).fill(false),
      showUpcoming:false,
    });
  }
  handleCloseViewAllMilestone=()=>{
    const remainingMilestone=this.state.mileStoneData.length-2;
    this.setState({isViewAllMilestone:false,
      accordingToCondiitonData:
      [...this.state.mileStoneData.slice(0,2), { remaining_milestones: remainingMilestone>0?remainingMilestone:0 }],
      expanded:new Array(this.state.mileStoneData.length).fill(false)
    })
  }

  //add milestone function
handleMileStoneName=(e:React.ChangeEvent<HTMLInputElement>)=>{
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 50;

  if (!isExceedingLimit) {
    this.setState({ milestoneName: e.target.value });
  }
  this.setState({ isMilestoneNameLimit: isExceedingLimit });
    
}
handleMileStoneNameDescription=(e:React.ChangeEvent<HTMLTextAreaElement>)=>{


  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 500;

  if (!isExceedingLimit) {
    this.setState({ milestoneDescription: e.target.value });
  }

  this.setState({ isWordLimitDescription: isExceedingLimit });
  
    
}

handleRaiseDisputeDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 200;

  if (!isExceedingLimit) {
    this.setState({ raiseDisputeDescription: e.target.value });
  }

  this.setState({ raiseDisputeDescriptionError: isExceedingLimit });
};
handleReasonDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 200;

  if (!isExceedingLimit) {
    this.setState({ reasonEndContractDescription: e.target.value });
  }

  this.setState({ isWordLimitReasonDescritpion: isExceedingLimit });
};
handleFeedBackRating = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 200;

  if (!isExceedingLimit) {
    this.setState({ feedBack:  e.target.value });
  }

  this.setState({ feedbackError: isExceedingLimit });
};
handleSubmitDescription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  const value = e.target.value.trim(); 
  const isExceedingLimit = value.length > 200;

  if (!isExceedingLimit) {
    this.setState({ submitDescritpion:  e.target.value });
  }

  this.setState({ isWordLimitSubmitDescritpion: isExceedingLimit });
};

handleMileStoneNameAmount=(e:React.ChangeEvent<HTMLInputElement>)=>{
  const value = e.target.value;
    const numericValue = parseFloat(value);
    if (numericValue <= 9999999) {
      this.setState({milestoneAmount:numericValue})
    } else {
      this.setState({milestoneAmount:0})

    }
    
}
showMileStoneNameError = () =>{
  return this.state.isMilestoneNameLimit ? "Reached Limit of 50 char" : ""
}
showDescriptionErrorEndContractfeedback = () =>{
  return this.state.feedbackError ? "Reached Limit of 200 char" : ""
}
showDescriptionErrorRaiseDispute = () =>{
  return this.state.raiseDisputeDescriptionError ? "Reached Limit of 200 char" : ""
}

showDescriptionErrorEndContract = () =>{
  return this.state.isWordLimitReasonDescritpion ? "Reached Limit of 200 char" : ""
}

showDescriptionError = () =>{
  return this.state.isWordLimitSubmitDescritpion ? "Reached Limit of 200 char" : ""
}
showMilestoneDescriptionError = () =>{
  return this.state.isWordLimitDescription ? "Reached Limit of 500 char" : ""
}
  handleAddMileStoneClose=()=>{
    this.setState({addMileStone:false})
    if(this.state.editPopUp){
      this.setState({editPopUp:false})
    }
    this.resetStateForAddMilestone();
  }
  handleAddMileStone=()=>{
    this.setState({addMileStone:true})
  }
  handleMenuIcon=()=>{
    this.setState({menuOpen:!this.state.menuOpen})
  }
  handlePropagation = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  }
  
  openCalender = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      openCalender: true
    });
    this.handlePropagation(event);
  }
  

closeCalender=()=>{
  this.setState({
    openCalender: false
  });
  }
  handleDateChange = (date: Dayjs | null) => {
    this.setState({ milestoneDueDate: date });
};
  showDeliverableError = (select:boolean) =>{
    return select? "Reached Limit of 150 char" : ""
  }
  handleAddDeliverable = () => {
    this.setState({ milestoneDeliverables:
       [...this.state.milestoneDeliverables,
        {id:this.state.milestoneDeliverables.length,
          deliverable:"",
          deliverableError:false,
          prevAdd:false,
          deliverable_type: "BxBlockDashboard::Deliverable"
       }] });
  };
  handleDeleteDeliverable = (id:number|string) => {
    const updatedData = this.state.milestoneDeliverables.filter((item:{
      id:number|string;
      deliverable:string;
      deliverableError:boolean;
      prevAdd:boolean
  },index) => {
     return item.id!==id;
    });
let deleteData=[];
    const deletDataFind= this.state.milestoneDeliverables.find((item:{
      id:number|string;
      deliverable:string;
      deliverableError:boolean;
      prevAdd:boolean
  },index) => {
     return item.id==id && item.prevAdd;
    });
if(deletDataFind){
  deleteData.push(deletDataFind.id);
  deleteData.push(deletDataFind.deliverable_type);
}

    this.setState({ milestoneDeliverables: updatedData,deleteDataState:[...this.state.deleteDataState,deleteData] });
  };
  handleDeliverableChange = (id: number|string, e: any) => {
    const { value } = e.target;
  
    const updatedData = this.state.milestoneDeliverables.map(
      (milestone: { 
        id:number|string;
        deliverable: string; 
        deliverableError: boolean; 
        prevAdd:boolean
        deliverable_type:string;
      },index) => {
        if (milestone.id == id) {
          return {
          ...milestone,
            deliverable: value,
            deliverableError: value.trim().length > 150, 
          };
        }
        return milestone;
      }
    );
  
    this.setState({ milestoneDeliverables: updatedData });
  };
  isFeedbackVaild=()=>{
    if(this.state.feedbackError){
      return true;
    }
    return false;
  }
  isSubmitFormValidRaiseDispute=()=>{
    if(this.state.raiseDisputeDescriptionError ||!this.state.raiseDisputeDescription  ){
      return true;
    }
    return false;
  }
  isSubmitFormValidRaesonEndContarct=()=>{
    if(this.state.isWordLimitReasonDescritpion ||!this.state.reasonEndContractDescription  ){
      return true;
    }
    return false;
  }
  isSubmitFormValid=()=>{
    if(this.state.files.length>0 || this.state.linkList.length>0 ){
      return false;
    }
    return true;
  }
  handleStarClick=(index:number)=>{
    this.setState({ ratingCount: index + 1 });
  }
  isFormValid = () => {
    const { milestoneName, milestoneDescription, milestoneDueDate, milestoneDeliverables, milestoneAmount } = this.state;
    if (this.state.isWordLimitDescription||this.state.isMilestoneNameLimit||
      !milestoneName || !milestoneDescription || !milestoneAmount ||milestoneAmount<=0 || !milestoneDueDate ||
      milestoneDeliverables.some((item: {
        deliverable:string;
        deliverableError:boolean
      }) => !item.deliverable || item.deliverableError)
    ) {
      return false;
    }   
    return true;
  };

  resetStateForAddMilestone=()=>{
    this.setState({
      milestoneName:"",
      milestoneDescription:"",
      milestoneDeliverables:[
        {id:0,
          deliverable:"",
          deliverableError:false,
          prevAdd:false,
          deliverable_type: "BxBlockDashboard::Deliverable"
        }],
      milestoneAmount:null,
      editPopUp:false,
      milestoneDueDate:dayjs(this.convertDateFormat(this.state.mileStoneData[this.state.mileStoneData.length-1].due_date)),
      isMilestoneNameLimit:false,
      deleteDataState:[],
      openCalender:false,
      isWordLimitDescription:false
    })
  }

// implement api for add milestone

handleAddMilestoneApi = () => {
  const headers = {
    "Content-Type": "application/json",
    token:this.state.token
  };
  const deliverablesData=this.state.milestoneDeliverables.map((item)=>{
    return item.deliverable
  })
  const httpBody = {
    "data" : {
        "attributes" : {
            "contract_id" : this.state.activeID,
            "milestone_details" : {
                "name" : this.state.milestoneName,
                "description" : this.state.milestoneDescription,
                "deliverables" : deliverablesData,
                "amount": this.state.milestoneAmount ,
                "due_date" : this.state.milestoneDueDate?.format("DD-MM-YYYY")
            }
        }
    }
}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.postAddMilestoneID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "/bx_block_cfdesignersidecontractmanagement/client_contracts/add_milestone"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

handleToastClose=()=>{
  this.setState({toastOpen:false})
}


//for submit work pop up


handleSubmitPopUpOpen=(editPartAttribute:any)=>{
  this.setState({submitWorkOpen:true,active_contract_milestone:editPartAttribute})
}
handleSubmitWork=()=>{
  
  const headers = {
    token:this.state.token
  };

  let submitFormData=new FormData();
  submitFormData.append("work_submission[contract_id]", String(this.state.activeID));
  submitFormData.append("work_submission[submission_links]", this.state.linkList.join(','));
  submitFormData.append("work_submission[message]",this.state.submitDescritpion );
  submitFormData.append("work_submission[contract_milestone_id]", this.state.active_contract_milestone.id);
  submitFormData.append("work_submission[contract_milestone_type]", this.state.active_contract_milestone.milestone_type);
  {
    this.state.files?.length>0 &&
    this.state.files.forEach((item:any)=>{
      submitFormData.append("work_submission[files][]", item.data,item?.filename);
    })
  }
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.submitWorkId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    "bx_block_cfdesignersidecontractmanagement/designer_work_submissions/submit_work/"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "POST"
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    submitFormData
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;

}
handleSubmitPopUpClose=()=>{
  this.setState({submitWorkOpen:false})
  this.resetSubmitFormState();
}

resetSubmitFormState=()=>{
  this.setState({
    linkList:[],
    files:[],
    submitDescritpion:"",
    toastError:""
  })
}

validFileFormat = (fileData: File) => {
  const validFileTypes = [
    "image/jpeg",
    "image/png",
    "image/jpg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ];
  if (!validFileTypes.includes(fileData.type)) {
    this.setState({
      toastError: "Please select a valid file (jpg, png, pdf, doc, docx).",
   
    });
    return false;
  }
  return true
}

handleResourceFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
  if(this.state.files.length >= 5)
    return
  const maxSizes = {
    image: 25 * 1024 * 1024
  };

  if (!event.target.files) return;
  const tempFile = event.target.files[0];
  if (!this.validFileFormat(tempFile)) {
    this.refElm.current && (this.refElm.current.value = "");
    return;
  }
  const maxFileSize = maxSizes.image;
  if (tempFile.size > maxFileSize) {
    this.setState({
  
      toastError: `File size exceeds 25MB. Please upload a smaller file.`
    });
    this.refElm.current && (this.refElm.current.value = "");
    return;
  }


  const fileObject = {
    filename: tempFile.name,
    data: tempFile
  };

  this.setState((prevState:any) => ({
    files: [...prevState.files, fileObject],
    toastError:""
  }));

  this.refElm.current && (this.refElm.current.value = "");
 
  
}



updateLink = (data: string) => {
  this.setState({
    files_or_links: data
  });
}

handlelinkData = (event: any) => {
  const keyData = event.keyCode ? event.keyCode : event.which

  if (keyData === 13 && this.isUrlValid(this.state.files_or_links)) {
    this.setState({
      linkList: [...this.state.linkList, this.state.files_or_links],
      files_or_links: "",
    toastError:""
    })
  }
}
isUrlValid = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    this.setState({
  
      toastError: "Please enter valid link",
 
    })
    return false;
  }
}
handleDeleteLink = (id:number) =>{
  let newValue = this.state.linkList.filter((_,index)=>{
    return index !== id
  })

  this.setState({linkList:newValue})
}
openImageInNewTab(base64Data:string) {
  const newTab :any= window.open();
  newTab.document.body.innerHTML = `<img src="${base64Data}" alt="Image" style="max-width:100%; max-height:100%;" />`;
}

handleDeleteFile = (id:number) =>{
  let newValue = this.state.files.filter((_,index)=>{
    return index !== id
  })
  this.setState({files:newValue})
}

isFileAdded = () => {
  return this.state.files.length > 0 ? true : false;
}

//for edit milestone

 convertDateFormat=(dateString:string) =>{
  let [day, month, year] = dateString.split('/');
  year = year.length === 2 ? `20${year}` : year;
  return `${month}/${day}/${year}`;
}

  openEditMilestonePopUp = (data: any) => {
    const dataMilestone = data.deliverables
    .filter((item: { id: number | string; deliverable: string }) => item.deliverable) // Filter valid items
    .map((item: { id: number | string; deliverable: string }) => ({
      ...item,
      deliverableError: false,
      prevAdd: true,
    }));
    this.setState({
      addMileStone: true,
      editAmountCheck:data.activate,
      editPopUp: true,
      milestoneType:data.milestone_type	,
      milestoneId: data.id,
      milestoneName: data.name,
      milestoneDescription: data.description,
      milestoneDeliverables:dataMilestone,
      milestoneAmount: data.amount,
      milestoneDueDate:dayjs(this.convertDateFormat(data?.due_date)),
      isMilestoneNameLimit: false,
      openCalender: false,
      isWordLimitDescription: false
    })
  }


  handleEditMilestone = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token
    };
    const data = this.state.milestoneDeliverables.map((item: any) => {
      if (item.prevAdd) {
        return {
          id: item.id,
          deliverable: item.deliverable,
          deliverable_type:item.deliverable_type
        }
      }
      else {
        return {
          new_deliverable: item.deliverable,
        }

      }

    })

    const httpBody = {
      "data": {
        "attributes": {
          "contract_id":this.state.activeID,
          "milestone_type": "BxBlockDashboard::ContractMilestone",
          "milestone_id": this.state.milestoneId,
      "milestone_edits": {
        "name": this.state.milestoneName,
        "description": this.state.milestoneDescription,
        "amount": this.state.milestoneAmount,
        "due_date": this.state.milestoneDueDate,
        "deliverables": data,
        "deleted_deliverables":this.state.deleteDataState
      },
    }
  }}


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editMilestoneId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_cfdesignersidecontractmanagement/client_contracts/edit_milestone`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleApiForAddEditMilestone = () => {
    if (this.state.editPopUp) {
      this.handleEditMilestone()
    }
    else {
      this.handleAddMilestoneApi()
    }
  }



   openisViewSubmession=(data:any)=>{

this.setState({isViewSubmessionPopUp:true,active_contract_milestone:data})
this.getSubmissionViewData(data);

  }

  closeisViewSubmession=()=>{
    this.setState({isViewSubmessionPopUp:false,isViewSubmessionPopUpAll:false})
      }
   
      handleisViewSubmessionMilestonePopUp=()=>{
        this.setState({
          isViewSubmessionMilestonePopUp:!this.state.isViewSubmessionMilestonePopUp
        })
      }
    
      getSubmissionViewData = async (data:any) => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewSubmissionId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designer_work_submissions/get_submissions_based_on_milestone/?work_submission[contract_id]=${this.state.activeID}&work_submission[contract_milestone_id]=${data.id}&work_submission[contract_milestone_type]=${data.milestone_type}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    

      downloadImage = async (item: any) => {
        const response = await fetch(config.baseURL + item.url);
        const blob = await response.blob();
        const link = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        link.href = objectUrl;
        link.download = item.file_name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      };
      
      getResourceLink = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.resourceLinkId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/contract_resources/${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

       formatDate = (isoDate: string): string => {
          const date = new Date(isoDate);
          const options: Intl.DateTimeFormatOptions = {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          };
          return date.toLocaleString("en-US", options);
      };
      getAllSubmessionFn = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.allSubmessionId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_cfdesignersidecontractmanagement/designer_work_submissions/view_submissions/?work_submission[contract_id]=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getNotificationLink = async (page:number) => {
        this.setState({page:page})
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.notificationLinkId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_notifications/notifications/contract_notifications?contract_id=${this.state.activeID}&page=${page}&per_page=5`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getViewAllNotification = async (id:number) => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.notificationViewId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_notifications/notifications/${id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
       
      getAllMilestone = async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewAllMilestoneId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designers_contracts/list_milestones?id=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
       

      contractEndFn = () => {
        const headers = {
          "Content-Type": "application/json",
          "token":this.state.token
        };
    
        const httpBody ={
          "contract_end": {
              "status": "contract_end_succesfully",
              "contract_id": this.state.activeID,
              "reason": this.state.reasonEndContractDescription,
              "client_feedback":this.state.feedBack,
              "client_rating": this.state.ratingCount
          }
      };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.createContractEnd = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_cfdesignersidecontractmanagement/designers_contracts/create_contract_end"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };
      handleRaiseDisputeFn = () => {
        const headers = {
          "Content-Type": "application/json",
          token:this.state.token
        };
        const httpBody = {
          "raise_dispute": {
              "contract_id": this.state.activeID,
              "issue": this.state.raiseDisputeDescription
          }
      }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.raiseDisputeId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_cfdisputemanagement/raise_disputes"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      };



      getAllListOfDispute= async () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.raiseDisputeAllId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_cfdesignersidecontractmanagement/designers_contracts/get_dispute_list?contract_end[contract_id]=${this.state.activeID}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    
    
      getProposalData= async (contractData:{
        contracts:{
          data:{
          attributes:{
            proposal_id:number
          }
        }
      }
      }) => {
        console.log(contractData,"contractDatacontractData");
        
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewProposalId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `/bx_block_joblisting/proposals/${contractData?.contracts?.data?.attributes?.proposal_id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
      getWorkPostData= async (contractData:{
        contracts:{
          data:{
          attributes:{
            work_opportunity_id:number
          }
        }
      }
      }) => {
        
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
    
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
      
          this.viewWrokPostId = getValidationsMsg.messageId
       
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
           `bx_block_landingpage2/work_opportunities/${contractData?.contracts?.data?.attributes?.work_opportunity_id}`
          )
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

      putResolveRaiseDispute=(id:number)=>{
        const headers = {
          "Content-Type": "application/json",
          token: this.state.token,
      };
  
      const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.resolveRaiseDisputeId = getValidationsMsg.messageId
     
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
         `/bx_block_cfdisputemanagement/raise_disputes/resolve_dispute?id=${id}`
        )
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );
      getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          'PUT'
      );
      runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }
    
    getRateTypeLabelProposal = (data: any) => {
        const rate_types = data?.attributes?.rate_type;
        if (rate_types && rate_types.includes("hourly")) {
            return "pr/hr";
        }
        return "";
    }
  // Customizable Area End
}