import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

interface IFile {
  file_type: string;
  file_name: string;
  url: string;
}

interface ISkill {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  category_id: number;
}

interface IAccountDetails {
  name: string;
  email: string;
  id: number;
  profile_photo: null;
  background_color: string;
}

interface IDelivarable {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface IProject {
  id: number;
  project_title: string;
  cover_image: string;
  user_name: string;
  profile_image: null;
  background_color: string;
  like_count: number;
  view_count: number;
  save_count: number;
}

interface ICollection {
  collection_id: number;
  collection_name: string;
  projects: IProject[]
}

interface ISpecificProject {
  id: string | number, 
  cover_image_url: string, 
  likes: string | number, 
  views: string | number,
  project_name: string,
  username: string,
  saved_count: string | number
}

interface IResponse {
  id: number;
  service_name: string;
  duration: string;
  min_timeline: string;
  max_timeline: string;
  work_setting: string;
  city: string;
  minimum_rate: number;
  maximum_rate: number;
  description: string;
  links: string[];
  created_at: string;
  updated_at: string;
  account_details: IAccountDetails;
  skills: ISkill[];
  cover_image: string;
  files: IFile[];
  deliverables: IDelivarable[];
  projects: ICollection[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string,string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeCarouselId: number;
  stepCarouselId: number;
  isLoading: boolean;
  token: string;
  baseURL: string;
  userRole: string;
  response: IResponse | null;
  files: string[];
  open: boolean;
  message: string;
  action: string;
  activeUserId: number;
  serviceUserId: number;
  showDetails: boolean;
  selectedProject: string | number;
  specificProjectList: ISpecificProject[],
  selectedCollection: ICollection | null,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewServiceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  viewServicesGetApiId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeCarouselId: 0,
      stepCarouselId: 0,
      token: "",
      baseURL: "",
      userRole: "",
      response: null,
      isLoading: true,
      files: [],
      open: false,
      message: "",
      action: "success",
      activeUserId: 0,
      serviceUserId: -1,
      showDetails: false,
      selectedProject: "",
      specificProjectList: [],
      selectedCollection: null,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getTokenFromLocalStorage()
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { requestCallId } = this.getResponseFromMessage(message);
      if (this.viewServicesGetApiId === requestCallId) {
        this.handleViewServiceResponse(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleViewServiceResponse = (message: Message) => {
    const { response } = this.getResponseFromMessage(message);
    if (!!response.data && !!response.data.attributes) {
      let files: string[] = []
      let serviceUserId: number = -1;
      const newResponse = response.data.attributes as IResponse;
      if (Array.isArray(newResponse.files)) {
        files = newResponse.files.map(file => file.url);
      }

      if(!!newResponse.cover_image) {
        files = [newResponse.cover_image, ...files];
      }

      if(!!newResponse.account_details && !isNaN(newResponse.account_details.id)) {
        serviceUserId = newResponse.account_details.id;
      }
      this.setState({ response: response.data.attributes, files, serviceUserId, isLoading: false })
      return;
    }

    if(Array.isArray(response.errors)) {
      const token = response.errors[0];
      this.setState({
        open: true,
        action: "danger",
        message: token.token,
        isLoading : false,
      })
      return;
    }

    if(!!response.error) {
      this.setState({
        open: true,
        action: "danger",
        message: response.error,
        isLoading : false,
      })
    }
  }

  handleCloseToast =() => {
    this.setState({
      open: false,
      message: ""
    })
  }

  getResponseFromMessage = (message: Message) => { 
    let response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const requestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    return ({ requestCallId, response })
  }

  getTokenFromLocalStorage = async () => {
    let tokenDetail = await getStorageData("userInfo");
    const tokenParseData = JSON.parse(tokenDetail);
    let token: string = "";
    let userRole: string = "";
    let activeUserId: number = 0;
    if(tokenParseData && tokenParseData.data && tokenParseData.data.attributes) {
      userRole = tokenParseData.data.attributes.role_name;
    }

    if (tokenParseData && tokenParseData.meta) {
      (!isNaN(tokenParseData.meta.id)) && (activeUserId = tokenParseData.meta.id);
    }
    if (tokenParseData && tokenParseData.meta) {
      (tokenParseData.meta.token) && (token = tokenParseData.meta.token);
    } 

    this.setState({
      token,
      baseURL: configJSON.baseURL.baseURL,
      userRole,
      activeUserId,
    },this.getViewServiceDetails)   
  }

  createMessage = (endPoint: string, method: string) => {
    const contactMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    contactMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    contactMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    contactMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    return contactMessage;
  }

  getViewServiceDetails = () => {
    const serviceId = this.props.navigation.getParam("id");
    const endpoint = `${configJSON.AddServiceEndPoint}/${serviceId}`
    const message = this.createMessage(endpoint,configJSON.validationApiMethodType);
    this.viewServicesGetApiId = message.messageId;
    runEngine.sendMessage(message.id,message);
  }


  handleNavigateToLandingPage = async () => {
    const { activeUserId, serviceUserId } = this.state;
    if(activeUserId === serviceUserId){ 
      this.handleNavigate("UserProfile")
      await setStorageData("activeCard","Services")
    }
    else this.handleNavigate("PublicUserProfile");
  }

  handleNaviagateToEditPage = () => {
    const serviceId = this.props.navigation.getParam("id");
    this.props.navigation.navigate("EditService", { id: serviceId });
  }

  handleNavigateProfileScreen = (comment: { userId: string, user_role: string }, userId: string) => {
    let path = userId === comment.userId ? "UserProfile" : "PublicUserProfile"
    let localdata = {
      account_id: comment.userId,
      user_role: comment.user_role
    }
    if (path == "PublicUserProfile") {
      setStorageData("PublicId", JSON.stringify(localdata));
    }
    this.handleNavigate(path);
  }

  closeModal = (likes:number | string, views:number | string) => {
    this.handleChangeLikeCount(likes,views)
    this.setState({
      specificProjectList: [],
      selectedProject: '',
      showDetails: false
    })
  }

  handleChangeLikeCount = (likes:number | string, views:number | string) =>{
    const { selectedCollection, response, selectedProject } = this.state;
    if(!!selectedCollection &&  !!response && Array.isArray(response.projects)) {
     const value = response.projects.map(collection => {
      if(collection.collection_id === selectedCollection.collection_id) {
       const newProjectList = collection.projects.map(project => {
        if(project.id === selectedProject) {
          project.like_count = likes as number;
          project.view_count = views as number;
        }
        return project;
       })
       return ({...collection,projects: newProjectList}) 
      }
      return collection;
     })

     response.projects = value;
      this.setState({ response: response })
    }
  }

  handleSelectedProject = (data: IProject, collection: ICollection) => {
    const allData = [];
    for (const project of collection.projects) {
      if (project.id !== data.id) {
        allData.push(project);
      }
      if (allData.length === 3) {
        break;
      }
    }
    allData.push(data);
    const newData:ISpecificProject[] = allData.map(project => (
      {
        cover_image_url: this.state.baseURL+project.cover_image,
        id: project.id,
        likes: project.like_count,
        project_name: project.project_title,
        saved_count: project.save_count,
        username: project.user_name,
        views: project.view_count
      }
    ))
    this.setState({
      showDetails: true,
      selectedProject: data.id,
      specificProjectList: [...newData],
      selectedCollection: collection,
    });
  }

  handleNavigate = (path: string) => {
    const navigateMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateMessage.addData(getName(MessageEnum.NavigationTargetMessage), path);
    this.send(navigateMessage);
  }


  handleActiveImage = (activeCarouselId: number) => {
    this.setState({activeCarouselId})
  }

  handleLeftArrowClick = () => {
    this.setState(prev => {
      const { activeCarouselId, stepCarouselId } = prev;
      return ({
        activeCarouselId: prev.activeCarouselId - 1,
        stepCarouselId: 
        stepCarouselId !== 0 && activeCarouselId === stepCarouselId ? 
        stepCarouselId - 1 :
        stepCarouselId
      })
    })
  }

  handleRightArrowClick = () => {
    this.setState(prev => {
      const { files, activeCarouselId, stepCarouselId } = prev;
      return ({
        activeCarouselId: activeCarouselId + 1,
        stepCarouselId: (
          files.length - 5 === stepCarouselId || 
          activeCarouselId < 4) ? 
          stepCarouselId : stepCarouselId + 1
      })
    })
  }

  getLocationText = () => {
    const { response } = this.state;
    if (!!response) {
      return response.city || configJSON.remoteAnyWhereText;
    }
  }

  getTimeLineText = () => {
    const { response } = this.state;
    if (!!response) {
      return `${response.min_timeline || ""} - ${response.max_timeline || ""} ${response.duration || ""}`
    }
  }

  getRateText = () => {
    const { response } = this.state;
    if (!!response)
      return `₹ ${response.minimum_rate || ""} - ${response.maximum_rate || ""}`
  }

  getArrayData = () => {
    const { stepCarouselId, files } = this.state;
    return files.slice(stepCarouselId, stepCarouselId + 5)
  }
  // Customizable Area End
}
