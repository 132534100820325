// Customizable Area Start
import React from "react";

import OfferReceivedController, { Milestone } from "./OfferReceivedController";
import {  Box, Button, CircularProgress,  Dialog, DialogContent, Divider, Grid, Step, StepContent, StepLabel,  TextareaAutosize,  Typography } from "@material-ui/core";
import { FlatList, View, Text, } from "react-native";
import { createTheme, ThemeProvider ,styled} from '@mui/material/styles';


import {  profileIcon } from "./assets";
import { AboutWorkOpp, ProposalComponent, NoResultFound, DownloadIcon, CalenderIcon, EditMileStoneData, handleFirstLetter } from "../../../components/src/ProposalComponent";
import { CustomBox, InputField, proposalStyle,  webStyle2 } from "./Cfproposalmanagement.web"
import { styles, Response, StepperStyledComponent, StepperStyledComponentWithNumber } from './UnderReviewProposal.web'
const config = require("../../../framework/src/config.js");
import './style.css'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { closeIcon } from "../../landingpage/src/assets";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ToastMSG from '../../../components/src/ToastMSG.web';
import dayjs, { Dayjs } from "dayjs";
import AddIcon from '@material-ui/icons/Add';

export const LocationButton = styled(Button)({
  fontFamily: "Inter , sans-serif",
  marginTop: 0,
  textTransform: 'none',
  backgroundColor: "rgb(50, 50, 50)",
  fontSize: "12px",
  fontWeight: 600,
  linehHeigth: "14.52px",
  color: "#FFF2E3",
  borderRadius: "21px",
  border: " 1px solid #FFF2E3",
  padding: "11px 22px 11px 22px",
  width: "max-content",
  height: "37px",
});
export const SkillButton = styled(Button)({
  fontFamily: "Inter , sans-serif",
  marginTop: "24px",
  backgroundColor: "rgb(50, 50, 50)",
  textTransform: 'none',
  fontSize: "12px",
  fontWeight: 600,
  linehHeigth: "14.52px",
  color: "#FFF2E3",
  borderRadius: "21px",
  border: " 1px solid #FFF2E3",
  padding: "11px 22px 11px 22px",
  width: "max-content",
  height: "37px",
});
const theme = createTheme({
  palette: {
      primary: {
          main: '#fff2e3',
      }
  },
  components: {
      MuiPaper: {
          styleOverrides: {
              root: {
                  backgroundColor: '#222',
                  color: '#fff2e3'
              }
          }
      },
      MuiTypography: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#fff2e3 !important',
              }
          }
      },
      MuiIconButton: {
          styleOverrides: {
              root: {
                  color: '#fff2e3 !important'
              }
          }
      },
      MuiButtonBase: {
          styleOverrides: {
              root: {
                  fontFamily: 'Inter,sans-serif !important',
                  color: '#7d7d7d !important',
                  '&.Mui-selected': {
                      color: '#222 !important',
                      backgroundColor: '#fff2e3 !important'
                  },
                  '&.MuiPickersDay-today': {
                      border: '1px solid #fff2e3 !important'
                  }
              }
          }
      },
      MuiDialogContent: {
          styleOverrides: {
              root: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  backgroundColor: "transparent",
                  overflowY: 'auto',
                  boxShadow: "none",
                  overflowX: "hidden",
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                      width: '0',
                      height: '0',
                  }
              }
          }
      }
  },
});
class OfferReceived extends OfferReceivedController {
  formatToLocaleString = (data: Response) => {
    const rate_types = data?.attributes?.rate_type;
    if (rate_types && rate_types.includes("hourly")) {
      return "pr/hr";
    }
    return "";
  }
   getProfileDetailsRenderItem = (proposalData: any) => {
    return proposalData?.attributes?.profile_image && proposalData?.attributes?.profile_image?.includes("rails") 
      ? (
        <img src={config.baseURL + proposalData.attributes.profile_image} style={styles.itemImage} alt="Default Profile" />
      ) : (
        proposalData?.attributes?.account_details?.background_color ? (
          <Box
            style={{ 
              ...styles.itemImage, 
              backgroundColor: proposalData?.attributes?.account_details?.background_color 
            }}
          />
        ) : (
          <img src={profileIcon} alt="Default Profile" style={styles.itemImage} />
        )
      );
  };
  
  getProfileDetails = () => {
    return this.state.proposalData?.attributes?.profile_photo ?
      <img src={config.baseURL + this.state.proposalData.attributes.profile_photo} className="img-view-preview" alt="Default Profile" /> :

      (this.state.proposalData?.attributes?.background_color ? (

        <div
          className="img-view-preview"
          style={{ backgroundColor: this.state.proposalData?.attributes.background_color }}
        />
      ) : (
        <img src={profileIcon} className="img-view-preview" alt="Default Profile" />
      ));
  };
  formatProjectTimeline = (data: Response) => {
    return `${data?.attributes?.project_timeline} ${data?.attributes?.project_timeline_type || "Months"}`;
  }
  handleVisibility = (data: string, location?: string) => {
    return data && data.trim() ? <Text style={location ? styles.itemUserLocationText : styles.itemUserNameText}>{  handleFirstLetter(data)
}</Text> : null
  }

  ItemSeparator = () => <View style={styles.separator} />;

  handleOpen = (responseJson: Response) => {
    this.setState({ offerReceivedId: responseJson.id });
    this.setState({ dataForPopUp: responseJson,sharedProposalPopUp:false,accordianPop:false })
    this.getDetailsApi(responseJson.id);
    this.handlediscardPopUpMain()
  };
  handleItemOfHeadingDescription=(item:any)=>{
if(this.state.tab==0){
  return <Text  style={OfferReceivedWebStyle.headingStleForList}>
    Take action until 
<span className="take_action_until">
{item?.attributes?.contract?.data[0]?.attributes?.take_action_until&& this.formatDateTime(item?.attributes?.contract?.data[0]?.attributes?.take_action_until)}
</span>      
    </Text>
}
else if (this.state.tab==1){
  return <>
  <Text  style={OfferReceivedWebStyle.headingStleForList}>
    {item?.attributes?.ongoing_status?.status_name}
  
    </Text>
    <span className="take_action_until" style={{paddingLeft:'0px',marginTop:'6px'}}>
    {item?.attributes?.ongoing_status?.status_date}
    </span>    
  </>
}
else{
  return <Text style={OfferReceivedWebStyle.headingStleForList}>
    {item?.attributes?.status}
<span className="take_action_until">
{item?.attributes?.decline_time}
</span>      
    </Text>
}
  }
  renderItemPopUp: any = ({ item }: any) => {
    return (
      <Box className={"popUpBox"}
        key={item?.id}
        flexDirection={"column"} boxSizing={"border-box"} data-testId="workOppertunityModal"
      >
        <View style={{
          display: "flex",
          flexDirection: "column"
        }} >
          <Text style={styles.itemHeadingText} data-testId="worktitle" >{this.state.dataForPopUp?.attributes?.title}</Text>
          <Box style={{
            display: "flex",
            marginTop: 24,
            flexWrap: 'wrap',
            gap: '4px',
            flexDirection: "row"  
          }}>
            <View style={{ ...styles.itemRateDuration, ...styles.marginRate }}>
              <Text style={styles.itemRateText}>Rs.{this.state.dataForPopUp?.attributes?.rate_amount && this.state.dataForPopUp?.attributes.rate_amount.toLocaleString('en-IN')} {this.formatToLocaleString(item)}</Text>
            </View>
            <View style={styles.itemRateDuration}>
              <Text style={styles.itemDurationText}>{this.formatProjectTimeline(this.state.dataForPopUp)}</Text>
            </View>
          </Box>
          <Text style={{
            fontSize: 12,
            lineHeight: 16.02,
            marginTop: 24,
            color: "#fff2e3",
            fontFamily: "Inter,sans-serif",
            overflow: 'hidden',
          }} numberOfLines={2} ellipsizeMode="tail">
            {this.state.dataForPopUp?.attributes?.description}
          </Text>
        </View>
        <div data-testId="NavigatetoPublic" >
          <View style={{
            marginTop: 16,
            borderTopWidth: 1.05,
            borderTopColor: "#3E3E3E",
            borderStyle: "solid",
            paddingTop: 11,
            display: "flex",
            justifyContent: "space-between" as 'space-between',
            width: "100%",
            flexDirection: "row" as 'row',
          }}>
            <Box style={{
              display: "flex",
              marginTop: 8,
              flexDirection: "row",
              alignItems: "center", width: '50%'
            }} onClick={() => { this.handleNavigateFind(this.state.dataForPopUp?.attributes) }}>
              {this.getProfileDetailsRenderItem(this.state.dataForPopUp)}
              <View style={{ display: "flex", width: '60%' }}>
                {this.handleVisibility(this.state.dataForPopUp?.attributes?.first_name)}
                {this.handleVisibility(this.state.dataForPopUp?.attributes?.account_details?.user_location, "location")}
              </View>
            </Box>
            <Text style={{
              color: "#66625C",
              fontFamily: "Inter,sans-serif",
              fontSize: 11,
              lineHeight: 13.31,
              alignSelf: "flex-end"
            }}>{this.state.dataForPopUp?.attributes?.Posted_time_ago}</Text>
          </View>
        </div>
      </Box>
    )
  };
  renderItem: any = ({ item }: any) => {

    return (
      <Box className={this.state.offerReceivedId == item?.id ? "active-pending-box" : "pending-box"}
        key={item?.id}
        flexDirection={"column"} boxSizing={"border-box"} data-test-id="workOppertunityModal" onClick={() => this.handleOpen(item)}
      >
        {
          this.props.tabNo==3 && 
          <>
          <Text style={{
            fontSize: 13,
            color: "rgba(97, 202, 120, 1)",
            lineHeight: 13.31,
            fontFamily: "Inter,sans-serif",
            alignSelf: "flex-start"
          }}>
         {item?.attributes?.activate_status}
            </Text>
          <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", 
            marginBlock: '10px' ,
            marginTop:'22px',
            }} />

        </>
        }
        {(item?.attributes?.status != "" && this.props.tabNo==0 )&&
          <>
          {this.handleItemOfHeadingDescription(item)}
            <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", 
              marginBlock: '10px' }}
               />

          </>
        }
        <View style={{
          display: "flex",
          flexDirection: "column"
        }} >
          <Text 
          data-testId="worktitle"
          style={styles.itemHeadingText} 
           >{item?.attributes?.title}</Text>
          <Box style={{
            display: "flex",
            flexWrap: 'wrap',
            marginTop: 24,
            gap: '4px',
            flexDirection: "row"
          }}>
            <View
             style={{ 
               ...styles.marginRate ,
              ...styles.itemRateDuration, 
             }}>
              <Text 
              style={styles.itemRateText}
              >Rs.{item?.attributes?.rate_amount && item.attributes.rate_amount.toLocaleString('en-IN')} {this.formatToLocaleString(item)}</Text>
            </View>
            <View style={styles.itemRateDuration}>
              <Text style={styles.itemDurationText}>{this.formatProjectTimeline(item)}</Text>
            </View>
          </Box>
          <Text style={{
            fontSize: 12,
            marginTop: 24,
            color: "#fff2e3",
            fontFamily: "Inter,sans-serif",
            overflow: 'hidden',
            lineHeight: 16.02,
          }} 
          ellipsizeMode="tail"
          numberOfLines={2} 
          >
            {item?.attributes?.description}
          </Text>
        </View>
        <div data-testId="NavigatetoPublic" >
          <View style={{
            marginTop: 16,
            borderTopWidth: 1.05,
            paddingTop: 11,
            display: "flex",
            justifyContent: "space-between" as 'space-between',
            width: "100%",
            flexDirection: "row" as 'row',
            borderTopColor: "#3E3E3E",
            borderStyle: "solid",
          }}>
            <Box data-test-id="handleNavigateFind"
            style={{
              display: "flex",
              marginTop: 8,
              flexDirection: "row",
              alignItems: "center", width: '50%'
            }} onClick={() => { this.handleNavigateFind(item?.attributes) }}>
              {this.getProfileDetailsRenderItem(item)}
              <View style={{ display: "flex", width: '60%' }}>
                {this.handleVisibility(item?.attributes?.first_name)}
                {this.handleVisibility(item?.attributes?.account_details?.user_location, "location")}
              </View>
            </Box>
            <Text style={{
              color: "#66625C",
              lineHeight: 13.31,
              fontFamily: "Inter,sans-serif",
              fontSize: 11,
              alignSelf: "flex-end"
            }}>{item?.attributes?.Posted_time_ago}</Text>
          </View>
        </div>
      </Box>
    )
  };


  navBarForirstPart = () => {
    if(this.props.tabNo==0){

    return <Box className="pending-box" style={{
      display: 'flex', flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      border:"unset",
      paddingBottom:'0px'

    }}>
      <Typography onClick={()=>{
        this.handleTab(0)
      }}
      data-test-id="new-tab"
      className={`normal-typography ${this.state.tab==0 &&'active-typography'}`} style={{
        paddingInline: '10px'
      }}>
        New
      </Typography>
                  <Divider style={{...OfferReceivedWebStyle.dividerStyle,height:"24px",width:"1px",background:"#FFF2E3",marginBlock:'unset'}} />


      <Typography 
      data-test-id="ongoing-tab"
      onClick={()=>{
        this.handleTab(1)
      }}className={`normal-typography ${this.state.tab==1 &&'active-typography'}`} style={{
        paddingInline: '20px'
      }}>
        Ongoing
      </Typography>
                  <Divider style={{...OfferReceivedWebStyle.dividerStyle,height:"24px",width:"1px",background:"#FFF2E3",marginBlock:'unset'}} />


      <Typography 
      data-test-id="Declined-tab"
style={{paddingInline:"20px"}}
      onClick={()=>{
        this.handleTab(2)
      }}className={`normal-typography ${this.state.tab==2 &&'active-typography'}`}>
        Declined
      </Typography>
    </Box>
    }
  }

  DioalogComponentForOffereAceptOdDecline = (popUpOpenState: boolean,
    heading: string, description: string,
    terms: boolean,
    closeFn: () => void, btnName: string, itemShow:boolean,mainFn: () => void,dynamicStyle:any) => {
    return <DioalogComponent
      open={popUpOpenState}
      fullWidth={true}
      data-test-id="close-onclick"
      style={{
        scrollbarWidth: 'none'
      }}
      maxWidth="sm"
    >
           <Box style={{height:40,display:'flex',justifyContent:'flex-end'}}>
            <img
                style={{cursor:'pointer',width:'14px',height:'14px'}}
                src={closeIcon}
                data-test-id = "closeButton"
                alt="Close"
                onClick={()=>closeFn()}
              />
            </Box>
      <DialogContent
      style={{

         borderRadius:'10px',
  border:'1px solid rgba(95, 95, 95, 1)',
      }}
      >
        <Typography className="popUpHeading">
          {heading}
        </Typography>
        <Typography className="popUpSubHeading" style={{width:itemShow?'398px':'356px'}}>
          {description}
        </Typography>
      { itemShow && <Box style={{
          paddingBlock: '15px',
          borderBottom: '1px solid rgba(84, 84, 84, 1)',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          alignItems: 'center',
          flexDirection: 'column'

        }}>

          { this.renderItemPopUp(this.state.dataForPopUp)}
          {terms && <Box onClick={this.handleChange} style={{ display: 'flex', gap: '12px', alignItems: 'center' ,cursor:'pointer'}}>
            {!this.state.termsAndConditionCheck ? <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.58178" y="0.58178" width="19.8364" height="19.8364" rx="4.41822" fill="#FFF2E3" stroke="#FFF2E3" stroke-width="1.16356" />
            </svg> : <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.58178" y="0.58178" width="19.8364" height="19.8364" rx="4.41822" fill="#FFF2E3" stroke="#FFF2E3" stroke-width="1.16356" />
              <path d="M4.56692 11.3024L8.67561 15.6049L15.5234 7" stroke="#171717" stroke-width="1.7" stroke-linecap="round" />
            </svg>}

            <span className="terms-text">
              I agree to all <span style={{ textDecoration: 'underline' }}>terms and conditions</span>
            </span>
          </Box>
          }

        </Box>}
        <Box style={{...dynamicStyle,
          display: 'flex', width: '392px',
          
          paddingBlock: '15px'
        }}>

          <Button className="btn-cancel"
            data-test-id="btn-cancel"
            onClick={
             ()=> closeFn()
            }
          >
            Cancel
          </Button>
          <Button className="btn-send-offer"
            data-test-id="btn-send-offer"
            disabled={terms ?!this.state.termsAndConditionCheck:false}
            onClick={()=>mainFn()}
            style={{background:
            (  terms &&!this.state.termsAndConditionCheck) ? "rgba(255, 242, 227, 0.51)":"rgba(255, 242, 227, 1)"}}
            >
            {btnName}
          </Button>
        </Box>
      </DialogContent>
    </DioalogComponent>
  }
  handleButton=()=>{
    return (this.state.tab==0)&&
    <>
    <Typography style={{ display: 'flex', gap: '11px', height: 'fit-content' ,alignItems:'center',cursor:'pointer'}}   data-test-id="editRequestDataTestId"
      onClick={()=>this.handleeditRequest()}>
      <Box style={OfferReceivedWebStyle.downloadIconStyle} 
    >
        {DownloadIcon()}

      </Box>
      <span 
       className="offered-contract-sub-edit" >
        Request edits
<Divider style={{...OfferReceivedWebStyle.dividerStyle,marginBlock:"2px",backgroundColor:"rgba(255, 242, 227, 1)"}} />

      </span>
    </Typography>
    <Button className="btn-cancel"
      data-test-id="declinePopOpen"
      onClick={() => {
        this.declinePopOpen()
      }}
    >
      Decline
    </Button>
    <Button className="btn-send-offer"
      data-test-id="acceptPopOpen"
      style={{background:
        "rgba(255, 242, 227, 1)"}}
      onClick={() => [
        this.acceptPopOpen()
      ]}>
      Accept contract
    </Button>
   </>
  }
handleHeadingDescription=()=>{
  if(this.props.tabNo==0){

    if(this.state.tab==0 ) {
  
  return   <>
    <Typography className="offer-received-sub-heading">
      Contract sent on {this.formatDateShort(this.state.mileStoneData?.attributes?.contract_sent_on_date)}
    </Typography>
    <Typography className="offer-received-sub-sub-heading">
      Take action until  {this.state.mileStoneData?.attributes?.take_action_until && this.formatDateTime(this.state.mileStoneData?.attributes?.take_action_until)}
    </Typography>
  </>
    } 
    else if(this.state.tab==1 ){
      return   <>
      <Typography className="response_pending_style">
     {this.state.status}
      </Typography>
      <Box style={{display:'flex',justifyContent:'space-between'}}>
      <Typography className="offer-received-sub-heading">
    {this.state.request_sent_date}
      </Typography>
      <Typography className="offer-received-sub-sub-heading">
      Awaiting action until: {this.formatDateTime(this.state.mileStoneData?.attributes?.take_action_until)}
      </Typography>
      </Box>
    <Divider style={{...OfferReceivedWebStyle.dividerStyle,marginBlock:'unset'}} />

      </>
    }
    else {
  
  return   <>
  <Typography className="offer-received-sub-sub-heading">
  The following contract was declined by you
  </Typography>
  <Typography className="offer-received-sub-heading">
  {this.state.deadline}
  </Typography>
  <Divider style={{...OfferReceivedWebStyle.dividerStyle,marginBlock:'unset'}} />

  </>
    }
  }
  else if(this.props.tabNo==3){
    return   <>
    <Box style={{display:'flex',gap:'10px',alignItems:'end' }}>

    <Typography  className="response_pending_style" style={{color: 'rgba(97, 202, 120, 1)'
}}>
    Accepted and activated
    </Typography>
    <Typography className="viewContractStyle" style={{marginBottom:"6px"}}>
    View Contract
    </Typography>
    </Box>
    <Typography className="offer-received-sub-heading">
    {this.state.activatedStatus}
    </Typography>
    <Divider style={{...OfferReceivedWebStyle.dividerStyle,marginBlock:'unset'}} />
    </>
  }
}

render() {
  const handleCurrentDate = (): Dayjs => {
    return dayjs();
  }
  const getMinDate = (editMileStoneId: number): Dayjs => {
    const milestones = this.state.afterEditMilestone;
    
    if (!milestones || milestones.length === 0) {
        return handleCurrentDate(); // Default to today's date if no milestones
      }
      
      const index = milestones.findIndex(
        (item: any) => item?.attributes?.id === editMileStoneId
      );
      
      if (index <= 0) {
        return handleCurrentDate();
      }
    
      const prevMilestone = milestones[index - 1];
      const prevDueDate = dayjs(prevMilestone?.attributes?.due_date);
      
      return prevDueDate.isValid() ? prevDueDate.add(1, 'day') : handleCurrentDate();
    };
    
    const dataMileStone=this.state.afterEditMilestone.find((item:any)=>{
      return item?.attributes?.id==this.state.editMileStoneId
    })
    const filterEdit=this.state.expanded.some((item:any)=>{
      return item.isEditAnyThing
    })
  
 const   handleFirstPartOfGrid=()=>{
      if(this.state.tab==0 ){
        if(this.state.editRequest && filterEdit  ){
          return   <Typography className="originalByClient" style={{
            display: 'flex',
            height: '31px',
            padding: "6px 16px 6px 16px",
            alignItems: 'end'
          }}>
            Original offer by client
          </Typography>
        }
        else if(!this.state.editRequest){
          return <Typography className="offered-contract-sub-edit" style={{
            display: 'flex',
            height: '31px',
            padding: "6px 16px 6px 16px",
            alignItems: 'end'
          }}>
            <span style={{ fontWeight: 400, marginRight: '4px' }}>
              Start date: {" "}
            </span>
            {this.formatDateShort(this.state.mileStoneData?.attributes?.start_date)}
          </Typography>
        }
       
      }
      else{
        return <Typography className="offered-contract-sub-edit" style={{
          display: 'flex',
          height: '31px',
          padding: "6px 16px 6px 16px",
          alignItems: 'end'
        }}>
          <span style={{ fontWeight: 400, marginRight: '4px' }}>
            Start date: {" "}
          </span>
          {this.formatDateShort(this.state.mileStoneData?.attributes?.contract_sent_on_date)}
        </Typography>
      }
    }
    return (
      <>
      <ThemeProvider theme={theme}>
      <ToastMSG open={this.state.open} close={() => this.handleClose()} message={this.state.message} action={this.state.action} />

        {this.state.loading ?
          <Box style={OfferReceivedWebStyle.lodingBox}>
            <Box style={OfferReceivedWebStyle.lodingBoxSecond}>
              <CircularProgress className="gray_color" size={25} />
            </Box>
            <Typography className="gray_color" style={{ marginTop: '20px', textAlign: 'center' }}>Searching...</Typography>
          </Box>
          :
          <Grid container style={{ justifyContent: "space-between", paddingTop: '5px' }} spacing={1}>
            {this.state.dataListForMap.length > 0 ?
              <>
                <Grid item xs={12} sm={4} md={4} lg={3} style={OfferReceivedWebStyle.firstpartStyle}>
                  {this.navBarForirstPart()}

                  <View style={{paddingTop:(this.state.tab==0 &&this.props.tabNo==0)?'0px':"24px"}}>
                    <FlatList

                      data-test-id="flat-list"
                      data={this.state.dataListForMap}
                      renderItem={this.renderItem }
                      keyExtractor={(item: any) => item.id}
                      horizontal={false}
                      showsHorizontalScrollIndicator={false}
                      contentContainerStyle={{ display: 'flex', flexDirection: 'column' }}
                      ItemSeparatorComponent={this.ItemSeparator}
                      showsVerticalScrollIndicator={true}

                    />
                  </View>
                </Grid>
                <Grid item xs={12} sm={8} md={8} lg={9} className="about-under-review-second-box">
                  <Box style={{ display: 'flex', justifyContent: 'space-between' ,flexWrap:'wrap',paddingBottom:(this.state.tab==0 && this.props.tabNo==0)?'40px':'0px',backgroundColor:(this.state.tab==0 && this.props.tabNo==0)?"rgba(39, 39, 39, 1)":"unset"}} className="edit-box">
                    <Box   style={{  display: 'flex', gap: '10px', flexDirection: 'column',width:this.props.tabNo==3 ||this.state.tab==1 ||this.state.tab==2?'100%':'50%',}} className="firstPartOfMilestone">
                        {
                          this.state.editRequest ?
                            <>
                              <Typography className="offered-contract-heading" style={{ fontSize: "36px" }}>
                                Request edits
                              </Typography>
                              <Typography className="offer-received-sub-sub-heading">
                                Select the milestone you want to request edits to
                              </Typography>
                            </>
                            :
                            <>{this.handleHeadingDescription()}
                               </>
                        }
                    </Box>
                    <Box    style={{ display: 'flex',
                        gap: '20px', alignItems: 'center',flexWrap:'wrap' ,width:(this.props.tabNo==3 ||this.state.tab==1 ||this.state.tab==2)?'0%':'50%'}} className="secondartOfMilestone">
                    {
  this.props.tabNo === 0 &&
  (!this.state.editRequest ? (
    this.handleButton()
  ) : (
    <>
      <Button
        className="btn-cancel"
        data-test-id="handlediscardPopUp"
        onClick={this.handlediscardPopUp}
      >
        Discard
      </Button>
      <Button
        className="btn-send-offer"
        data-test-id="acceptPopOpen"
        onClick={this.putEditMilestoneOffer}
        disabled={this.sendEditRequestHandle()}
        style={{
          background: this.sendEditRequestHandle()
            ? "rgba(255, 242, 227, 0.51)"
            : "rgba(255, 242, 227, 1)"
        }}
      >
        Send edit request
      </Button>
    </>
  ))
}

                    </Box>
                    <Box>
                    </Box>
                  </Box>
                  <Box style={{
                    flexWrap:'wrap',
                    display: 'flex', background: 'unset',  padding:!this.state.editRequest? '20px 40px':'20px 40px 5px 40px'
                  }} className="edit-box">
                    <Box   style={{  display: 'flex', height: '100%', justifyContent: 'flex-start',alignItems:'center' }} className="firstPartOfMilestone">
                    <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <Typography className="offered-contract-sub-heading" style={{color:"transparent"}}>
                          {"select"}
                        </Typography>
                          <Typography className="offered-contract-heading" style={{
                            fontSize: '20px'
                            , height: '31px',
                            paddingBlock: "6px",
                            alignItems: 'end'
                          }}>
                            Offered Contract Milestones
                          </Typography>
                     </Box>
                    </Box>
                    <Box   style={{ display: 'flex', alignItems: 'center',  gap: '20px',flexWrap:'wrap' }} className="secondartOfMilestone">
                      <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <Typography className="offered-contract-sub-heading">
                          {""}
                        </Typography>
                    {handleFirstPartOfGrid()}
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <Typography className="offered-contract-sub-heading">
                          Project timeline
                        </Typography>
                        <Typography style={{...webStyle2.month,height:'unset',wordBreak:'break-all'}}>
                          {this.state.mileStoneData?.attributes?.project_timeline}{" "}
                          {
                            this.state.mileStoneData?.attributes
                              ?.project_timeline_type
                          }{" "}
                        </Typography>
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
                        <Typography className="offered-contract-sub-heading">
                          Overall budget:
                        </Typography>
                        <Typography style={{...webStyle2.priceHour,height:'unset',wordBreak:'break-all'}}>
                          Rs.
                           {this.state.mileStoneData?.attributes?.contract_overall_budget && this.state.mileStoneData?.attributes?.contract_overall_budget.toLocaleString('en-IN')}{" "}
                          {
                            this.getRateTypeLabelProposal(this.state.mileStoneData)
                          }
                        </Typography>

                      </Box>
                    </Box>
                  </Box>
              { ((filterEdit||this.state.editRequest) && this.state.tab==0)&&   <Box style={{
                    flexWrap:'wrap',
                    display: 'flex', background: 'unset',  padding: '0px 40px'
                  }} className="edit-box">
                    <Box   style={{  display: 'flex',alignItems:'center',gap:'4px' }} className="firstPartOfMilestone">
                    <Typography className="offered-contract-sub-edit" style={{
                          display: 'flex',
                          alignItems:'center'
                        }}>
                          <span style={{ fontWeight: 400, marginRight: '4px' }}>
                            Start date: {" "}
                          </span>
                          {this.formatDateShort(this.state.afterEditMilestone[0]?.attributes?.due_date)}
                        </Typography>
                        <Box
                                      style={
                                       OfferReceivedWebStyle.downloadIconStyle
                                      }
                                    >
{DownloadIcon()}
</Box>
                     
                    </Box>
                   {filterEdit &&  <Box   style={{ display: 'flex', alignItems: 'center',  gap: '20px',flexWrap:'wrap' }} className="secondartOfMilestone">
                      
                        <Typography className="originalByClient" style={{
                          display: 'flex',
                          height: '31px',
                          padding: "6px 16px 6px 16px",
                          alignItems: 'end'
                        }}>
                         After requested edits
                        </Typography>
                        <Typography style={webStyle2.month}>
                          {this.state.projectTimelineNumber}{" "}
                          {
                            this.state.projectTimelineType
                          }{" "}
                        </Typography>
                   
                        <Typography style={webStyle2.priceHour}>
                          Rs. {this.state.overallBudget.toLocaleString('en-IN')}{" "}
                          {
                            this.getRateTypeLabelProposal(this.state.mileStoneData)
                          }
                        </Typography>
                    </Box>}
                  </Box>}
                  <>
                    <StepperStyledComponentWithNumber  style={{ padding: '40px 40px 0px 40px' }} activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper">
                      {this.state.mileStoneData?.attributes?.milestones?.data && this.state.specificMileStoneData.map((label: Milestone,index:number) => {
                        const isExpanded = this.state.accordianMilestoneData[index];
                        const editPart=label?.attributes?.edits;
                        const backgroundStyle={background: (editPart && this.state.tab==1)?"rgba(50, 50, 50, 1)":"transparent", borderRadius:"11px 11px 0px 0px"}
                        const paddingStyle={padding: (editPart && this.state.tab==1)?"16px 12px 0px 12px":"0px",marginTop: (editPart && this.state.tab==1)?'-16px':'0px', }
                        const paddingStyleSecond={padding: (editPart && this.state.tab==1)?"0px 12px 27px 12px":"0px", borderRadius:"0px 0px 11px 11px" }

 const isEditAnyThing = this.state.expanded.find((item: { id: number; isEditAnyThing: boolean }) => {
                          return item.id == label?.attributes?.id;
                        })?.isEditAnyThing;
                        const editPartAttribute=(editPart && this.state.tab==1)?this.state.afterEditMilestone[index]:label
                        const dataisEditAnyThing=isEditAnyThing?this.state.afterEditMilestone[index]:[];
                        
                        
                        return  <Step key={editPartAttribute?.attributes?.id+index} expanded={isExpanded}>
                        <StepLabel >
                          <Box 
                            style={{ ...backgroundStyle,...paddingStyle,color: "white", fontFamily: "inter", width: '100%' }}
                          >
                             <Box
                                onClick={() => {
                              (    !this.state.editRequest  )&& this.handleAccordionToggle(index)
                                }}
                                data-test-id="handleAccordionToggle"
                               
                                style={OfferReceivedWebStyle.accordianSummaryStyle}
                              >
                                <Box style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: 'space-between' ,marginBottom:'10px'}}>
                                  <Box style={{ display: "flex", gap: '6px',alignItems:'center' }}>
                                    <Typography>
                                      {editPartAttribute?.attributes?.name}
                                    </Typography>
                                 <Box
                                      style={
                                        !this.state.editRequest?      OfferReceivedWebStyle.accordianIconStyle:OfferReceivedWebStyle.downloadIconStyle
                                      }
                                    >
                                      {!this.state.editRequest ? (
  isExpanded 
    ?
    <ExpandLessIcon style={{ color: "#fff2e3", fontSize: "16px" }} />
    :
    <ExpandMoreIcon style={{ color: "#fff2e3", fontSize: "16px" }} /> 
):
<span
data-test-id="specificEditID"
style={{cursor:'pointer'}}
 onClick={()=>this.openMileStonePopUp(editPartAttribute.attributes.id,index)}>
{DownloadIcon()}
</span>
}

                                    </Box>
                                  </Box>
                                  <Box style={{ display: 'flex', gap: '40px' }}>
                                    <Box style={{ display: 'flex', gap: '10px' }}>

                                      {CalenderIcon()}
                                      <Typography>
                                        {this.formatDateShort(editPartAttribute?.attributes?.due_date)}
                                      </Typography>
                                    </Box>
                                    <Typography>
                                      ₹ {editPartAttribute?.attributes?.amount}
                                    </Typography>
                                  </Box>

                                </Box>
                              </Box>
                          </Box>
                        </StepLabel>
                        <StepContent style={{  borderLeft:this.state?.mileStoneData?.attributes.milestones.data.length!==(index+1)? '2px solid rgba(73, 73, 73, 1)':'unset',
}}>

                            {isExpanded &&  <Box style={{  width:'100%', paddingInline: 0, paddingBottom: 0,flexDirection:'column'}}>
                                <Box style={{ display: 'flex', width: '100%', ...backgroundStyle,...paddingStyleSecond,paddingTop:'10px',flexDirection: 'column', gap: '10px' }}>
                                  <Typography className="description-offer-received">
                                    {editPartAttribute?.attributes?.description}
                                  </Typography>
                                  <Typography className="deliveerable-style">
                                    Deliverables:
                                  </Typography>
                                  {editPartAttribute?.attributes?.deliverables?.data.map((item: {
                                    attributes: {
                                      deliverable: string
                                    }
                                  }, index: number) => {

                                    return <Typography className="description-offer-received" style={{ fontSize: '12px' }}>
                                      {index + 1}.{item?.attributes?.deliverable}
                                    </Typography>
                                  })
                                  }
                                </Box>
                                {
                                  isEditAnyThing && this.state.tab==0 && EditMileStoneData(dataisEditAnyThing)
                                }
                              </Box>}
                        </StepContent>
                      </Step>
                      })}
                    </StepperStyledComponentWithNumber></>
                  <Box style={{ padding: '40px 40px 0px 40px' }}>

                    <AboutWorkOpp
                      workOppertunityData={this.state.workOppertunityData}
                      SkillButton={SkillButton}
                      handleAbutWorkOpen={this.handleAbutWorkOpen}
                      accordian={this.state.accordianPop}
                      getRateTypeLabelProposal={this.getRateTypeLabelProposal(this.state.workOppertunityData)}
                    />
                  </Box>
                  <Divider style={OfferReceivedWebStyle.dividerStyle} />
                  <Box style={{ padding: '0px 40px 0px 40px' }}>
                    <Box style={{ background: "transparent", color: "white", fontFamily: "inter",marginBottom:'10px' }}>
                      <Box
                        onClick={() => {
                          this.sharedProposalPopUpFn()
                        }}
                        data-test-id="SendProposal"
                        aria-controls="SendProposal-content"
                        id="SendProposal-header"
                        style={{
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "24.2px",
                          textAlign: "left",
                          color: "#fff2e3",
                          paddingLeft: "0px"
                        }}
                      >
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Typography style={proposalStyle.secondHeadingStyle}>  Shared proposal</Typography>
                          <Box
                            style={{
                              backgroundColor: "#7d7d7d",
                              borderRadius: "50%",
                              padding: "4px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {!this.state.sharedProposalPopUp ? <ExpandMoreIcon style={{ color: "#fff2e3", fontSize: "16px" }} /> :
                              <ExpandLessIcon style={{ color: "#fff2e3", fontSize: "16px" }} />}
                          </Box>
                        </Box>

                      </Box>
                 { this.state.sharedProposalPopUp  &&    <Box style={{ paddingInline: 0, paddingBottom: 0 }}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                          <Box >
                            <Box >
                              <Box
                              style={{marginTop:'20px'}}
                               className="profile-view-preview">
                                {this.getProfileDetails()}
                                <Box className="profile-name-preview2">
                                  <Typography 
                                  className="received-shared-style">
                                    {this.state.proposalData?.attributes?.received_time_ago}
                                  </Typography>
                                  <Typography
                                   className="profile-name-preview">
                                    {this.state.proposalData?.attributes?.user_name}
                                  </Typography>
                                  <Typography
                                   className="profile-address-preview">
                                    {this.state.proposalData?.attributes?.location}
                                  </Typography>
                                </Box>
                              </Box>
                              <Grid container spacing={4} style={{ marginBottom: '20px', flexWrap: 'wrap' }}>
                                <Grid item xs={12} sm={12} md={7} lg={6} style={{ display: 'flex', flexDirection: 'row' }}>

                                  <Box className="part-sub-preview2" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                                      Views
                                    </Typography>
                                    <Typography className="part-sub-value">
                                      {this.state.proposalData?.attributes?.views}

                                    </Typography>
                                  </Box>
                                  <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                                      Likes
                                    </Typography>
                                    <Typography className="part-sub-value">
                                      {this.state.proposalData?.attributes?.likes}


                                    </Typography>
                                  </Box>
                                  <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                                      Service rate
                                    </Typography>
                                    <Typography style={{ ...webStyle2.priceHour, height: 'unset', width: '100%', padding: '6px 8px' }}>
                                      Rs.{" "} {this.state.proposalData?.attributes?.hourly_rate && this.state.proposalData?.attributes?.hourly_rate?.toLocaleString('en-IN')}{" "}
                                      {this.formatToLocaleString(this.state.proposalData)}
                                    </Typography>
                                  </Box>
                                  <Box className="part-sub-preview3" style={{ borderRight: '1px solid rgba(84, 84, 84, 1)', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography className="part-sub-heading" style={{ marginBottom: '10px' }}>
                                      Earnings
                                    </Typography>
                                    <Typography className="part-sub-value">
                                      {'₹ 0k'}

                                    </Typography>
                                  </Box>
                                </Grid>

                                <Grid item xs={12} sm={12} md={5} lg={6} style={{
                                  display: 'flex',
                                  height: '128px',
                                  overflow: "auto",
                                  scrollbarWidth: 'none',
                                  flexDirection: 'row', flexWrap: 'wrap', gap: '16px', paddingLeft: '20px'
                                }}>
                                  {this.state.proposalData?.attributes?.skills && this.state.proposalData?.attributes?.skills.length > 0 && this.state.proposalData?.attributes?.skills.map((item: any) => {


                                    return <Box className="chips-preview">
                                      {item.name}
                                    </Box>
                                  })
                                  }
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider style={OfferReceivedWebStyle.dividerStyle} />
                            <Box >

                              <ProposalComponent
                                      workOppertunityData={this.state.workOppertunityData}
                                      project_timeline={this.state.proposalData?.attributes?.work_timeline}
                                      Question={this.state.proposalData?.attributes?.answer || []}
                                      rateHourlyClient={this.state.workOppertunityData?.attributes?.rate_amount}
                                      project_timeline_type={this.state.proposalData?.attributes?.work_timeline_type}
                                      hours={this.state.proposalData?.attributes?.required_hours_per_week}
                                      rateType={this.getRateTypeLabelProposal(this.state.proposalData)}
                                projectEstimate={this.state.proposalData?.attributes?.overall_project_estimate}
                                previewData={this.state.proposalData?.attributes?.projects}
                                dateData={this.state.proposalData?.attributes?.start_date}
                                linkList={this.state.proposalData?.attributes?.links ?? ""}
                                files={this.state.proposalData?.attributes?.resource_files}
                                pitchData={this.state.proposalData?.attributes?.give_your_pitch}
                                showCollection={this.state.proposalData?.attributes?.projects.length == 0 ? true : false}
                                hourlyRate={this.state.proposalData?.attributes?.hourly_rate}

                        
                                findWork={true}
                              />
                            </Box>
                          </Box>
                          <Divider style={OfferReceivedWebStyle.dividerStyle} />
                          <StepperStyledComponent activeStep={0} orientation="vertical" className="about-under-review-second-box-stepper">
                            {this.state.proposalData?.attributes?.proposal_history?.data.map((label: {
                              "id": string
                              "type": string
                              "attributes": {
                                "ref_type": string
                                "event_date": string
                                "id": number,
                                "proposal_id": number,
                                "event": string,
                                "ref_id": number
                              }
                            }) => (
                              <Step key={label?.attributes?.event}>
                                <StepLabel >
                                  <Typography>     {label?.attributes?.event}
                                  </Typography>
                                  <Typography>      {label?.attributes?.event_date}
                                  </Typography>
                                </StepLabel>
                              </Step>
                            ))}
                          </StepperStyledComponent>
                        </Box>
                      </Box>}
                    </Box>

                  </Box>
                </Grid>
              </> :
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4} md={4} lg={3}

                    style={{
                      paddingBlock: '20px',
                    }}>
                    {this.navBarForirstPart()}

                  </Grid>

                </Grid>
                <NoResultFound />
              </>
            }
          </Grid>
        }
        {this.state.acceptOfferPopUp && this.DioalogComponentForOffereAceptOdDecline(this.state.acceptOfferPopUp,
          "Accept Offer", "Awesome! Once you accept the contract, the client will be able to activate the contract.", true,
          this.acceptPopClose, "Accept offer",true,this.putAcceptOffer,{gap:'21px',justifyContent:'center'})}
        {this.state.declineOfferPopUp && this.DioalogComponentForOffereAceptOdDecline(this.state.declineOfferPopUp,
          "Decline Offer", "Are you sure you want to decline the contract for the following opportunity. This action cant be undone. ", false,
          this.declinePopClose, "Decline offer",true,this.putDeclineOffer,{gap:'21px',justifyContent:'center'})}
             {this.state.discardPopUp && this.DioalogComponentForOffereAceptOdDecline(this.state.discardPopUp,
          "Discard edits", "Are you sure you want to discard the edits? This action cant be undone.", false,
          this.handlediscardPopUpClose, "Discard edits",false,this.handlediscardPopUpMain,{justifyContent:'space-between'})}
         <DioalogComponent
    open={this.state.editMileStone}
    fullWidth={true}
    data-test-id="close-onclick"
    style={{
      scrollbarWidth: 'none'
    }}
    maxWidth="md"
  >
    <DialogContent
    >
      <Typography className="popUpHeading" style={{ textAlign: 'unset',borderBottom: 'unset', paddingInline: '10%',width:'80%' }}>
        {'Edit milestone'}
      </Typography>
      <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", width:'100%',
              marginBlock: '10px' }} />
      <Box style={OfferReceivedWebStyle.editMileStoneBox}>

       <Typography className="popUpHeadingEditMilestone" >

          {'Milestone name'}
        </Typography>
        <Box style={{ ...OfferReceivedWebStyle.txtfield, width: '100%',height:'29px' }} >

          <InputField
            value={dataMileStone?.attributes?.name}
            data-test-id="MilestoneName"
            onChange={(e) =>this. handleInputChange(e, 'name', this.state.editMileStoneId)}
            style={{ ...OfferReceivedWebStyle.textFieldStyle, width: "100%" }} />

        </Box>
        <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >{this.showNameError()}</Typography>
      </Box>
      <Box style={OfferReceivedWebStyle.editMileStoneBox}>

       <Typography className="popUpHeadingEditMilestone" >

          {'Description'}
        </Typography>
        <TextareaAutosize
          data-test-id="description"
          minRows={4}
          value={dataMileStone?.attributes?.description}
          onChange={(e) =>this. handleInputChange(e, 'description',this.state. editMileStoneId)}
          placeholder="Type here..."
          style={{
            width: '100%',
            height: '126px',
            backgroundColor: '#333',
            scrollbarWidth:'none',
            color: '#fff',
            padding: '10px',
            borderRadius: '11.97px',
            border: '1px solid #444',
            resize: 'none',

          }}
          className={dataMileStone?.attributes?.description ? 'pitch-style-font' : 'pitch-style-font-placeholder'}
        />
         <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >{this.showDescriptionError()}</Typography>
      </Box>
      <Box style={OfferReceivedWebStyle.editMileStoneBox}>

       <Typography className="popUpHeadingEditMilestone" >
          {'Deliverables'}
        </Typography>
        {dataMileStone?.attributes?.deliverables?.data.map((item: {
          attributes: {
            deliverable: string;
            deliverableError:boolean
          }
        },index:number) => {
      return  <>
      <Box style={{display:'flex',gap:'12px',alignItems:'center'}}>
      < Box style={{
            ...OfferReceivedWebStyle.txtfield,
            height: '29px', width: '295px',
            paddingInline: '12px',
          }} >
        <Typography style={{ ...proposalStyle.monyStyle, width: '10%' }}>{index+1}.</Typography>
        <InputField
          value={item.attributes.deliverable}
          data-test-id="Deliverables"
          onChange={(e) => this.handleDeliverableChange(index, e, this.state.editMileStoneId)}
          style={OfferReceivedWebStyle.textFieldStyle} />
        <DeleteOutlineIcon
         onClick={() =>  this.handleDeleteDeliverable(index, this.state.editMileStoneId)}
          data-test-id="deleteFilesDeliverables"
          fontSize="small"
          style={{ color: 'white', marginLeft: 5 }} />
      </Box>
          { dataMileStone?.attributes?.deliverables?.data.length==index+1 && 
          <Box style={{width:'40px',height:'40px',borderRadius:'50%',cursor:'pointer',
            background:"rgba(84, 84, 84, 1)",display:'flex',justifyContent:'center',alignItems:'center'}}
            data-test-id="handleAddDeliverable"
            onClick={() =>  this.handleAddDeliverable(this.state.editMileStoneId)}>
              <AddIcon style={{color:"white"}}/></Box>
              }
      </Box>  
      <Typography style={{
                  marginTop: 10,
                  marginLeft: 10,
                  color: "red",
                  fontFamily: "Inter, sans-serif"
                }} >{this.showDeliverableError(item?.attributes?.deliverableError)}</Typography>
    
      </>
        })}
          { dataMileStone?.attributes?.deliverables?.data.length==0 && 
          <Box style={{width:'40px',height:'40px',borderRadius:'50%',cursor:'pointer',
            background:"rgba(84, 84, 84, 1)",display:'flex',justifyContent:'center',alignItems:'center'}}
            data-test-id="handleAddDeliverable"
            onClick={() =>  this.handleAddDeliverable(this.state.editMileStoneId)}>
              <AddIcon style={{color:"white"}}/></Box>
              }
      </Box>
      <Box style={OfferReceivedWebStyle.editMileStoneBox}>

       <Typography className="popUpHeadingEditMilestone" >

          {'Amount'}
        </Typography>
        
        <Box style={{...OfferReceivedWebStyle.txtfield,height:'29px' ,width:'197px'}} >
          <Typography style={{ ...proposalStyle.monyStyle, width: '10%' }}>₹</Typography>
          <InputField
             onChange={(e) => this.handleInputChange(e, 'amount', this.state.editMileStoneId)}
                  value={dataMileStone?.attributes?.amount}
            data-test-id="Amount"
            style={OfferReceivedWebStyle.textFieldStyle} />

        </Box>
      </Box>
      <Box style={OfferReceivedWebStyle.editMileStoneBox}>
        <Typography className="popUpHeadingEditMilestone" >
          {'Due date'}
        </Typography>
        <Box className="post-opportunity-container" style={{
          background: 'rgba(47, 47, 47, 1)'
        }}>
          <CustomBox className='formGroup' >
            <CustomBox className='hours-container' style={{ backgroundColor: '#222', color: "#FFF2E3" }}>
              <CustomBox className='date-container' style={{ color: "#FFF2E3",
              borderRadius:'11.97px',
              height:'49px',
               width: '197px', paddingLeft: '15px', paddingRight: '5px',
                 backgroundColor: 'rgba(49, 49, 49)' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    data-test-id='date-picker'

                    open={this.state.openCalender}
                            onClose={() => this.closeCalender()}
                            minDate={getMinDate(this.state.editMileStoneId)} 
                    value={dayjs(dataMileStone?.attributes?.due_date)}
                    onChange={(date) => this.handleDateChange(date, this.state.editMileStoneId)}
                    disablePast={true}
                    slotProps={{
                      popper: {
                        onClick: (event) => this.handlePropagation(event)
                    },
                      openPickerIcon: {
                        onClick: (event: any) => this.openCalender(event)
                      }
                    }}
                  />
                </LocalizationProvider>
              </CustomBox>
            </CustomBox>
          </CustomBox>
        </Box>
          <Typography style={OfferReceivedWebStyle.textFieldStyle}>
        {this.state.  errorMessage}
          </Typography>
      </Box>
      <Divider style={{ backgroundColor: "rgba(84, 84, 84, 1)", width:'100%',
              marginBlock: '20px' }} />
      <Box style={{
        display: 'flex', justifyContent: 'space-between', width: '392px',
        paddingBlock: '15px',
         paddingInline: '60px'
        
      }}>

        <Button className="btn-cancel"
          data-test-id="closeMileStonePoPuP"
          onClick={
        ()=>   this. closeMileStonePoPuP(this.state.editMileStoneId)
          }
        >
          Cancel
        </Button>
        <Button className="btn-send-offer"
          data-test-id="editUpdate"
          disabled={!this.isFormValid()}
          onClick={()=>{
            this.editUpdate(this.state.editMileStoneId)
          }}
          style={{
            background:
              "rgba(255, 242, 227, 1)"
          }}
        >
          {"Edit milestone"}
        </Button>
      </Box>
    </DialogContent>
  </DioalogComponent>
          </ThemeProvider>
      </>
    );
  }
}


export const OfferReceivedWebStyle = {
  headingStleForList:{
    fontSize: 13 as 13,
    color: "#66625C",
    lineHeight: 13.31,
    fontFamily: "Inter,sans-serif",
    alignSelf: "flex-start" as 'flex-start',
    marginBottom:'5px'
  },
  editMileStoneBox:{
    width:'80%',
    gap:'4px',
    display:'flex' as 'flex',
    marginBottom:'37px',
     paddingInline: '10%',
    flexDirection:'column' as 'column'
  },
  txtfield: {
    width: "100%%",
    height: "49px",
    padding:'10px 4px',
    overflow: "hidden",
    borderRadius: "11.97px",
    color: '#fff',
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    backgroundColor: '#313131',
  },
  textFieldStyle: {
    width: '80%',
    margin: "0px 5px",
    background: 'transparent',
    border: 'none',
    color: "rgba(255, 242, 227, 1)",

    outline: "none",
    fontFamily: 'Inter',
  fontSize: '18.62px',
  fontWeight: 400,
  lineHeight: '22.53px',
  },
  dividerStyle: {
    backgroundColor: "rgba(84, 84, 84, 1)", marginBlock: '30px'
  },
  lodingBox: {
    width: '100%', marginTop: '10%'
  },
  lodingBoxSecond: {
    width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'
  },
  firstpartStyle: {
    maxHeight: '1800px',
    alignItems: 'center' as 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    overflow: 'auto' as 'auto',
    scrollbarWidth: 'none' as 'none'
  },
  downloadIconStyle: {
    borderRadius: '50%',
    background: "rgba(84, 84, 84, 1)",
    width: '30px', height: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  accordianSummaryStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin:0
    , width: '100%' as '100%',
    borderBottom: "1px solid rgba(84, 84, 84, 1)",
    lineHeight: "24.2px",
    textAlign: "left" as 'left',
    color: "#fff2e3",
    paddingLeft: "0px" as '0px'
  },
  accordianIconStyle: {
    backgroundColor: "rgba(51, 51, 51, 1)",
   
    borderRadius: "50%",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}

export const DioalogComponent = styled(Dialog)({
  '& .MuiPaper-root':{
    backgroundColor:'unset',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '478px',
  },
  '& .MuiPickersLayout-contentWrapper': {
    background: 'rgba(34, 34, 34, 1) '

  },
  '& .MuiDialogContent-root': {
    paddingInline: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(34, 34, 34, 1)'
  }
})

export default OfferReceived;
// Customizable Area End
